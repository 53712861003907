<template>
  <tr
    v-for="(user, index) in usersList"
    :key="index"
    class="table-row"
  >
    <td class="table-cell table-id">
      {{ user.id }}
    </td>
    <td class="table-cell table-fav">
      <div class="favorite">
        <div class="favorite-icon">
          <div
            v-if="user.isFavorite"
            class="active"
            @click="changeFav(user, false)"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.85352 18.3333L6.20768 12.4792L1.66602 8.54167L7.66602 8.02083L9.99935 2.5L12.3327 8.02083L18.3327 8.54167L13.791 12.4792L15.1452 18.3333L9.99935 15.2292L4.85352 18.3333Z"
                fill="#FFC700"
              />
            </svg>
          </div>
          <div
            v-if="!user.isFavorite"
            class="passive"
            @click="changeFav(user, true)"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.37435 14.8542L9.99935 13.2708L12.6243 14.875L11.9368 11.875L14.2493 9.875L11.2077 9.60417L9.99935 6.77083L8.79102 9.58333L5.74935 9.85417L8.06185 11.875L7.37435 14.8542ZM4.85352 18.3333L6.20768 12.4792L1.66602 8.54167L7.66602 8.02083L9.99935 2.5L12.3327 8.02083L18.3327 8.54167L13.791 12.4792L15.1452 18.3333L9.99935 15.2292L4.85352 18.3333Z"
                fill="#DFE0EB"
              />
            </svg>
          </div>
        </div>
      </div>
    </td>
    <td class="table-cell table-login">
      {{ user.email }}
    </td>
    <td class="table-cell table-role">
      {{ user.roles.join(', ') }}
    </td>
    <td
      class="table-cell table-status"
      :class="[currentIndex === index ? statusClass: '']"
    >
      <Toggle
        v-model="user.isActive"
        @change="updateStatus(user, index)"
      />
    </td>
    <td class="table-cell table-createDate">
      {{ dataFormatting(user.createUser) }}
    </td>
    <td
      class="table-cell table-program"
      :class="[programIndex === user.id + 'program' ? statusClass: '']"
    >
      <CustomSelect
        v-if="PartnerPrograms"
        :options="PartnerPrograms"
        :value-input="user.partnerProgramName"
        class="select"
        :data="user"
        @change-select="changeProgramLevel"
      />
    </td>
    <td class="table-cell table-ip">
      <div
        class="table-ip_button"
        @click="ipListSet(user.id)"
      >
        <ip-false v-if="user.isIpList === false" />
        <ip-true v-if="user.isIpList === true" />
        <!--        <ip-inactive v-if="ipListItem.isActive === 3" />-->
      </div>
    </td>
    <td class="table-cell table-profile">
      <div class="info">
        <div
          class="info-profile"
          @click="profileInfo(user)"
        >
          <img
            src="../../../assets/img/icons/info.svg"
            alt=""
          >
        </div>
        <div
          class="info-password"
          @click="changePassword(user)"
        >
          <img
            src="../../../assets/img/icons/passwordLock.svg"
            alt=""
          >
          Password
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import {mapGetters} from 'vuex';
import moment from 'moment';
import Toggle from '@vueform/toggle'
import CustomSelect from '../partials/CustomSelect'
import UserService from '../../../services/user.service'
import PartnerService from '../../../services/partner.service'
import IpFalse from "@/icons/ipFalse";
import IpTrue from "@/icons/ipTrue";

export default {
  name: "UserListItem",
  components: {
    IpTrue,
    IpFalse,
    Toggle,
    CustomSelect
  },
  props: {
    usersList: {
      type: Array,
      default: null,
    },
  },
emits: ['change-fav'],
  data() {
    return {
      isPopUp: null,
      search: null,
      dateFormat: 'DD.MM.YYYY',
      statusClass: '',
      currentIndex: null,
      programIndex: null,
      ipListItem: {
        id: 23,
        isActive: 3,
        ipAddress: [
          {
            ip: ['23232323', '232323232342344'],
            isActive: true
          }
        ]
      },
    }
  },
  computed: {
    ...mapGetters({
      UserItem: 'getUserItem',
      Notification: 'getNotification',
    }),
    PartnerPrograms: {
      get() {
        return this.$store.state.programsList;
      },
      set(value) {
        this.$store.commit('setProgramAll', value);
      }
    }
  },
  mounted() {
    this.$store.dispatch('getPartnersProgram');
    this.scrollHandler();
  },
  methods: {
    scrollHandler() {
      const table = document.querySelector('.table-block-table');

      table.addEventListener('scroll', () => {
        const tds = document.querySelectorAll('.table-id');

        tds.forEach((el) => {
          el.classList.add('sticky');

          if (table.scrollLeft < 20) {
            el.classList.remove('sticky');
          }
        });
      });
    },
    dataFormatting(value) {
      return moment(value).format(this.dateFormat);
    },
    ipListSet(id) {
      UserService.getUserIpList(id).then((res) => {
        this.$store.commit('setIpList', res.data.result);
      })
    },
    profileInfo(user) {
      this.$store.commit('setProfile', user);

      UserService.getProfileActivity(user.id).then((res) => {
        this.$store.commit('setUserActivity', res.data.result);
      })
    },
    changeFav(user, isFav) {
      const query = {
        maxDepositFix: user.partnerProfile.maxDepositFix,
        maxDepositFloat: user.partnerProfile.maxDepositFloat,
        partnerFloat: user.partnerProfile.partnerFloat,
        partnerFix: user.partnerProfile.partnerFix,
        isFavorite: isFav
      }

      UserService.editUserById(user.id, query).then((res) => {
        if (res) {
          this.$emit("change-fav");
        }
      }).catch(() => {

      })
    },
    changePassword(user) {
      this.$store.commit('setUserItem', user);
    },
    setNotification(data) {
      let result = {};

      if (data.name) {
        result = {
          name: data.name,
          value: data.value,
        }
      }
      this.$store.commit('setNotification', result);
    },
    changeProgramLevel(data) {
      this.programIndex = data.data.id + 'program';
      this.currentIndex = null;
      this.setNotification({name: 'Partner program', value: data.option.name});
      this.emitter.off('update-notification');
      this.emitter.on('update-notification', () => {
        if (this.Notification && this.Notification.name) {
          PartnerService.changePartnerProgram(data.data.id, data.option.id).then(() => {
            this.statusClass = 'status-success';
            setTimeout(() => {
              this.statusClass = '';
            }, 3000);
            this.setNotification({});
          }).catch(() => {
            this.statusClass = 'status-error';
            this.setNotification({name: 'Error', value: data.option.name});
          });
        }
      });
    },
    updateStatus(user, index) {
      this.currentIndex = index;
      this.programIndex = null;
      this.setNotification({name: 'Update status', value: 'status: ' + user.isActive});
      this.emitter.off('update-notification');
      this.emitter.on('update-notification', () => {
        if (this.Notification && this.Notification.name) {
          UserService.updateStatus({id: user.id, status: user.isActive}).then(() => {
            this.statusClass = 'status-success';
            setTimeout(() => {
              this.statusClass = '';
            }, 3000);
            this.setNotification({});
          }).catch(() => {
            this.statusClass = 'status-error';
            this.setNotification({name: 'Error', value: 'status: ' + user.isActive});
          });
        }
      });
    }
  },
}
</script>

<style lang="scss">
@import "~@vueform/toggle/themes/default.css";

.table-row {
  .table-status {
    .toggle {
      width: var(--toggle-width, 21px);
      height: var(--toggle-height, 9px);
      outline: none;

      &-container {
        &:focus {
          box-shadow: none;
        }
      }
    }

    .toggle-on {
      background: var(--toggle-bg-on, #02C076);
      border-color: var(--toggle-border-on, #02C076);
    }

    .toggle-handle-on {
      transform: translateX(-110%);
    }

    .toggle-handle {
      width: 7px;
      height: 7px;
      top: 1px;
    }
  }
}

</style>
