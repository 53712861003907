<template>
  <div
    v-for="(exchange, index) in exchangesList"
    :key="index"

    class="list-row_block"
    :class="{'lastRows': index >= exchangesList.length - 7 && exchangesList.length > 14}"
  >
    <div
      v-if="exchange"
      class="list-row"
      @click="dropHandler(exchange)"
    >
      <div
        ref="startColumn"
        class="list-cell list-info"
      >
        <div
          class="info-deposit"
          @click="exchangeInfo(exchange)"
          @click.stop="dropHandler"
        >
          <info-icon />
        </div>
      </div>
      <div
        v-if="exchange?.id"
        class="list-cell list-id"
      >
        {{ exchange.id }}
      </div>
      <div
        v-if="exchange?.uid"
        class="list-cell list-uid"
      >
        {{ exchange.uid }}
      </div>
      <!--      <div class="list-cell list-idBinance">-->
      <!--        {{ exchange.idBinance }}-->
      <!--      </div>-->
      <!--      <div class="list-cell list-id1inch">-->
      <!--        {{ exchange.id1inch }}-->
      <!--      </div>-->
      <div
        class="list-cell list-from"
        @mouseover="currentNetworkFrom = exchange.id"
        @mouseleave="currentNetworkFrom = null"
      >
        {{ exchange.from }} <span>{{ networkFormat(exchange.addressFromNetwork) }}</span>
        <transition name="fade">
          <hover-text
            v-if="currentNetworkFrom === exchange.id && exchange.addressFromNetwork.length > 4"
            :option="exchange.addressFromNetwork"
          />
        </transition>
      </div>
      <div
        class="list-cell list-to"
        @mouseover="currentNetworkTo = exchange.id"
        @mouseleave="currentNetworkTo = null"
      >
        {{ exchange.to }} <span>{{ networkFormat(exchange.addressToNetwork) }}</span>
        <transition name="fade">
          <hover-text
            v-if="currentNetworkTo === exchange.id && exchange.addressToNetwork.length > 4"
            :option="exchange.addressToNetwork"
          />
        </transition>
      </div>
      <div class="list-cell list-mode">
        {{ exchange.modeOrder }}
        <span v-if="exchange.modeOrder === null">floating</span>
      </div>
      <div class="list-cell list-modeRate">
        {{ exchange.modeCurs }}
        <span v-if="exchange.modeCurs === null">floating</span>
      </div>
      <div class="list-cell list-partner">
        {{ exchange.partner }}
        <span v-if="exchange.partner === null"> SwapzoneTwo </span>
      </div>
      <div class="list-cell list-amount">
        {{ numbersFormatting(exchange.amountIn) }}
      </div>
      <div class="list-cell list-userAmount">
        {{ numbersFormatting(exchange.amountResult) }}
      </div>
      <div
        class="list-cell list-companyFee"
        @mouseover="currentCompanyFeeCell = exchange.id"
        @mouseleave="currentCompanyFeeCell = null"
      >
        {{ numbersFormatting(exchange.companyFee, 5) }}
        <transition name="fade">
          <hover-text
            v-if="currentCompanyFeeCell === exchange.id && exchange.companyFee !== 0"
            :option="exchange.companyFee"
          />
        </transition>
      </div>
      <div
        class="list-cell list-partnerFee"
        @mouseover="currentPartnerFeeCell = exchange.id"
        @mouseleave="currentPartnerFeeCell = null"
      >
        {{ numbersFormatting(exchange.partnerFee, 5) }}
        <transition name="fade">
          <hover-text
            v-if="currentPartnerFeeCell === exchange.id && exchange.partnerFee !== 0"
            :option="exchange.partnerFee"
          />
        </transition>
      </div>
      <div class="list-cell list-date">
        <div>
          {{ dataFormatting(exchange.createDate) }}
        </div>
      </div>
      <div
        class="list-cell table-status list-status"
        :class="[currentIndex === exchange.id ? statusClass: '']"
      >
        <div class="buttons">
          <select-status-popup-exchange
            v-if="ExchangeStatusList"
            :options="ExchangeStatusList"
            :value-input="exchange.status"
            class="select select-popup select-popup-item"
            page="multi"
            :data="exchange"
            @change-select="changeStatus"
            @click.stop="dropHandler"
          />
          <div
            class="dropdown"
            :class="{'dropdown-open': currentExchangeRow === exchange.id}"
          >
            <dropdown-arrow />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="currentExchangeRow === exchange.id"
      class="list-row list-row_details"
    >
      <div
        v-if="exchange.swapzoneRow"
        class="swapzone"
      >
        <div
          class="list-row"
        >
          <div
            ref="startColumn"
            class="list-cell list-info"
          >
            <div class="info">
              <div
                class="info-deposit"
                @click="exchangeInfo(exchange.swapzoneRow)"
                @click.stop="dropHandler"
              >
                <info-icon />
              </div>
            </div>
          </div>
          <div class="list-cell list-id">
            {{ exchange.swapzoneRow.id }}
          </div>
          <div
            v-clipboard:copy="exchange.swapzoneRow.uid"
            v-clipboard:success="onCopyUid"
            class="list-cell list-uid"
            @mouseover="tokenUid = index"
            @mouseleave="tokenUid = null"
          >
            <div v-if="exchange.swapzoneRow.uid">
              {{ uidFormatter(exchange.swapzoneRow.uid) }}
              <transition name="fade">
                <hover-text
                  v-if="tokenUid === index"
                  :option="exchange.swapzoneRow.uid"
                />
              </transition>
            </div>
            <div v-else />
          </div>
          <!--          <div class="list-cell list-idBinance" />-->
          <!--          <div class="list-cell list-id1inch" />-->
          <div class="list-cell list-from">
            {{ exchange.swapzoneRow.from }}
          </div>
          <div class="list-cell list-to">
            {{ exchange.swapzoneRow.to }}
          </div>
          <div class="list-cell list-mode">
            Swapzone
          </div>
          <div class="list-cell list-modeRate" />
          <div class="list-cell list-partner" />
          <div class="list-cell list-amount">
            {{ numbersFormatting(exchange.swapzoneRow.amount) }}
          </div>
          <div class="list-cell list-userAmount">
            {{ numbersFormatting(exchange.swapzoneRow.amountResult) }}
          </div>
          <div class="list-cell list-companyFee" />
          <div class="list-cell list-partnerFee" />
          <div class="list-cell list-date" />
          <div
            class="list-cell table-status list-status"
            :class="[currentIndex === exchange.swapzoneRow.id ? statusClass: '']"
          >
            <status-exchange
              v-if="ExchangeStatusList"
              :options="ExchangeStatusList"
              :value-input="exchange.swapzoneRow.status"
              class="select"
              :data="exchange.swapzoneRow"
            />
          </div>
        </div>
      </div>
      <div
        v-if="exchange.binanceRow"
        class="binance"
      >
        <div
          class="list-row"
        >
          <div
            ref="startColumn"
            class="list-cell list-info"
          >
            <div class="info">
              <div
                class="info-deposit"
                @click="exchangeInfoBinance(exchange)"
                @click.stop="dropHandler"
              >
                <info-icon />
              </div>
            </div>
          </div>
          <div class="list-cell list-id">
            {{ exchange.binanceRow.id }}
          </div>
          <div
            v-clipboard:copy="exchange.binanceRow.uid"
            v-clipboard:success="onCopyUid"
            class="list-cell list-uid"
            @mouseover="tokenUid = index"
            @mouseleave="tokenUid = null"
          >
            <div v-if="exchange.binanceRow.uid">
              {{ uidFormatter(exchange.binanceRow.uid) }}
              <transition name="fade">
                <hover-text
                  v-if="tokenUid === index"
                  :option="exchange.binanceRow.uid"
                />
              </transition>
            </div>
            <div v-else />
          </div>
          <!--          <div class="list-cell list-idBinance" />-->
          <!--          <div class="list-cell list-id1inch" />-->
          <div class="list-cell list-from">
            {{ exchange.binanceRow.from }}
          </div>
          <div class="list-cell list-to">
            {{ exchange.binanceRow.to }}
          </div>
          <div class="list-cell list-mode">
            Binance
          </div>
          <div class="list-cell list-modeRate" />
          <div class="list-cell list-partner" />
          <div class="list-cell list-amount">
            {{ numbersFormatting(exchange.binanceRow.amount) }}
          </div>
          <div class="list-cell list-userAmount">
            {{ numbersFormatting(exchange.binanceRow.amountResult) }}
          </div>
          <div class="list-cell list-companyFee" />
          <div class="list-cell list-partnerFee" />
          <div class="list-cell list-date" />
          <div
            class="list-cell table-status list-status"
            :class="[currentIndex === exchange.binanceRow.id ? statusClass: '']"
          >
            <status-exchange
              v-if="ExchangeStatusList"
              :options="ExchangeStatusList"
              :value-input="exchange.binanceRow.status"
              class="select"
              :data="exchange.binanceRow"
            />
          </div>
        </div>
      </div>
      <div
        v-if="exchange.inchRow"
        class="inch"
      >
        <div
          class="list-row"
        >
          <div
            ref="startColumn"
            class="list-cell list-info"
          >
            <div class="info">
              <div
                class="info-deposit"
                @click="exchangeInfo(exchange.inchRow)"
                @click.stop="dropHandler"
              >
                <info-icon />
              </div>
            </div>
          </div>
          <div class="list-cell list-id">
            {{ exchange.inchRow.id }}
          </div>
          <div
            v-clipboard:copy="exchange.inchRow.uid"
            v-clipboard:success="onCopyUid"
            class="list-cell list-uid"
            @mouseover="tokenUid = index"
            @mouseleave="tokenUid = null"
          >
            <div v-if="exchange.inchRow.uid">
              {{ uidFormatter(exchange.inchRow.uid) }}
              <transition name="fade">
                <hover-text
                  v-if="tokenUid === index"
                  :option="exchange.inchRow.uid"
                />
              </transition>
            </div>
            <div v-else />
          </div>
          <div class="list-cell list-from">
            {{ exchange.inchRow.from }}
          </div>
          <div class="list-cell list-to">
            {{ exchange.inchRow.to }}
          </div>
          <div class="list-cell list-mode">
            1inch
          </div>
          <div class="list-cell list-modeRate" />
          <div class="list-cell list-partner" />
          <div class="list-cell list-amount">
            {{ numbersFormatting(exchange.inchRow.amount) }}
          </div>
          <div class="list-cell list-userAmount">
            {{ numbersFormatting(exchange.inchRow.amountResult) }}
          </div>
          <div class="list-cell list-companyFee" />
          <div class="list-cell list-partnerFee" />
          <div class="list-cell list-date" />
          <div
            class="list-cell table-status list-status"
            :class="[currentIndex === exchange.inchRow.id ? statusClass: '']"
          >
            <status-exchange
              v-if="ExchangeStatusList"
              :options="ExchangeStatusList"
              :value-input="exchange.inchRow.status"
              class="select"
              :data="exchange.inchRow"
              @change-select="changeStatus"
            />
          </div>
        </div>
      </div>
      <div v-if="!exchange.swapzoneRow && !exchange.binanceRow && !exchange.inchRow">
        <div class="list-row">
          Empty
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InfoIcon from "@/icons/infoIcon";
import {mapGetters} from "vuex";
import {ALLOW_STATUS, STATUSES} from "@/pages/exchanges/exchangeData";
import OrderService from "@/services/order.service";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import StatusExchange from "@/pages/exchanges/partials/StatusExchange.vue";
import DropdownArrow from "@/assets/img/icons/dropdownArrow.vue";
import HoverText from "@/pages/coins/partials/hoverText.vue";
import SelectStatusPopupExchange from "@/pages/exchanges/partials/SelectStatusPopupExchange.vue";

export default {
  name: "ExchangesListMultiItem",
  components: {SelectStatusPopupExchange, HoverText, DropdownArrow, StatusExchange, InfoIcon},
  props: {
    exchangesList: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      dateFormat: 'DD.MM.YY HH:mm',
      dateFormatSec: 'DD.MM.YY HH:mm:ss',
      currentProfit: null,
      currentPartnerProfit: null,
      currentIndex: null,
      currentExchangeId: null,
      currentExchangeRow: null,
      statusClass: '',
      allowStatuses: ALLOW_STATUS,
      isRecalcLoader: false,
      ExchangeStatusList: STATUSES,
      scrollPosition: false,
      maxNumberLength: 7,
      isOpen: false,
      tokenUid: false,
      isSuccessCopy: false,
      currentCompanyFeeCell: null,
      currentPartnerFeeCell: null,
      currentNetworkTo: null,
      currentNetworkFrom: null,
    }
  },
  computed: {
    ...mapGetters({
      Notification: 'getNotification',
    }),
  },
  mounted() {
    this.scrollHandler();
  },
  methods: {
    networkFormat(val) {
      if (val && val.length > 4) {
        return val.slice(0, 4) + '..'
      } else {
        return val
      }
    },
    onCopyUid() {
      this.isSuccessCopy = true;

      this.emitter.emit('copy-uid', this.isSuccessCopy)

      setTimeout(() => {
        this.isSuccessCopy = false
        this.emitter.emit('copy-uid', this.isSuccessCopy)
      }, 2000);
    },
    exchangeInfoBinance(exchange) {
      if (exchange) {
        this.currentExchangeRow = exchange.id
        OrderService.getOrderAdminById(exchange.binanceRow.id).then((res) => {
          this.$store.commit('setExchangeData', res.data.result);
          this.$router.push({
            path: `/exchanges/multi/${exchange.binanceRow.id}`,
          })
        })

        /*OrderService.getOrderActivity(exchange.binanceRow.id).then((res) => {
          this.$store.commit('setOrderStatusActivityData', res.data.result);
        })*/
        this.emitter.emit('exchange-binance-timer', false)
      }
    },
    dropHandler(exchange) {
      if (exchange) {
        if (!this.currentExchangeRow) {
          this.currentExchangeRow = exchange.id
        } else if (this.currentExchangeRow !== exchange.id && exchange) {
          this.currentExchangeRow = exchange.id
        } else {
          this.currentExchangeRow = null
        }
      }
    },
    uidFormatter(val) {
      if (val.length > 15) {
        return val.slice(0, 11) + '...'
      } else {
        return val
      }
    },
    scrollHandler() {
      const table = document.querySelector('.table-block-table');

      table.addEventListener('scroll', () => {
        const tds = document.querySelectorAll('.list-id');

        tds.forEach((el) => {
          el.classList.add('sticky');

          if (table.scrollLeft < 100) {
            el.classList.remove('sticky');
          }
        });
      });
    },
    inputResize(el) {
      el.target.style.width = (el.target.value.length + 9) + 'ch';
    },
    exchangeInfo(exchange) {
      this.emitter.emit('exchange-multi-info', this.$route.query)
      this.emitter.emit('exchange-info-event', exchange)
      this.emitter.emit('exchange-multi-timer', false)

      this.$router.push({
        name: 'Exchange Multi modal',
        params: {
          id: exchange.id
        }
      })
      document.body.classList.add('modal-open');
    },
    amountHandler(el, exchangeId) {
      this.inputResize(el);
      this.currentExchangeId = exchangeId;
    },
    dataFormatting(value) {
      dayjs.extend(utc)
      dayjs.extend(timezone)

      return dayjs.utc(value).local().format(this.dateFormatSec)
    },
    setNotification(data) {
      let result = {};

      if (data.name) {
        result = {
          name: data.name,
          value: data.value,
        }
      }
      this.$store.commit('setNotification', result);
    },
    numbersFormatting(data, num) {
      let defaultNum = null
      if (num) {
        defaultNum = num
      } else {
        defaultNum = this.maxNumberLength
      }

      if (data === 0 || data === null) {
        return '-';
      } else {
        const formattedData = parseFloat(data.toFixed(5));
        return formattedData.toString();
      }
    },
    changeStatus(data) {
      this.setNotification({
        name: 'ID ' + data.data.id + ', status has been changed from ' + data.option.name,
        value: data.option.name
      });
      this.emitter.off('update-notification');
      this.emitter.on('update-notification', () => {
        if (this.Notification && this.Notification.name) {
          OrderService.updateOrderMultiStatusById(data.data.id, data.option.status).then(() => {
            this.currentIndex = data.data.id;
            this.statusClass = 'status-success';
            setTimeout(() => {
              this.statusClass = '';
            }, 3000);
            this.setNotification({});
          }).catch(() => {
            this.statusClass = 'status-error';
            this.setNotification({name: 'Error', value: 'status: ' + data.option.name});
          });
        }
      });

      this.emitter.emit('exchange-multi-timer', true)
    },
  }
}
</script>

<style lang="scss">
</style>
