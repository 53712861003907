<script>

export default {
    name: "PartnerActivityAlert"
}

</script>

<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.2"
      d="M10.0026 3.33325C6.31927 3.33325 3.33594 6.31659 3.33594 9.99992C3.33594 13.6833 6.31927 16.6666 10.0026 16.6666C13.6859 16.6666 16.6693 13.6833 16.6693 9.99992C16.6693 6.31659 13.6859 3.33325 10.0026 3.33325ZM10.8359 14.1666H9.16927V12.4999H10.8359V14.1666ZM10.8359 10.8333H9.16927V5.83325H10.8359V10.8333Z"
      fill="#FF5C00"
    />
    <path
      d="M9.98906 1.66675C5.38906 1.66675 1.66406 5.40008 1.66406 10.0001C1.66406 14.6001 5.38906 18.3334 9.98906 18.3334C14.5974 18.3334 18.3307 14.6001 18.3307 10.0001C18.3307 5.40008 14.5974 1.66675 9.98906 1.66675ZM9.9974 16.6667C6.31406 16.6667 3.33073 13.6834 3.33073 10.0001C3.33073 6.31675 6.31406 3.33341 9.9974 3.33341C13.6807 3.33341 16.6641 6.31675 16.6641 10.0001C16.6641 13.6834 13.6807 16.6667 9.9974 16.6667ZM9.16406 12.5001H10.8307V14.1667H9.16406V12.5001ZM9.16406 5.83341H10.8307V10.8334H9.16406V5.83341Z"
      fill="#FF5C00"
    />
  </svg>
</template>

<style scoped lang="scss">

</style>
