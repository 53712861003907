export const TABLE_TH = [
    {
        name: 'Run bot',
        sort: 'DESC',
        id: 'run-bot',
        key: 'run-bot',
        description: '',
        isSortable: false,
    },
    {
        name: 'Date / Time',
        sort: '',
        key: 'date',
        description: '',
        isSortable: true,
    },
    {
        name: 'ID / Uid',
        sort: '',
        key: 'id',
        description: '',
        isSortable: true,
    },
    {
        name: 'Partner / Mkt',
        sort: '',
        key: 'partner',
        description: '',
        isSortable: true,
    },
    {
        name: 'Pair / mode',
        sort: '',
        key: 'pair',
        description: '',
        isSortable: false,
    },
    {
        name: 'Amount / result',
        sort: '',
        key: 'amount',
        description: '',
        isSortable: false,
    },
    {
        name: 'AML',
        sort: '',
        key: 'aml',
        description: '',
        isSortable: false,
    },
    {
        name: 'Status',
        sort: '',
        key: 'status',
        description: '',
        isSortable: true,
    },
];

export const STATUSES = [
    {
        id: 'Waiting',
        name: 'Waiting',
        status: 10,
        cssClass: 'status-empty',
    },
    {
        id: 'Confirmation',
        name: 'Confirmation',
        status: 20,
        cssClass: 'status-pay',
    },
    {
        id: 'Payment',
        name: 'Payment',
        status: 30,
        cssClass: 'status-exchange',
    },
    {
        id: 'Exchanging',
        name: 'Exchanging',
        status: 40,
        cssClass: 'status-exchange',
    },
    {
        id: 'ExchangingInProgress',
        name: 'Exchanging in progress',
        status: 50,
        cssClass: 'status-exchange',
    },
    {
        id: 'ReadyToSend',
        name: 'Ready to send',
        status: 60,
        cssClass: 'status-withdrawal',
    },
    {
        id: 'Sending',
        name: 'Sending',
        status: 70,
        cssClass: 'status-withdrawal',
    },
    {
        id: 'Success',
        name: 'Success',
        status: 80,
        cssClass: 'status-completed',
    },
    {
        id: 'Overdue',
        name: 'Overdue',
        status: 110,
        cssClass: 'status-overdue',
    },
    {
        id: 'Refunded',
        name: 'Refunded',
        status: 100,
        cssClass: 'status-returned',
    },
    {
        id: 'Frozen',
        name: 'Frozen',
        status: 90,
        cssClass: 'status-hold',
    },
    {
        id: 'Suspended',
        name: 'Suspended',
        status: 120,
        cssClass: 'status-profit',
    },
];

export const ALLOW_STATUS = [1, 2, 3, 4, 5];
