<template>
  <div
    ref="tagsFilter"
    class="tags-filter"
    @click="tagsSearchToggle"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M5 16.6667L5.83333 13.3334H2.5L2.91667 11.6667H6.25L7.08333 8.33337H3.75L4.16667 6.66671H7.5L8.33333 3.33337H10L9.16667 6.66671H12.5L13.3333 3.33337H15L14.1667 6.66671H17.5L17.0833 8.33337H13.75L12.9167 11.6667H16.25L15.8333 13.3334H12.5L11.6667 16.6667H10L10.8333 13.3334H7.5L6.66667 16.6667H5ZM7.91667 11.6667H11.25L12.0833 8.33337H8.75L7.91667 11.6667Z"
        fill="#0065FF"
      />
    </svg>
    Tags/Category
    <perfect-scrollbar
      v-if="tagsList.length && isTagsSearch"
      class="dropdown"
    >
      <div
        v-for="(tagItem,index) in tagsList"
        :key="index"
        class="dropdown-item"
        @click="tagsSearchHandler(tagItem)"
      >
        <div>
          {{ tagItem.name }}
        </div>
        <svg
          v-if="selectedTag && selectedTag === tagItem"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_4068_2939)">
            <path
              d="M7.49896 13.5L4.58229 10.5833C4.25729 10.2583 3.74063 10.2583 3.41563 10.5833C3.09062 10.9083 3.09062 11.425 3.41563 11.75L6.90729 15.2416C7.23229 15.5666 7.75729 15.5666 8.08229 15.2416L16.9156 6.41664C17.2406 6.09164 17.2406 5.57498 16.9156 5.24998C16.5906 4.92498 16.074 4.92498 15.749 5.24998L7.49896 13.5Z"
              fill="#0A68F7"
            />
          </g>
          <defs>
            <clipPath id="clip0_4068_2939">
              <rect
                width="20"
                height="20"
                fill="white"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import {onBeforeUnmount, onMounted, ref} from 'vue';
import {PerfectScrollbar} from 'vue3-perfect-scrollbar';
import BlogService from '@/services/blog.service';

export default {
  name: "TagsFilter",
  components: {
    PerfectScrollbar
  },
  props: {
    isTagsSearch: {
      type: Boolean,
      required: true
    },
    tagsSearchToggle: {
      type: Function,
      required: true
    },
    tagsSearchHandler: {
      type: Function,
      required: true
    },
    selectedTag: {
      type: String,
      required: true
    }
  },
  emits: ['handleTagsSearchClickOutside'],
  setup(props, { emit }) {
    const tagsFilter = ref(null);

    const handleClickOutside = (event) => {
      if (tagsFilter.value && !tagsFilter.value.contains(event.target)) {
        emit('handleTagsSearchClickOutside');
      }
    };

    onMounted(() => {
      document.addEventListener('click', handleClickOutside);
    });

    onBeforeUnmount(() => {
      document.removeEventListener('click', handleClickOutside);
    });

    return {
      tagsFilter,
    };
  },
  data() {
    return {
      tagsList: [],
    }
  },
  mounted() {
    this.getMarket()
  },
  methods: {
    getMarket() {
      BlogService.getTags().then((res) => {
        if (res.status === 200) {
          this.tagsList = res.data.result;
        }
      }).catch((error) => {
        console.log(error.response.data.message)
      });
    },
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables';

.tags-filter {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 6px 12px 6px 6px;
  height: 32px;
  min-width: fit-content;
  font-size: 12px;
  color: $blue-color;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  background: $light-gray-color;
  border-radius: 8px;
  cursor: pointer;
  box-sizing: border-box;

  &:hover {
    background: $light-gray-hover-color;
  }

  .dropdown {
    position: absolute;
    background: white;
    top: 37px;
    left: -10px;
    height: 208px;
    width: 180px;
    z-index: 999;
    border-radius: 8px;
    padding: 8px 0;
    box-shadow: 0 1px 4px 0 rgba(6, 59, 122, 0.12), 0 4px 24px 0 rgba(6, 59, 122, 0.08);
    box-sizing: border-box;

    .dropdown-item {
      padding: 6px 12px;
      color: $black-color1;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      cursor: pointer;
      text-transform: capitalize;
      transition: all 0.3s ease-in-out;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      &:hover {
        background: $hover-color;
      }

    }
  }
}
</style>
