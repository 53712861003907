<template>
  <AuthLayout>
    <template #main>
      <form
        id="login-form"
        class="form-input"
      >
        <h2 class="title">
          Log in
        </h2>
        <div class="inputs">
          <div
            class="form-input-group"
            :class="{ 'form-group--error': !isLoginValid() || error}"
          >
            <label
              for="login"
              class="input-title"
            >Login</label>
            <input
              id="login"
              v-model.trim="form.email"
              type="text"
              class="form-control"
              :class="{ 'no-valid': !isLoginValid(), 'valid': isLoginValid(), 'active-input': form.password.length }"
            >
            <div class="desc">
              <label
                v-if="!isLoginValid() && !form.email"
                class="desc-text"
              >Please
                enter your
                email</label>
              <label
                v-if="error"
                class="desc-text"
              >Login or password invalid</label>
            </div>
          </div>
          <div
            class="form-input-group"
            :class="{ 'form-group--error': !isPasswordValid() || error}"
          >
            <label
              for="password"
              class="input-title"
            >Password</label>
            <input
              id="password"
              v-model="form.password"
              type="password"
              class="form-control"
              :class="{ 'active-input': form.password.length }"
            >
            <div
              class="desc"
              :class="{ 'right': form.password.length }"
            >
              <label
                v-if="!isPasswordValid() && !form.password"
                class="desc-text"
              >Please
                enter your
                password</label>
            </div>
          </div>
        </div>
        <div class="buttons">
          <button
            class="col btn-submit"
            type="button"
            @click="submitForm"
          >
            Log in
          </button>
        </div>
      </form>
    </template>
  </AuthLayout>
</template>

<script>
import AuthLayout from "../../components/admin/AuthLayout.vue";

export default {
  name: "Login",
  components: { AuthLayout },
  data() {
    return {
      form: {
        email: '',
        password: ''
      },
      error: ''
    }
  },
  methods: {
    isLoginValid() {
      return /^[A-Za-z0-9_]+[0-9]$/.test(this.form.email);
    },
    isPasswordValid() {
      return this.form.password.length >= 1;
    },
    submitForm() {
      const credential = {
        email: this.form.email,
        password: this.form.password
      };

      if (this.isLoginValid() || this.isPasswordValid()) {
        console.log('asd')
        this.$store.dispatch('login', credential).then(() => {
          this.$router.push({ name: 'Main dashboard' });
        }).catch((error) => {
          this.error = error;
        });
      }

    },
  }
}
</script>
