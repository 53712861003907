<template>
  <tr
    class="blog-list-item-tr"
  >
    <td
      class="table-data-time"
      :class="{'grey': article.status === 0}"
    >
      {{ dataFormatting(article.publishDate, 'DD.MM.YY') }} <br>
      {{ dataFormatting(article.publishDate, 'HH:mm') }}
    </td>
    <td class="table-image">
      <img
        v-if="!article.imageUrl"
        src="../../../assets/img/png/blog--no-img.png"
        alt="post image"
      >
      <img
        v-if="article.imageUrl"
        :src="`https://swapuz.com${article.imageUrl}`"
        alt="post image"
      >
    </td>
    <td
      class="table-title"
      :class="{'grey': article.status === 0}"
    >
      {{ article.title }}
    </td>
    <td
      class="table-tags-category"
      :class="{'grey': article.status === 0}"
    >
      <div>
        <span
          v-for="(tag,index) in article.tags"
          :key="index"
        >
          # {{ tag.name }}
        </span>
      </div>
    </td>
    <td
      class="table-author"
      :class="{'grey': article.status === 0}"
    >
      <div>
        <img
          v-if="article?.author?.avatar"
          :src="'https://swapuz.com/'+ article?.author?.avatar"
          alt="author avatar"
        >
        <svg
          v-if="!article?.author?.avatar"
          width="57"
          height="57"
          viewBox="0 0 57 57"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        ><rect
          width="57"
          height="57"
          fill="#EBEDF0"
        /><path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M34.3561 14.1985C35.2497 13.657 36.3945 14.2858 36.4167 15.3304L36.5515 21.6622L43.1114 22.793C44.1411 22.9705 44.5925 24.1961 43.9239 24.9991L39.5587 30.242L42.8737 32.9055C43.6882 33.5599 43.4888 34.8507 42.5148 35.2289L36.5244 37.5547L37.2587 41.2568C37.462 42.2817 36.4796 43.1423 35.4903 42.8059L31.2172 41.3528C26.3436 43.7196 20.5046 42.4925 16.9839 38.4768C22.1986 39.9823 26.3782 38.4087 28.3621 36.782C28.8452 36.3859 28.9157 35.6732 28.5197 35.1901C28.1236 34.7071 27.4109 34.6365 26.9278 35.0326C25.281 36.3829 20.7831 38.1578 14.9606 35.2628C12.6495 29.8598 14.6066 23.4407 19.7772 20.307C22.0523 18.928 24.6035 18.395 27.0642 18.618L34.3561 14.1985ZM23.8019 30.1945C22.7334 30.842 20.4965 29.9394 19.8489 28.8709C19.2013 27.8025 19.5425 26.4113 20.611 25.7637C21.6795 25.1162 25.4827 23.4292 26.1303 24.4977C26.7779 25.5662 24.8704 29.5469 23.8019 30.1945Z"
          fill="#C4C4C4"
        /></svg>
        <span>{{ article?.author?.login ? article?.author?.login : 'Null' }}</span>
      </div>
    </td>
    <td class="table-status">
      <div class="status-wrapper">
        <div
          class="status-button"
          :class="{'grey': article.status === 0}"
        >
          {{ article.status === 1 ? 'Published' : 'Unpublished' }}
        </div>

        <div class="edit-wrapper">
          <button
            class="edit"
            @click="editArticle"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M4.16667 15.8333H5.35417L13.5 7.6875L12.3125 6.5L4.16667 14.6458V15.8333ZM2.5 17.5V13.9583L13.5 2.97917C13.6667 2.82639 13.8507 2.70833 14.0521 2.625C14.2535 2.54167 14.4653 2.5 14.6875 2.5C14.9097 2.5 15.125 2.54167 15.3333 2.625C15.5417 2.70833 15.7222 2.83333 15.875 3L17.0208 4.16667C17.1875 4.31944 17.309 4.5 17.3854 4.70833C17.4618 4.91667 17.5 5.125 17.5 5.33333C17.5 5.55556 17.4618 5.76736 17.3854 5.96875C17.309 6.17014 17.1875 6.35417 17.0208 6.52083L6.04167 17.5H2.5ZM12.8958 7.10417L12.3125 6.5L13.5 7.6875L12.8958 7.10417Z"
                fill="#0065FF"
              />
            </svg>
          </button>
          <button
            class="link"
            :class="{'disabled': article.status === 0}"
            @click="openLink"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M2.16667 15.5C1.70833 15.5 1.31597 15.3368 0.989583 15.0104C0.663194 14.684 0.5 14.2917 0.5 13.8333V2.16667C0.5 1.70833 0.663194 1.31597 0.989583 0.989583C1.31597 0.663194 1.70833 0.5 2.16667 0.5H13.8333C14.2917 0.5 14.684 0.663194 15.0104 0.989583C15.3368 1.31597 15.5 1.70833 15.5 2.16667V13.8333C15.5 14.2917 15.3368 14.684 15.0104 15.0104C14.684 15.3368 14.2917 15.5 13.8333 15.5H2.16667ZM2.16667 13.8333H13.8333V3.83333H2.16667V13.8333ZM8 12.1667C6.86111 12.1667 5.84375 11.8576 4.94792 11.2396C4.05208 10.6215 3.40278 9.81944 3 8.83333C3.40278 7.84722 4.05208 7.04514 4.94792 6.42708C5.84375 5.80903 6.86111 5.5 8 5.5C9.13889 5.5 10.1562 5.80903 11.0521 6.42708C11.9479 7.04514 12.5972 7.84722 13 8.83333C12.5972 9.81944 11.9479 10.6215 11.0521 11.2396C10.1562 11.8576 9.13889 12.1667 8 12.1667ZM8 10.9167C8.77778 10.9167 9.48611 10.7326 10.125 10.3646C10.7639 9.99653 11.2639 9.48611 11.625 8.83333C11.2639 8.18056 10.7639 7.67014 10.125 7.30208C9.48611 6.93403 8.77778 6.75 8 6.75C7.22222 6.75 6.51389 6.93403 5.875 7.30208C5.23611 7.67014 4.73611 8.18056 4.375 8.83333C4.73611 9.48611 5.23611 9.99653 5.875 10.3646C6.51389 10.7326 7.22222 10.9167 8 10.9167ZM8 10.0833C8.34722 10.0833 8.64236 9.96181 8.88542 9.71875C9.12847 9.47569 9.25 9.18056 9.25 8.83333C9.25 8.48611 9.12847 8.19097 8.88542 7.94792C8.64236 7.70486 8.34722 7.58333 8 7.58333C7.65278 7.58333 7.35764 7.70486 7.11458 7.94792C6.87153 8.19097 6.75 8.48611 6.75 8.83333C6.75 9.18056 6.87153 9.47569 7.11458 9.71875C7.35764 9.96181 7.65278 10.0833 8 10.0833Z"
                fill="#0065FF"
              />
            </svg>
          </button>
          <!--          <button class="delete">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M5.8335 17.5C5.37516 17.5 4.9828 17.3368 4.65641 17.0104C4.33002 16.684 4.16683 16.2917 4.16683 15.8333V5H3.3335V3.33333H7.50016V2.5H12.5002V3.33333H16.6668V5H15.8335V15.8333C15.8335 16.2917 15.6703 16.684 15.3439 17.0104C15.0175 17.3368 14.6252 17.5 14.1668 17.5H5.8335ZM14.1668 5H5.8335V15.8333H14.1668V5ZM7.50016 14.1667H9.16683V6.66667H7.50016V14.1667ZM10.8335 14.1667H12.5002V6.66667H10.8335V14.1667Z"
                fill="#FF603D"
              />
            </svg>
          </button>-->
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import {mapGetters} from 'vuex';
import {STATUSES} from "../blogArticlesData";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

export default {
  name: "BlogListItem",
  props: {
    // eslint-disable-next-line vue/require-prop-types
    article: {
      required: true
    }
  },
  data() {
    return {
      ExchangeStatusList: STATUSES,
      isStatusDropdownOpen: false,
      currentStatusIndex: null
    }
  },
  computed: {
    ...mapGetters({
      Notification: 'getNotification',
    }),
  },
  mounted() {
    document.addEventListener('click', this.statusClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.statusClickOutside);
  },
  methods: {
    editArticle() {
      this.$router.push({ name: 'Article Editor', params: {article: this.article.id} });
    },
    dataFormatting(value, format) {
      dayjs.extend(utc)
      dayjs.extend(timezone)

      return dayjs.utc(value).local().format(format)
    },
    setNotification(data) {
      let result = {};

      if (data.name) {
        result = {
          name: data.name,
          value: data.value,
        }
      }
      this.$store.commit('setNotification', result);
    },
    openLink() {
      if (this.article.status === 1) {
        window.open(`https://swapuz.com/blog/${this.article.id}-${this.article.slag}`, '_blank');
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables';

  .blog-list-item-tr {
    height: 56px;
    border-bottom: 1px solid #EAEFF4;
    transition: .3s;

    &:hover {
      background: #F5F9FF;
    }

    td {
      position: relative;
      color: $black-color1;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.28px;

      span {
        color: $gray-color;
      }
    }

    td.table-data-time {
      padding-left: max(2.62%, 32px);
      padding-right: max(1.64%, 20px);
      width: 10.49%;
    }

    td.table-data-time.grey {
      color: $another-grey-color;
    }

    td.table-image {
      padding-right: max(1.64%, 20px);
      width: 6.56%;

      img {
        width: 100%;
        max-width: 60px;
        border-radius: 4px;
      }
    }

    td.table-title {
      padding-right: max(1.64%, 20px);
      width: 26.23%;
    }

    td.table-title.grey {
      color: $another-grey-color;
    }

    td.table-tags-category {
      padding-right: max(1.64%, 20px);
      width: 16.56%;
      color: $gray-color;

      div {
        display: flex;
        gap: 2px 6px;
        flex-wrap: wrap;
      }
    }

    td.table-tags-category.grey {
      span {
        color: $another-grey-color;
      }
    }

    td.table-author {
      width: 11.80%;
      margin-right: max(1.64%, 20px);

      div {
        display: flex;
        align-items: center;
      }

      svg,img {
        margin-right: 8px;
        height: 24px;
        width: 24px;
        border-radius: 50%;
      }
    }

    td.table-author.grey {
      color: $another-grey-color;
    }

    td.table-status {
      width: 14.92%;
      padding-right: max(2.62%, 32px);

      div.status-wrapper {
        display: flex;
        align-items: center;
        gap: 6px;

        .status-button {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 6px;
          height: 24px;
          width: 100%;
          max-width: 88px;
          min-width: 70px;
          color: #FFF;
          background: #02C076;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px;
          letter-spacing: -0.12px;
          text-overflow: ellipsis;
          border-radius: 6px;
          transition: .3s all;

          &-remaining {
            background: $status-empty;
          }
          &-pay {
            background: $status-pay;
          }
        }

        .status-button.grey {
          background: $another-grey-color;
        }

        div.edit-wrapper {
          display: flex;
          gap: 6px;

          button {
            display: flex;
            align-items: center;
            width: 32px;
            height: 32px;
            border-radius: 8px;
            outline: none;
            border: none;
            cursor: pointer;
            box-sizing: border-box;
            transition: 0.3s;
          }

          button.edit, button.link {
            justify-content: center;
            background: $light-gray-color;
          }

          button.link.disabled {
            cursor: not-allowed;

            svg path {
              fill: #A1BBD9;
            }
          }

          /*button.delete {
            background: $pale-red;
          }*/
        }
      }
    }
  }

</style>
