<template>
  <AccountLayoutNew>
    <template #title>
      <div class="layout-header">
        <h2>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
          >
            <path
              d="M2 18C1.45 18 0.979167 17.8042 0.5875 17.4125C0.195833 17.0208 0 16.55 0 16V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H18C18.55 0 19.0208 0.195833 19.4125 0.5875C19.8042 0.979167 20 1.45 20 2V16C20 16.55 19.8042 17.0208 19.4125 17.4125C19.0208 17.8042 18.55 18 18 18H2ZM2 16H18V2H2V16ZM4 14H16V12H4V14ZM4 10H8V4H4V10ZM10 10H16V8H10V10ZM10 6H16V4H10V6Z"
              fill="#0065FF"
            />
          </svg>
          Blog Articles
        </h2>
      </div>
    </template>
    <template #content>
      <main class="main-block article-editor">
        <loading
          v-model:active="isLoading"
          :can-cancel="true"
          :is-full-page="true"
          color="#0A68F7"
        />
        <div class="header-wrapper">
          <h1 class="article-editor-title">
            Article editor
          </h1>
          <button
            class="close-button"
            @click="$router.push({ name: 'Blog Articles' })"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M5.33366 15.8333L4.16699 14.6666L8.83366 9.99996L4.16699 5.33329L5.33366 4.16663L10.0003 8.83329L14.667 4.16663L15.8337 5.33329L11.167 9.99996L15.8337 14.6666L14.667 15.8333L10.0003 11.1666L5.33366 15.8333Z"
                fill="#0065FF"
              />
            </svg>
          </button>
        </div>
        <section class="article-editor-form-container">
          <div class="left-block">
            <h4 class="sub-title">
              Image
            </h4>

            <div class="image-placeholder">
              <img
                v-if="articleImageInline"
                :src="articleImageInline"
                alt="upload image"
              >
              <img
                v-if="articleImage"
                :src="`https://swapuz.com${articleImage}`"
                alt="upload image"
              >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="80"
                height="80"
                viewBox="0 0 80 80"
                fill="none"
              >
                <path
                  d="M15 70C13.6667 70 12.5 69.5 11.5 68.5C10.5 67.5 10 66.3333 10 65V15C10 13.6667 10.5 12.5 11.5 11.5C12.5 10.5 13.6667 10 15 10H65C66.3333 10 67.5 10.5 68.5 11.5C69.5 12.5 70 13.6667 70 15V65C70 66.3333 69.5 67.5 68.5 68.5C67.5 69.5 66.3333 70 65 70H15ZM15 65H65V15H15V65ZM19.6667 56.9167H60.4167L48.1667 40.5833L37.1667 54.8333L29.4167 44.25L19.6667 56.9167ZM15 65V15V65Z"
                  fill="#527AA1"
                  fill-opacity="0.12"
                />
              </svg>
              <button class="upload-image">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M4.16667 17.5C3.70833 17.5 3.31597 17.3368 2.98958 17.0104C2.66319 16.684 2.5 16.2917 2.5 15.8333V4.16667C2.5 3.70833 2.66319 3.31597 2.98958 2.98958C3.31597 2.66319 3.70833 2.5 4.16667 2.5H10.8333V4.16667H4.16667V15.8333H15.8333V9.16667H17.5V15.8333C17.5 16.2917 17.3368 16.684 17.0104 17.0104C16.684 17.3368 16.2917 17.5 15.8333 17.5H4.16667ZM5 14.1667H15L11.875 10L9.375 13.3333L7.5 10.8333L5 14.1667ZM14.1667 7.5V5.83333H12.5V4.16667H14.1667V2.5H15.8333V4.16667H17.5V5.83333H15.8333V7.5H14.1667Z"
                    fill="#0065FF"
                  />
                </svg>
                <input
                  class="file-input"
                  type="file"
                  accept="image/*"
                  @change="onFileChange"
                >
                Upload image
              </button>
            </div>
            <p class="image-hint">
              Use image with ratio 3:2, recommened resolution is 1200x800px or more, min resolution is 720x480px
            </p>

            <h4 class="sub-title">
              Tags/Category
            </h4>
            <div class="checkbox-wrapper">
              <check-box
                v-for="checkbox in checkboxes"
                :key="checkbox.id"
                v-model="checkbox.checked"
                :label="checkbox.name"
              />

              <div
                v-if="!isNewTagOpen"
                class="add-new-tag"
                @click="newTagHandler"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M9.1665 10.8333H4.1665V9.16666H9.1665V4.16666H10.8332V9.16666H15.8332V10.8333H10.8332V15.8333H9.1665V10.8333Z"
                    fill="#0065FF"
                  />
                </svg>
                Add new tag
              </div>
              <div
                v-if="isNewTagOpen"
                class="new-tag-form"
              >
                <label>
                  <input
                    v-model="newTag"
                    type="text"
                    class="editor-form-input"
                    :class="{'invalid': newTagIsError}"
                    placeholder="Enter a tag name"
                    @focus="newTagIsError = false"
                  >
                  <span
                    v-if="newTagIsError"
                    class="invalid-text"
                  >
                    Please enter a valid tag name containing only letters.
                  </span>
                </label>
                <button @click="createNewTag">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M9.1665 10.8333H4.1665V9.16666H9.1665V4.16666H10.8332V9.16666H15.8332V10.8333H10.8332V15.8333H9.1665V10.8333Z"
                      fill="#0065FF"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>

          <div class="right-block">
            <h4 class="sub-title">
              Title
            </h4>
            <label>
              <input
                v-model="title"
                type="text"
                class="editor-form-input"
                :class="{'invalid': titleIsError}"
                placeholder="Enter a title up to 60 characters"
                @focus="titleIsError = false"
              >
              <span
                v-if="titleIsError"
                class="invalid-text"
              >
                Title must be up to 60 characters long and cannot be empty.
              </span>
            </label>

            <h4 class="sub-title">
              Announce
            </h4>
            <label>
              <textarea
                ref="textarea"
                v-model="announce"
                class="editor-form-textarea"
                :class="{'invalid': announceIsError}"
                placeholder="Enter a announce up to 150 characters"
                @focus="announceIsError = false"
                @input="adjustHeight"
              />
              <span
                v-if="announceIsError"
                class="invalid-text"
              >Announce must be up to 150 characters long and cannot be empty.
              </span>
            </label>

            <h4 class="sub-title">
              Text
            </h4>
            <QuillEditor
              v-model:content="htmlContent"
              content-type="html"
              :options="options"
              :modules="modules"
              @ready="onEditorReady"
            />
          </div>
        </section>
        <section class="action-section">
          <div class="action-status-wrapper">
            <span>status</span>
            <span v-if="status === null">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M4.16667 17.5C3.70833 17.5 3.31597 17.3368 2.98958 17.0104C2.66319 16.684 2.5 16.2917 2.5 15.8333V4.16667C2.5 3.70833 2.66319 3.31597 2.98958 2.98958C3.31597 2.66319 3.70833 2.5 4.16667 2.5H11.6042L9.9375 4.16667H4.16667V15.8333H15.8333V10.0417L17.5 8.375V15.8333C17.5 16.2917 17.3368 16.684 17.0104 17.0104C16.684 17.3368 16.2917 17.5 15.8333 17.5H4.16667ZM7.5 12.5V8.95833L15.1458 1.3125C15.3125 1.14583 15.5 1.02083 15.7083 0.9375C15.9167 0.854167 16.125 0.8125 16.3333 0.8125C16.5556 0.8125 16.7674 0.854167 16.9687 0.9375C17.1701 1.02083 17.3542 1.14583 17.5208 1.3125L18.6875 2.5C18.8403 2.66667 18.9583 2.85069 19.0417 3.05208C19.125 3.25347 19.1667 3.45833 19.1667 3.66667C19.1667 3.875 19.1285 4.07986 19.0521 4.28125C18.9757 4.48264 18.8542 4.66667 18.6875 4.83333L11.0417 12.5H7.5ZM9.16667 10.8333H10.3333L15.1667 6L14.5833 5.41667L13.9792 4.83333L9.16667 9.64583V10.8333Z"
                  fill="#A1BBD9"
                />
              </svg>
              Drafts
            </span>

            <span v-if="status === 0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M17.5 5.83333V15.8333C17.5 16.2917 17.3368 16.684 17.0104 17.0104C16.684 17.3368 16.2917 17.5 15.8333 17.5H4.16667C3.70833 17.5 3.31597 17.3368 2.98958 17.0104C2.66319 16.684 2.5 16.2917 2.5 15.8333V4.16667C2.5 3.70833 2.66319 3.31597 2.98958 2.98958C3.31597 2.66319 3.70833 2.5 4.16667 2.5H14.1667L17.5 5.83333ZM15.8333 6.54167L13.4583 4.16667H4.16667V15.8333H15.8333V6.54167ZM10 15C10.6944 15 11.2847 14.7569 11.7708 14.2708C12.2569 13.7847 12.5 13.1944 12.5 12.5C12.5 11.8056 12.2569 11.2153 11.7708 10.7292C11.2847 10.2431 10.6944 10 10 10C9.30556 10 8.71528 10.2431 8.22917 10.7292C7.74306 11.2153 7.5 11.8056 7.5 12.5C7.5 13.1944 7.74306 13.7847 8.22917 14.2708C8.71528 14.7569 9.30556 15 10 15ZM5 8.33333H12.5V5H5V8.33333ZM4.16667 6.54167V15.8333V4.16667V6.54167Z"
                  fill="#A1BBD9"
                />
              </svg>
              Unpublished
            </span>

            <span
              v-if="status === 1"
              :class="{'green': status === 1}"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M7.9585 15L3.2085 10.25L4.396 9.0625L7.9585 12.625L15.6043 4.97917L16.7918 6.16667L7.9585 15Z"
                  fill="#02C076"
                />
              </svg>
              Published
            </span>
          </div>
          <div class="action-buttons-wrapper">
            <button @click="$router.push({ name: 'Blog Articles' })">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M7 19V17H14.1C15.15 17 16.0625 16.6667 16.8375 16C17.6125 15.3333 18 14.5 18 13.5C18 12.5 17.6125 11.6667 16.8375 11C16.0625 10.3333 15.15 10 14.1 10H7.8L10.4 12.6L9 14L4 9L9 4L10.4 5.4L7.8 8H14.1C15.7167 8 17.1042 8.525 18.2625 9.575C19.4208 10.625 20 11.9333 20 13.5C20 15.0667 19.4208 16.375 18.2625 17.425C17.1042 18.475 15.7167 19 14.1 19H7Z"
                  fill="#0065FF"
                />
              </svg>
              Exit
            </button>
            <button
              v-if="status === null || status === 0"
              @click="submitArticleEditor(0)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M21 7V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H17L21 7ZM19 7.85L16.15 5H5V19H19V7.85ZM12 18C12.8333 18 13.5417 17.7083 14.125 17.125C14.7083 16.5417 15 15.8333 15 15C15 14.1667 14.7083 13.4583 14.125 12.875C13.5417 12.2917 12.8333 12 12 12C11.1667 12 10.4583 12.2917 9.875 12.875C9.29167 13.4583 9 14.1667 9 15C9 15.8333 9.29167 16.5417 9.875 17.125C10.4583 17.7083 11.1667 18 12 18ZM6 10H15V6H6V10ZM5 7.85V19V5V7.85Z"
                  fill="#0065FF"
                />
              </svg>
              Save
            </button>
            <button
              v-if="status !== 0 && status !== null"
              @click="previewLink"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5ZM5 19H19V7H5V19ZM12 17C10.6333 17 9.4125 16.6292 8.3375 15.8875C7.2625 15.1458 6.48333 14.1833 6 13C6.48333 11.8167 7.2625 10.8542 8.3375 10.1125C9.4125 9.37083 10.6333 9 12 9C13.3667 9 14.5875 9.37083 15.6625 10.1125C16.7375 10.8542 17.5167 11.8167 18 13C17.5167 14.1833 16.7375 15.1458 15.6625 15.8875C14.5875 16.6292 13.3667 17 12 17ZM12 15.5C12.9333 15.5 13.7833 15.2792 14.55 14.8375C15.3167 14.3958 15.9167 13.7833 16.35 13C15.9167 12.2167 15.3167 11.6042 14.55 11.1625C13.7833 10.7208 12.9333 10.5 12 10.5C11.0667 10.5 10.2167 10.7208 9.45 11.1625C8.68333 11.6042 8.08333 12.2167 7.65 13C8.08333 13.7833 8.68333 14.3958 9.45 14.8375C10.2167 15.2792 11.0667 15.5 12 15.5ZM12 14.5C12.4167 14.5 12.7708 14.3542 13.0625 14.0625C13.3542 13.7708 13.5 13.4167 13.5 13C13.5 12.5833 13.3542 12.2292 13.0625 11.9375C12.7708 11.6458 12.4167 11.5 12 11.5C11.5833 11.5 11.2292 11.6458 10.9375 11.9375C10.6458 12.2292 10.5 12.5833 10.5 13C10.5 13.4167 10.6458 13.7708 10.9375 14.0625C11.2292 14.3542 11.5833 14.5 12 14.5Z"
                  fill="#0065FF"
                />
              </svg>
              Preview
            </button>
            <button
              v-if="status !== null && status !== 0"
              class="unpublish-button"
              @click="submitArticleEditor(0,'unpublish')"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22ZM12 20C12.9 20 13.7667 19.8542 14.6 19.5625C15.4333 19.2708 16.2 18.85 16.9 18.3L5.7 7.1C5.15 7.8 4.72917 8.56667 4.4375 9.4C4.14583 10.2333 4 11.1 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20ZM18.3 16.9C18.85 16.2 19.2708 15.4333 19.5625 14.6C19.8542 13.7667 20 12.9 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C11.1 4 10.2333 4.14583 9.4 4.4375C8.56667 4.72917 7.8 5.15 7.1 5.7L18.3 16.9Z"
                  fill="#FF603D"
                />
              </svg>
              Unpublish
            </button>
            <button
              class="publish-button"
              @click="submitArticleEditor(1)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M11 20V11.85L8.4 14.45L7 13L12 8L17 13L15.6 14.45L13 11.85V20H11ZM4 9V6C4 5.45 4.19583 4.97917 4.5875 4.5875C4.97917 4.19583 5.45 4 6 4H18C18.55 4 19.0208 4.19583 19.4125 4.5875C19.8042 4.97917 20 5.45 20 6V9H18V6H6V9H4Z"
                  fill="white"
                />
              </svg>
              {{ status === 0 || status === null ? 'Publish' : 'Publish with changes' }}
            </button>
          </div>
        </section>
      </main>
    </template>
  </AccountLayoutNew>
</template>

<script>
import {defineComponent} from 'vue';
import AccountLayoutNew from '@/components/admin/AccountLayoutNew.vue';
import Loading from 'vue-loading-overlay';
import CheckBox from '@/components/admin/CheckBox.vue';
import {Quill, QuillEditor} from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import BlogService from '@/services/blog.service';
import ImageUploader from 'quill-image-uploader';
import axios from 'axios';
import FileService from '@/services/file.service';
import imageCompression from 'browser-image-compression';

Quill.register('modules/imageUploader', ImageUploader);

export default defineComponent({
  components: {
    CheckBox,
    Loading, AccountLayoutNew,QuillEditor
  },
  props: {
    article: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isLoading: true,
      postId: null,
      postSlag: null,
      postPublishDate: null,
      // image
      articleImage: '',
      articleImageInline: '',
      selectedFile: null,
      // new tag
      isNewTagOpen: false,
      newTagIsError: false,
      newTag: '',
      // checkbox
      checkboxes: [],
      postTags: [],
      // title input
      title: '',
      titleIsError: false,
      // announce input
      announce: '',
      announceIsError: false,
      // status
      status: null,
      // text editor
      htmlContent: '',
      options: {
        modules: {
          toolbar: [
            [{ header: [ 2, 3, false] }],
            ['bold', 'italic', 'underline'],
            ['link', 'image',/* 'video'*/],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
          ],
          imageUploader: {
            upload: async (file) => {
              const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
              }

              const compressedFile = await imageCompression(file, options);

              return new Promise((resolve, reject) => {
                BlogService.UploadArticleImage(compressedFile).then((result) => {
                  if (result.status === 200) {
                    resolve('https://swapuz.com'+result.data.result);
                  }
                }).catch((error) => {
                  reject('Upload failed');
                  console.error(error);
                })
              });
            },
          },
        },
      },
    }
  },
  mounted() {
    this.initialArticle()
    this.adjustHeight()
  },
  beforeUnmount() {

  },
  methods: {
    onEditorReady(quill) {
      quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
        delta.ops.forEach(op => {
          if (op.attributes) {
            delete op.attributes.style;
          }
        });

        return delta;
      });
    },
    async imageHandler() {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.click();

      /*input.onchange = async () => {
        const file = input.files[0];
        if (file) {
          const formData = new FormData();
          formData.append('image', file);

          try {
            const response = await axios.post('/upload-image', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });

            const url = response.data.url;

            const range = this.$refs.editor.quill.getSelection(); // Получаем текущую позицию в редакторе
            this.$refs.editor.quill.insertEmbed(range.index, 'image', url); // Вставляем изображение
          } catch (error) {
            console.error('Ошибка загрузки изображения', error);
          }
        }
      };*/
    },
    async initialArticle() {
      if (this.article === 'new') {
        await this.getTags()
      } else {
        await this.getTags()
        await this.getPost()
      }
      this.isLoading = false
    },
    async getTags() {
      await BlogService.getTags().then((res) => {
        if (res.status === 200) {
          this.checkboxes = res.data.result.map(item => ({
            ...item,
            checked: false,
          }));
        }
      }).catch((error) => {
        console.log(error.response.data.message)
      });
    },
    async getPost() {
      await BlogService.getPost(this.article).then((result) => {
        if (result.status === 200) {
          if (result.data.result === null) {
            this.$router.push({ name: 'Blog Articles' })
          }
          this.checkboxes.forEach(checkbox => {
            checkbox.checked = result.data.result.tags.some(postItem => postItem.slag === checkbox.slag);
          });
          this.postId = result.data.result.id
          this.postSlag = result.data.result.slag
          this.postTags = result.data.result.tags
          this.title = result.data.result.title
          this.announce = result.data.result.announce
          this.htmlContent = result.data.result.content
          this.postPublishDate = result.data.result.publishDate
          this.status = result.data.result.status
          console.log(result.data.result)
          this.articleImage = result.data.result.imageUrl
        }
      }).catch((error) => {
        console.log(error.response.data.result, 'get post error')
        this.$router.push({ name: 'Blog Articles' })
      })
    },
    async submitArticleEditor(status) {
      if (!this.notValidatedFormInputs()) {
        if (this.article === 'new') {
          let newPostId
          this.isLoading = true
          const postParams = {
            title: this.title,
            content: this.htmlContent,
            announce: this.announce,
            publishDate: new Date().toISOString(),
            slag: this.getSlagFromName(this.title),
            timeToRead: Math.ceil(this.htmlContent.trim().split(/\s+/).length / 180),
            status: status,
            tagIDs: this.checkboxes.filter(tag => tag.checked).map(tag => tag.id)
          }

          await BlogService.createPost(postParams).then((result) => {
            if (result.status === 200) {
              console.log(result.data.result, 'new post created successfully')
              newPostId = result.data.result.id

            }
          }).catch((error) => {
            console.log(error.response.data.result, 'new post error')
          })

          await BlogService.UpdateImage({postId: newPostId, image: this.selectedFile}).then((result) => {
            if (result.status === 200) {
              console.log('new image updated successfully')
              this.isLoading = false
              this.successPopup('Post','Created successfully');
              this.$router.push({ name: 'Blog Articles' })
            }
          }).catch((error) => {
            console.log(error.response.data.result, 'new image error')
          })
        } else {
          this.isLoading = true
          const postParams = {
            postId: this.postId,
            title: this.title,
            content: this.htmlContent,
            announce: this.announce,
            publishDate: this.postPublishDate,
            slag: this.getSlagFromName(this.title),
            timeToRead: Math.ceil(this.htmlContent.trim().split(/\s+/).length / 180),
            status: status,
            tagIDs: this.checkboxes.filter(tag => tag.checked).map(tag => tag.id)
          }

          await BlogService.editPost(postParams).then((result) => {
            if (result.status === 200) {
              console.log(' post edited successfully')
            }
          }).catch((error) => {
            console.log(error.response.data.result, 'new post error')
          })

          if (this.selectedFile) {
            await BlogService.UpdateImage({postId:this.postId, image: this.selectedFile}).then((result) => {
              if (result.status === 200) {
                this.isLoading = false
                this.successPopup('Post','Edited successfully');
                this.$router.push({ name: 'Blog Articles' })
                console.log('edit image updated successfully')
              }
            }).catch((error) => {
              console.log(error.response.data.result, 'new image error')
            })
          } else {
            this.$router.push({ name: 'Blog Articles' })
            this.successPopup('Post','Edited successfully');
            this.isLoading = false
          }
        }
      }
    },
    successPopup(name,value) {
      this.$store.commit('setSuccess', {
        name: name,
        value: value,
      });
    },
    notValidatedFormInputs() {
      if (!/^[A-Za-z0-9\s-№#$%&!-]{1,60}$/.test(this.title)) {
        this.titleIsError = true
      }

      if (!/^[A-Za-z0-9\s-№#$%&!-]{1,150}$/.test(this.announce)) {
        this.announceIsError = true
      }

      return (this.announceIsError || this.titleIsError)
    },
    getSlagFromName(string) {
      return string
        .toLowerCase()
        .trim()
        .replace(/[^a-z0-9\s/-]/g, '')
        .replace(/\s+/g, '-')
        .replace(/--+/g, '-')
        .replace(/^\//, '')
        .replace(/\/$/, '');
    },
    //new tag
    newTagHandler() {
      this.isNewTagOpen = true
    },
    async createNewTag() {
      if (/^[A-Za-z/& ]{1,15}$/.test(this.newTag)) {
        this.isLoading = true
        const postParams = {
          name: this.newTag,
          description: 'some description',
          status: 1,
          slag: this.getSlagFromName(this.newTag)
        }

        await BlogService.createTag(postParams).then((result) => {
          if (result.status === 200) {
            console.log('new tag added successfully')
          }
        }).catch((error) => {
          console.log(error.response.data.result, 'get post error')
        })
        await this.getTags()
        this.checkboxes.forEach(checkbox => {
          checkbox.checked = this.postTags.some(postItem => postItem.slag === checkbox.slag);
        });
        this.isLoading = false
        this.newTag = ''
        this.isNewTagOpen = false
      } else {
        this.newTagIsError = true
      }
    },
    onFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.selectedFile = file;

        this.articleImageInline = URL.createObjectURL(file);
        this.articleImage = null
      }
    },
    adjustHeight() {
      const textarea = this.$refs.textarea;
      textarea.style.height = '44px';
      textarea.style.height = textarea.scrollHeight > 44 ? textarea.scrollHeight + 'px' : '44px';
    },
    previewLink() {
      window.open(`https://swapuz.com/blog/${this.postId}-${this.postSlag}`, '_blank');
    },
  }
})
</script>

<style lang="scss">
@import "../../../assets/styles/pages/blog-articles/article-editor.scss";
@import "../../../assets/styles/components/quill-editor";
</style>
