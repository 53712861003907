<script setup>

</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clip-path="url(#clip0_3699_421)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.99 9L1 13L4.99 17V14H6V12H4.99V9ZM8 14H7V12H8V14ZM10 14H9V12H10V14ZM11 14H12V12H11V14ZM12 8H11V6H12V8ZM13 8H15.01V11L19 7L15.01 3V6H13V8ZM10 6H9V8H10V6Z"
        fill="#527AA1"
      />
    </g>
    <defs>
      <clipPath id="clip0_3699_421">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<style scoped lang="scss">

</style>
