<script>
import AccountLayout from "@/components/admin/AccountLayout.vue";
import OrderService from "@/services/order.service";
import CoinsSvg from "@/pages/balance/CoinsSvg.vue";
import NoDataBlock from "@/components/admin/NoDataBlock.vue";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import BalanceMirrorAddress from "@/pages/balance/balance-total/partials/BalanceMirrorAddress.vue";
import PercentageBars from "@/pages/balance/balance-total/partials/PercentageBars.vue";
import BalanceMirrorWithdraw from "@/pages/balance/balance-total/partials/BalanceMirrorWithdraw.vue";
import {mapGetters} from "vuex";
import LvProgressBar from "lightvue/progress-bar";
import BalanceMirrorItemPercentage from "@/pages/balance/balance-total/partials/BalanceMirrorItemPercentage.vue";
import CoinsService from "@/services/coins.service";
import PercentageBarsNetworks from "@/pages/balance/balance-total/partials/PercentageBarsNetworks.vue";
import CopyNotification from "@/pages/notification/CopyNotification.vue";
import BalanceMirrorUsdt from "@/pages/balance/balance-total/partials/BalanceMirrorUsdt.vue";
import { PerfectScrollbar } from 'vue3-perfect-scrollbar'

export default {
  name: 'BalanceMirror',
  components: {
    BalanceMirrorUsdt,
    CopyNotification,
    PercentageBarsNetworks,
    CoinsSvg,
    BalanceMirrorItemPercentage,
    LvProgressBar,
    BalanceMirrorWithdraw,
    PercentageBars,
    BalanceMirrorAddress,
    Loading,
    NoDataBlock,
    AccountLayout,
    PerfectScrollbar
  },
  data() {
    return {
      chartDataMounted: [],
      chartDataNodas: null,
      tokensData: [],
      isLoading: false,
      chartDataNode: [],
      chartDataMarket: [],
      originCoins: null,
      selectedTimer: {name: "Off", interval: null},
      dropdownItems: [
        {name: "Off", interval: null},
        {name: "30s", interval: 30000},
        {name: "1m", interval: 60000},
        {name: "2m", interval: 120000},
        {name: "5m", interval: 300000},
        {name: "15m", interval: 900000}
      ],
      timerHandle: false,
      timerId: null,
      timerValue: 0,
      tokensDataArr: [],
      updateText: '',
      isUpdate: false,
      isMobileDevice: this.isMobile(),
    }
  },
  computed: {
    ...mapGetters({
      mirrorWithdrawData: 'getMirrorWithdraw'
    }),
    sortedNodeTokensData() {
      const {tokenOrder, tokenDataMap} = this.sortedTotalTokensData;

      return tokenOrder.map(token => {
        if (token !== 'USDT' && token !== 'USDC') {
          return {
            token,
            ...this.chartDataMounted.node[tokenDataMap[token].token]
          };
        }
        return null;
      }).filter(data => data !== null);
    },
    sortedExchangeTokensData() {
      const {tokenOrder, tokenDataMap} = this.sortedTotalTokensData;

      return tokenOrder.map(token => {
        return {
          token,
          ...this.chartDataMounted.exchange[tokenDataMap[token].token]
        };
      });
    },
    filteredTotalTokens() {
      return this.sortedTotalTokensData.sortedData.filter(token => token.token !== 'USDT_TRX' && token.token !== 'USDT_ETH' && token.token !== 'USDC_TRX' && token.token !== 'USDC_ETH');
    },
    filteredExchangeTokens() {
      return this.sortedExchangeTokensData.filter(token => token.token !== 'USDT_TRX' && token.token !== 'USDT_ETH' && token.token !== 'USDC_TRX' && token.token !== 'USDC_ETH');
    },
    sortedTotalTokensData() {
      const tokens = Object.keys({...this.chartDataMounted.exchange, ...this.chartDataMounted.node});

      const mergedData = tokens.map(token => {
        const exchangeToken = this.chartDataMounted.exchange[token] || {};
        const nodeToken = this.chartDataMounted.node[token] || {};

        return {
          token,
          network: exchangeToken.network || nodeToken.network,
          balanceAvailable: (exchangeToken.balanceAvailable || 0) + (nodeToken.balanceAvailable || 0) + (exchangeToken.balanceLocked || 0) + (nodeToken.balanceLocked || 0),
          balanceLocked: (exchangeToken.balanceLocked || 0) + (nodeToken.balanceLocked || 0),
          balanceUsdt: (exchangeToken.balanceUsdt || 0) + (nodeToken.balanceUsdt || 0)
        };
      });

      const usdtToken = mergedData.find(token => token.token === 'USDT');
      if (usdtToken) {
        const usdtTrxToken = mergedData.find(token => token.token === 'USDT_TRX');
        const usdtEthToken = mergedData.find(token => token.token === 'USDT_ETH');
        if (usdtTrxToken) {
          usdtToken.balanceAvailable += usdtTrxToken.balanceAvailable || 0;
          usdtToken.balanceLocked += usdtTrxToken.balanceLocked || 0;
          usdtToken.balanceUsdt += usdtTrxToken.balanceUsdt || 0;
        }
        if (usdtEthToken) {
          usdtToken.balanceAvailable += usdtEthToken.balanceAvailable || 0;
          usdtToken.balanceLocked += usdtEthToken.balanceLocked || 0;
          usdtToken.balanceUsdt += usdtEthToken.balanceUsdt || 0;
        }
      }

      const usdcToken = mergedData.find(token => token.token === 'USDC');
      if (usdcToken) {
        const usdcTrxToken = mergedData.find(token => token.token === 'USDC_TRX');
        const usdcEthToken = mergedData.find(token => token.token === 'USDC_ETH');
        if (usdcTrxToken) {
          usdcToken.balanceAvailable += usdcTrxToken.balanceAvailable || 0;
          usdcToken.balanceLocked += usdcTrxToken.balanceLocked || 0;
          usdcToken.balanceUsdt += usdcTrxToken.balanceUsdt || 0;
        }
        if (usdcEthToken) {
          usdcToken.balanceAvailable += usdcEthToken.balanceAvailable || 0;
          usdcToken.balanceLocked += usdcEthToken.balanceLocked || 0;
          usdcToken.balanceUsdt += usdcEthToken.balanceUsdt || 0;
        }
      }

      const sortedData = mergedData.sort((a, b) => b.balanceUsdt - a.balanceUsdt);
      const tokenOrder = sortedData.map(tokenData => tokenData.token);
      const tokenDataMap = sortedData.reduce((acc, tokenData) => {
        acc[tokenData.token] = tokenData;
        return acc;
      }, {});

      return {tokenOrder, tokenDataMap, sortedData};
    },
    totalBalance() {
      const balances = this.sortedTotalTokensData.sortedData.map(token => token.balanceUsdt);

      return balances.reduce((total, balance) => total + balance, 0);
    },
  },
  mounted() {
    this.getCoinsList();
    this.getChartsData();
    document.addEventListener('click', this.timerClickOutside);
    window.addEventListener('resize', this.updateDeviceType);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.timerClickOutside);
    window.removeEventListener('resize', this.updateDeviceType);
  },
  methods: {
    getSortedByTokenName(arr) {
      return arr.filter(data => data !== null).sort((a, b) => {
        const topTokens = ["BTC", "ETH", "XMR"];
        const bottomTokens = ["USDC", "USDT"];

        if (topTokens.includes(a.token) && !topTokens.includes(b.token)) {
          return -1;
        } else if (!topTokens.includes(a.token) && topTokens.includes(b.token)) {
          return 1;
        } else if (bottomTokens.includes(a.token) && !bottomTokens.includes(b.token)) {
          return 1;
        } else if (!bottomTokens.includes(a.token) && bottomTokens.includes(b.token)) {
          return -1;
        } else {
          return a.token.localeCompare(b.token);
        }
      })
    },
    getCoinsList() {
      CoinsService.getCoinsListData().then((res) => {
        this.originCoins = res.data.result
      })
    },
    isMobile() {
      return window.innerWidth <= 1079;
    },
    updateDeviceType() {
      this.isMobileDevice = this.isMobile();
    },
    onUpdateHandler() {
      this.updateText = 'Pages refresh';

      this.isUpdate = true;

      setTimeout(() => {
        this.isUpdate = false
      }, 2000);
    },
    getChartsData() {
      OrderService.getBalanceMirror().then(async (res) => {

        await res;

        //console.log(res);

        if (res?.data?.result) {
          const profitData = res.data.result.exchange.Profit;
          delete res.data.result.node.Profit;
          delete res.data.result.exchange.Profit;

          let temporaryChardData = JSON.parse(JSON.stringify(res.data.result));
          temporaryChardData.node.Profit = profitData;

          this.chartDataNodas = temporaryChardData;
          this.chartDataMounted = res.data.result;

          this.tokensData = this.extractTokens(this.chartDataMounted);
          this.tokensDataArr = this.extractTokensArr(this.chartDataMounted)
        }
        this.isLoading = false
      })
    },
    maxBalanceUsdt(arr) {
      return Math.max(...arr.map(token => token.balanceUsdt));
    },
    minBalanceUsdt(arr) {
      return Math.min(...arr.map(token => token.balanceUsdt));
    },
    getHighestBalanceToken(tokens) {
      return tokens.reduce((highest, current) => {
        return highest.balanceUsdt > current.balanceUsdt ? highest : current;
      }, {});
    },
    startProgress() {
      this.timerValue = 0;
      this.interval = setInterval(() => {
        this.timerValue += (100 / (this.selectedTimer.interval / 1000));
        if (this.timerValue >= 100) {
          this.timerValue = 0;
          clearInterval(this.interval);
        }
      }, 1000);
    },
    endProgress() {
      clearInterval(this.interval);
      this.interval = null;
    },
    selectTimerValue(item) {
      this.selectedTimer = item;
      clearInterval(this.timerId);

      if (item.interval) {
        this.startProgress();
        this.timerId = setInterval(this.timerRefresh, item.interval);
      } else {
        this.timerValue = 0
        this.endProgress();
      }
      this.timerHandler();
    },
    timerRefresh() {
      this.getChartsData();

      if (this.selectedTimer.interval) {
        this.timerValue = 0;
        this.startProgress()
      }

      this.onUpdateHandler();
    },
    timerClickOutside(event) {
      if (this.$refs.timer && !this.$refs.timer.contains(event.target)) {
        this.timerHandle = false
      }
    },
    timerHandler() {
      this.timerHandle = !this.timerHandle
    },
    extractTokens(data) {
      let tokens = {};
      let usdtNetworks = [];
      let usdcNetworks = [];
      let orderedTokens = [];

      for (let category in data) {
        for (let token in data[category]) {
          if (token.startsWith("USDT_")) {
            let baseToken = token.replace("USDT_", "");
            if (!tokens[baseToken]) {
              tokens[baseToken] = {name: baseToken, networks: []};
            }
            if (!Array.isArray(tokens[baseToken].networks)) {
              tokens[baseToken].networks = [];
            }
            tokens[baseToken].networks.push({name: token, network: baseToken});
            usdtNetworks.push(token);
          } else if (token.startsWith("USDC_")) {
            let baseToken = token.replace("USDC_", "");
            if (!tokens[baseToken]) {
              tokens[baseToken] = {name: baseToken, networks: []};
            }
            if (!Array.isArray(tokens[baseToken].networks)) {
              tokens[baseToken].networks = [];
            }
            tokens[baseToken].networks.push({name: token, network: baseToken});
            usdcNetworks.push(token);
          } else {
            tokens[token] = {name: token};
          }
        }
      }

      if (usdtNetworks.length > 0) {
        let usdtNetworkObjects = usdtNetworks.map(network => ({name: network, network: network.split('_')[1]}));
        tokens["USDT"] = {name: "USDT", networks: usdtNetworkObjects};
      }

      if (usdcNetworks.length > 0) {
        let usdcNetworkObjects = usdcNetworks.map(network => ({name: network, network: network.split('_')[1]}));
        tokens["USDC"] = {name: "USDC", networks: usdcNetworkObjects};
      }

      if (tokens["BTC"]) {
        orderedTokens.push(tokens["BTC"]);
        delete tokens["BTC"];
      }
      if (tokens["ETH"]) {
        orderedTokens.push(tokens["ETH"]);
        delete tokens["ETH"];
      }

      orderedTokens.push(...Object.values(tokens));

      return orderedTokens;
    },
    extractTokensArr(data) {
      let tokens = [];

      for (let category in data) {
        for (let token in data[category]) {
          if (!tokens.includes(token)) {
            tokens.push(token);
          }
        }
      }

      return tokens;
    },
    amountToFixed(val, token) {
      let numberToFixed = 0;

      if (token.toUpperCase() === 'BTC' || token.toUpperCase() === 'ETH') {
        numberToFixed = 4;
      } else if (token.toUpperCase() === 'LTC' || token.toUpperCase() === 'XMR' || token.toUpperCase() === 'TRX' || token.toUpperCase() === 'USDT' || token.toUpperCase() === 'USDT_ETH' || token.toUpperCase() === 'USDT_TRX' || token.toUpperCase() === 'USDC' || token.toUpperCase() === 'USDC_ETH' || token.toUpperCase() === 'USDC_TRX') {
        numberToFixed = 2;
      } else {
        numberToFixed = 4;
      }

      if (val !== undefined && val !== null) {
        const fixedVal = parseFloat(val).toFixed(numberToFixed);

        const formattedVal = fixedVal.replace(/(\.[0-9]*[1-9])0+$/, '$1');

        if (val >= 10000) {
          return parseFloat(formattedVal).toLocaleString('en-US', {
            minimumFractionDigits: 1,
            maximumFractionDigits: 5
          });
        } else {
          return formattedVal;
        }
      }
    },

    amountToFixedToken(val, token) {
      let numberToFixed = 4;

      if (token.toUpperCase() === 'BTC' || token.toUpperCase() === 'ETH') {
        numberToFixed = 4;
      } else if (['LTC', 'XMR', 'TRX', 'USDT', 'USDT_ETH', 'USDT_TRX', 'USDC', 'USDC_ETH', 'USDC_TRX'].includes(token.toUpperCase())) {
        numberToFixed = 2;
      }

      if (val > 0) {
        let fixedVal = val.toFixed(numberToFixed);

        let parts = fixedVal.split('.');
        if (parseFloat(parts[0].replace(/,/g, '')) >= 10000) {
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        return parts.join('.').replace(/\.?0+$/, '');
      } else {
        return '0';
      }
    },

    amountToFixedUsdt(val) {
      if (val !== undefined && val !== null && !isNaN(val)) {
        val = parseFloat(val);
        const fixedVal = val.toFixed(2).replace(/\.?0+$/, '');

        if (val >= 10000) {
          return parseFloat(fixedVal).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }).replace(/\.00$/, '');
        } else {
          return fixedVal;
        }
      }
    },
    availablePercentage(available, blocked) {
      const total = available + blocked

      return (available / total) * 100;
    },
    lockedPercentage(available, blocked) {
      const total = available + blocked

      return (blocked / total) * 100
    },
    filteredItemData(token, category) {
      const categoryData = this.chartDataMounted[category];

      if (categoryData && categoryData[token] && categoryData[token] && token) {
        return categoryData[token]
      } else {
        return 0;
      }
    },
    filteredData(token, category, fieldKey) {
      const categoryData = this.chartDataMounted[category];

      if (categoryData && categoryData[token] && categoryData[token]) {
        const tokenData = categoryData[token];
        return tokenData.hasOwnProperty(fieldKey) ? tokenData[fieldKey] : "";
      }

      return "";
    },
    calculateTotalBalance(tokensData, category) {
      if (tokensData === null || tokensData === undefined) {
        return 0;
      }
      let totalBalance = 0;

      tokensData.forEach(token => {
        if (this.filteredItemData(token, category).balanceUsdt) {
          totalBalance += this.filteredItemData(token, category).balanceUsdt;
        }
      });

      return totalBalance;
    },
    calculateNodeExchangeBalance(tokensData) {
      if (tokensData === null || tokensData === undefined) {
        return 0;
      }
      let totalBalance = 0;
      tokensData.forEach(token => {
        totalBalance += this.filteredItemData(token, 'node').balanceUsdt;
        totalBalance += this.filteredItemData(token, 'exchange').balanceUsdt;
      });

      return totalBalance;
    },
    nodeBalanceAvailable(token) {
      if (token === 'USDT_ETH' || token === 'USDT_TRX') {
        return this.chartDataMounted.node.USDT_ETH.balanceUsdt + this.chartDataMounted.node.USDT_TRX.balanceUsdt
      } else if (token === 'USDC_ETH' || token === 'USDC_TRX') {
        return this.chartDataMounted.node.USDC_ETH.balanceUsdt + this.chartDataMounted.node.USDC_TRX.balanceUsdt
      } else if (token === 'USDT' || token === 'USDC') {
        return 0
      } else {
        if (!this.chartDataMounted.node[token]) {
          return null
        }
        return this.chartDataMounted.node[token].balanceUsdt;
      }
    },
    exchangeBalanceAvailable(token) {
      if (token === 'USDT_ETH' || token === 'USDT_TRX' || token === 'USDC_ETH' || token === 'USDC_TRX') {
        return 0;
      } else {
        return this.chartDataMounted.exchange[token].balanceUsdt;
      }
    },
    totalBalanceAvailable(token) {
      return this.nodeBalanceAvailable(token) + this.exchangeBalanceAvailable(token);
    },
    nodePercentage(token) {
      return Math.round((this.nodeBalanceAvailable(token) / this.totalBalanceAvailable(token)) * 100);
    },
    exchangePercentage(token) {
      return 100 - this.nodePercentage(token);
    }
  }
}
</script>

<template>
  <AccountLayout>
    <template #title>
      <div class="layout-header">
        <h2>Balance</h2>
      </div>
    </template>
    <template
      #content
    >
      <div class="content_balance">
        <loading
          v-model:active="isLoading"
          :can-cancel="true"
          :is-full-page="false"
          color="#0A68F7"
        />
        <no-data-block v-if="!chartDataMounted && !isLoading" />

        <transition
          name="committee"
          appear
        >
          <div
            v-if="chartDataMounted"
            class="user-balances main-block balance-total"
          >
            <div
              ref="timer"
              class="table-block-header-filters-timer"
            >
              <div class="table-block-header-filters-timer_block">
                <div
                  class="icon"
                  @click="timerRefresh()"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_4068_2960)">
                      <path
                        d="M9.16667 6.66667V10.8333L12.7083 12.9333L13.35 11.8667L10.4167 10.125V6.66667H9.16667ZM17.5 8.33333V2.5L15.3 4.7C13.95 3.34167 12.075 2.5 10 2.5C5.85833 2.5 2.5 5.85833 2.5 10C2.5 14.1417 5.85833 17.5 10 17.5C14.1417 17.5 17.5 14.1417 17.5 10H15.8333C15.8333 13.2167 13.2167 15.8333 10 15.8333C6.78333 15.8333 4.16667 13.2167 4.16667 10C4.16667 6.78333 6.78333 4.16667 10 4.16667C11.6083 4.16667 13.0667 4.825 14.125 5.875L11.6667 8.33333H17.5Z"
                        fill="#0A68F7"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4068_2960">
                        <rect
                          width="20"
                          height="20"
                          fill="white"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div
                  class="minutes"
                  @click="timerHandler()"
                >
                  {{ selectedTimer.name }}
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.40372 4.19995H8.59628C8.95551 4.19995 9.13512 4.65889 8.88084 4.92785L6.28556 7.67508C6.12815 7.84158 5.87185 7.84158 5.71444 7.67508L3.11916 4.92785C2.86488 4.65889 3.0445 4.19995 3.40372 4.19995Z"
                      fill="#9FA2B4"
                    />
                  </svg>
                </div>
              </div>
              <div class="timer-progress">
                <LvProgressBar
                  v-if="selectedTimer.interval"
                  :value="timerValue"
                  :show-value="false"
                  color="#C7C9DA"
                />
              </div>
              <div
                v-if="timerHandle"
                class="dropdown"
              >
                <div
                  v-for="(item, index) in dropdownItems"
                  :key="index"
                  class="dropdown-item"
                  @click="selectTimerValue(item)"
                >
                  {{ item.name }}

                  <svg
                    v-if="selectedTimer.name === item.name"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_4068_2939)">
                      <path
                        d="M7.49896 13.5L4.58229 10.5833C4.25729 10.2583 3.74063 10.2583 3.41563 10.5833C3.09062 10.9083 3.09062 11.425 3.41563 11.75L6.90729 15.2416C7.23229 15.5666 7.75729 15.5666 8.08229 15.2416L16.9156 6.41664C17.2406 6.09164 17.2406 5.57498 16.9156 5.24998C16.5906 4.92498 16.074 4.92498 15.749 5.24998L7.49896 13.5Z"
                        fill="#0A68F7"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4068_2939">
                        <rect
                          width="20"
                          height="20"
                          fill="white"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
            </div>
            <div class="table-block">
              <div class="table-block-header">
                <div
                  v-if="!isMobileDevice"
                  class="table-block-header-title"
                >
                  Market/Node balances
                </div>
                <div
                  v-else
                  class="table-block-header-title"
                >
                  Mrkt/Node balances
                </div>
              </div>
              <div
                v-if="!isMobileDevice"
                class="table-block_table"
              >
                <div class="table-block_table--row table-block_table--row_headers">
                  <div class="table-block_table--row_headers--item">
                    Coin
                  </div>
                  <div class="table-block_table--row_headers--item bars">
                    Node
                  </div>
                  <div class="table-block_table--row_headers--item percent-bar">
                    Node/Market
                  </div>
                  <div class="table-block_table--row_headers--item bars">
                    Market
                  </div>
                </div>
                <div
                  v-for="(token, index) in tokensData"
                  :key="index"
                  class="table-block_table--row"
                  :class="{'table-block_table--row_usdt': token.name === 'USDT' || token.name === 'USDC'}"
                >
                  <div class="table-block_table--row_block">
                    <div
                      v-if="!token.networks"
                      class="table-block_table--row--item table-block_table--row--item_coin"
                    >
                      <CoinsSvg
                        :coin="token.name"
                        :coins-list="originCoins"
                      />
                      <div class="info">
                        <div class="name">
                          {{ token.name }}
                        </div>
                        <div class="full-name">
                          {{ filteredData(token.name, 'node', 'network') }}
                        </div>
                      </div>
                    </div>
                    <div
                      v-else
                      class="table-block_table--row--item table-block_table--row--item_full-height"
                    >
                      <div
                        v-for="(network, indx) in token.networks"
                        :key="indx"
                        class="table-block_table--row--item table-block_table--row--item_coin"
                      >
                        <CoinsSvg
                          v-if="indx === 0"
                          :coin="token.name"
                          :coins-list="originCoins"
                        />
                        <div
                          v-else
                          class="icon"
                        />
                        <div class="info">
                          <div class="name">
                            {{ token.name }}

                            <div
                              class="name-network"
                              :class="{
                                'name-network_trx': network.network === 'TRX',
                                'name-network_eth': network.network === 'ETH',
                              }"
                            >
                              {{ network.network }}
                            </div>
                          </div>
                          <div class="full-name">
                            Tether
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      v-if="(filteredData(token.name, 'node', 'balanceLocked') !== 0 || filteredData(token.name, 'node', 'balanceAvailable') !== 0) && (token.name !== 'USDT' && token.name !== 'USDC') "
                      class="table-block_table--row--item bars bars-cell"
                    >
                      <div class="bars-cell--row bars-cell--available">
                        <div class="left">
                          Available
                        </div>
                        <div
                          class="right"
                          :class="{'right-red': filteredData(token.name, 'node', 'balanceLocked') > 0}"
                        >
                          Blocked
                        </div>
                      </div>
                      <div class="bars-cell--value bars-cell--row ">
                        <div class="left">
                          {{ amountToFixedToken(filteredData(token.name, 'node', 'balanceAvailable'), token.name) }}
                          <span>{{
                            token.name
                          }}</span>
                        </div>
                        <div
                          class="right"
                          :class="{'right-black': filteredData(token.name, 'node', 'balanceLocked') > 0}"
                        >
                          {{ amountToFixedToken(filteredData(token.name, 'node', 'balanceLocked'), token.name) }} <span>{{
                            token.name
                          }}</span>
                        </div>
                      </div>


                      <div class="bars-cell--bar bars-cell--row ">
                        <div class="container">
                          <div
                            class="part1"
                            :style="{
                              width: `${availablePercentage(
                                filteredData(token.name, 'node', 'balanceAvailable'),
                                filteredData(token.name, 'node', 'balanceLocked')
                              )}%`,
                              backgroundColor: '#00B18B' }"
                          />
                          <div
                            class="part2"
                            :style="{
                              width: `${lockedPercentage(
                                filteredData(token.name, 'node', 'balanceAvailable'),
                                filteredData(token.name, 'node', 'balanceLocked')
                              )}%`,
                              backgroundColor: '#FF603D' }"
                          />
                          <div
                            class="part3"
                            :style="{
                              width: `0%`,
                              backgroundColor: 'gray' }"
                          />
                        </div>
                      </div>

                      <div class="bars-cell--head bars-cell--row ">
                        <div class="left">
                          Total node {{
                            amountToFixedUsdt(filteredData(token.name, 'node', 'balanceUsdt'), token.name)
                          }} $
                        </div>
                        <div class="right">
                          {{
                            amountToFixed(filteredData(token.name, 'node', 'balanceAvailable') + filteredData(token.name, 'node', 'balanceLocked'), token.name)
                          }}
                          {{ token.name }}
                        </div>
                      </div>
                    </div>

                    <div
                      v-if="(filteredData(token.name, 'node', 'balanceLocked') !== 0 || filteredData(token.name, 'node', 'balanceAvailable') !== 0) && (token.name === 'USDT' || token.name === 'USDC')"
                      class="table-block_table--row--item bars-cell_block"
                    >
                      <div
                        v-for="(network, idx) in token.networks"
                        :key="idx"
                        class="table-block_table--row--item bars bars-cell"
                      >
                        <div class="bars-cell--row bars-cell--available">
                          <div class="left">
                            Available
                          </div>
                          <div
                            class="right"
                            :class="{'right-red': filteredData(network.name, 'node', 'balanceLocked') > 0}"
                          >
                            Blocked
                          </div>
                        </div>
                        <div class="bars-cell--value bars-cell--row ">
                          <div class="left">
                            {{
                              amountToFixedToken(filteredData(network.name, 'node', 'balanceAvailable'), network.name)
                            }}
                            <span>{{
                              token.name
                            }}</span>
                          </div>
                          <div
                            class="right"
                            :class="{'right-black': filteredData(network.name, 'node', 'balanceLocked') > 0}"
                          >
                            {{ amountToFixedToken(filteredData(network.name, 'node', 'balanceLocked'), network.name) }}
                            <span>{{
                              token.name
                            }}</span>
                          </div>
                        </div>


                        <div class="bars-cell--bar bars-cell--row ">
                          <div class="container">
                            <div
                              class="part1"
                              :style="{
                                width: `${availablePercentage(
                                  filteredData(network.name, 'node', 'balanceAvailable'),
                                  filteredData(network.name, 'node', 'balanceLocked')
                                )}%`,
                                backgroundColor: '#00B18B' }"
                            />
                            <div
                              class="part2"
                              :style="{
                                width: `${lockedPercentage(
                                  filteredData(network.name, 'node', 'balanceAvailable'),
                                  filteredData(network.name, 'node', 'balanceLocked')
                                )}%`,
                                backgroundColor: '#FF603D' }"
                            />
                            <div
                              class="part3"
                              :style="{
                                width: `0%`,
                                backgroundColor: 'gray' }"
                            />
                          </div>
                        </div>

                        <div class="bars-cell--head bars-cell--row ">
                          <div class="left">
                            Total node {{
                              amountToFixedUsdt(filteredData(network.name, 'node', 'balanceUsdt'), network.name)
                            }} $
                          </div>
                          <div class="right">
                            {{
                              amountToFixed(filteredData(network.name, 'node', 'balanceAvailable') + filteredData(network.name, 'node', 'balanceLocked'), network.name)
                            }}
                            {{ token.name }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--Node/Market col-->
                  <PercentageBars
                    v-if="token.name !== 'USDT' && token.name !== 'USDC' "
                    :token="token.name"
                    :chart-data-mounted="chartDataMounted"
                  />


                  <PercentageBarsNetworks
                    v-else
                    :chart-data-mounted="chartDataMounted"
                    :token="token"
                  />

                  <div
                    v-if="filteredData(token.name, 'exchange', 'balanceLocked') !== 0 || filteredData(token.name, 'exchange', 'balanceAvailable') !== 0"
                    class="table-block_table--row--item bars bars-cell"
                  >
                    <div class="bars-cell--row bars-cell--available">
                      <div class="left">
                        Available
                      </div>
                      <div
                        class="right"
                        :class="{'right-red': filteredData(token.name, 'exchange', 'balanceLocked') > 0}"
                      >
                        Blocked
                      </div>
                    </div>
                    <div class="bars-cell--value bars-cell--row ">
                      <div class="left">
                        {{ amountToFixedToken(filteredData(token.name, 'exchange', 'balanceAvailable'), token.name) }}
                        <span>{{ token.name }}</span>
                      </div>
                      <div
                        class="right"
                        :class="{'right-black': filteredData(token.name, 'exchange', 'balanceLocked') > 0}"
                      >
                        {{ amountToFixedToken(filteredData(token.name, 'exchange', 'balanceLocked'), token.name) }}
                        <span>{{
                          token.name
                        }}</span>
                      </div>
                    </div>


                    <div class="bars-cell--bar bars-cell--row ">
                      <div class="container">
                        <div
                          class="part1"
                          :style="{
                            width: `${availablePercentage(
                              filteredData(token.name, 'exchange', 'balanceAvailable'),
                              filteredData(token.name, 'exchange', 'balanceLocked')
                            )}%`,
                            backgroundColor: '#00B18B' }"
                        />
                        <div
                          class="part2"
                          :style="{
                            width: `${lockedPercentage(
                              filteredData(token.name, 'exchange', 'balanceAvailable'),
                              filteredData(token.name, 'exchange', 'balanceLocked')
                            )}%`,
                            backgroundColor: '#FF603D' }"
                        />
                        <div
                          class="part3"
                          :style="{
                            width: `0%`,
                            backgroundColor: 'gray' }"
                        />
                      </div>
                    </div>


                    <div class="bars-cell--head bars-cell--row ">
                      <div class="left">
                        Total market {{
                          amountToFixedUsdt(filteredData(token.name, 'exchange', 'balanceUsdt'), token.name)
                        }} $
                      </div>
                      <div class="right">
                        {{
                          amountToFixed(filteredData(token.name, 'exchange', 'balanceAvailable') + filteredData(token.name, 'exchange', 'balanceLocked'), token.name)
                        }} {{ token.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                v-if="isMobileDevice"
                class="table-block_table--mobile"
              >
                <div
                  v-for="(token, index) in tokensData"
                  :key="index"
                >
                  <div
                    v-if="token.name !== 'USDT' && token.name !== 'USDC'"
                    class="table-block_table--row"
                  >
                    <div class="table-block_table--row_block table-block_table--row_block-left">
                      <div
                        v-if="!token.networks"
                        class="table-block_table--row--item table-block_table--row--item_coin table-block_table--row--item_coin-left"
                        :class="{
                          'table-block_table--row--item_coin-red': nodePercentage(token.name) >= 0 && nodePercentage(token.name) <= 20,
                          'table-block_table--row--item_coin-green': nodePercentage(token.name) >= 21 && nodePercentage(token.name) <= 80,
                          'table-block_table--row--item_coin-gray': nodePercentage(token.name) >= 81 && nodePercentage(token.name) <= 100

                        }"
                      >
                        <div class="table-block_table--row--item_coin-block">
                          <CoinsSvg
                            :coin="token.name"
                            :coins-list="originCoins"
                          />
                          <div class="info">
                            <div class="name">
                              {{ token.name }}
                            </div>
                            <div class="full-name">
                              {{ filteredData(token.name, 'node', 'network') }}
                            </div>
                          </div>
                        </div>
                        <div class="table-block_table--row--item_coin-block_percentage">
                          {{ nodePercentage(token.name) }}%
                        </div>
                      </div>

                      <div class="table-block_table--row--item bars bars-cell">
                        <div class="bars-cell--bar bars-cell--row ">
                          <div class="container">
                            <div
                              class="part1"
                              :style="{
                                width: `${availablePercentage(
                                  filteredData(token.name, 'node', 'balanceAvailable'),
                                  filteredData(token.name, 'node', 'balanceLocked')
                                )}%`,
                                backgroundColor: '#00B18B' }"
                            />
                            <div
                              class="part2"
                              :style="{
                                width: `${lockedPercentage(
                                  filteredData(token.name, 'node', 'balanceAvailable'),
                                  filteredData(token.name, 'node', 'balanceLocked')
                                )}%`,
                                backgroundColor: '#FF603D' }"
                            />
                            <div
                              class="part3"
                              :style="{
                                width: `0%`,
                                backgroundColor: 'gray' }"
                            />
                          </div>
                        </div>

                        <div class="bars-cell--value bars-cell--row ">
                          <div class="bars-cell--item">
                            <span>Available</span>
                            {{ amountToFixedToken(filteredData(token.name, 'node', 'balanceAvailable'), token.name) }}
                            {{ token.name }}
                          </div>

                          <div class="bars-cell--item">
                            <span>Blocked</span>
                            {{ amountToFixedToken(filteredData(token.name, 'node', 'balanceLocked'), token.name) }} {{
                              token.name
                            }}
                          </div>
                          <div class="bars-cell--item bars-cell--item_total">
                            <span>Total node</span>
                            <div>
                              {{
                                amountToFixed(filteredData(token.name, 'node', 'balanceAvailable') + filteredData(token.name, 'node', 'balanceLocked'), token.name)
                              }}
                              {{ token.name }}
                            </div>
                            <div>
                              {{
                                amountToFixedUsdt(filteredData(token.name, 'node', 'balanceUsdt'), token.name)
                              }} $
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="table-block_table--row_block table-block_table--row_block-right">
                      <div
                        class="table-block_table--row--item table-block_table--row--item_coin table-block_table--row--item_coin-right"
                        :class="{
                          'table-block_table--row--item_coin-red': exchangePercentage(token.name) >= 0 && exchangePercentage(token.name) <= 20,
                          'table-block_table--row--item_coin-green': exchangePercentage(token.name) >= 21 && exchangePercentage(token.name) <= 80,
                          'table-block_table--row--item_coin-gray': exchangePercentage(token.name) >= 81 && exchangePercentage(token.name) <= 100

                        }"
                      >
                        <div class="table-block_table--row--item_coin-block">
                          <CoinsSvg
                            :coin="token.name"
                            :coins-list="originCoins"
                          />
                          <div class="info">
                            <div class="name">
                              {{ token.name }}
                            </div>
                            <div class="full-name">
                              {{ filteredData(token.name, 'node', 'network') }}
                            </div>
                          </div>
                        </div>

                        <div class="table-block_table--row--item_coin-block_percentage">
                          {{ exchangePercentage(token.name) }}%
                        </div>
                      </div>

                      <div class="table-block_table--row--item bars bars-cell">
                        <div class="bars-cell--bar bars-cell--row ">
                          <div class="container">
                            <div
                              class="part1"
                              :style="{
                                width: `${availablePercentage(
                                  filteredData(token.name, 'exchange', 'balanceAvailable'),
                                  filteredData(token.name, 'exchange', 'balanceLocked')
                                )}%`,
                                backgroundColor: '#00B18B' }"
                            />
                            <div
                              class="part2"
                              :style="{
                                width: `${lockedPercentage(
                                  filteredData(token.name, 'exchange', 'balanceAvailable'),
                                  filteredData(token.name, 'exchange', 'balanceLocked')
                                )}%`,
                                backgroundColor: '#FF603D' }"
                            />
                            <div
                              class="part3"
                              :style="{
                                width: `0%`,
                                backgroundColor: 'gray' }"
                            />
                          </div>
                        </div>
                        <div class="bars-cell--value bars-cell--row ">
                          <div class="bars-cell--item">
                            <span>Available</span>
                            {{ amountToFixedToken(filteredData(token.name, 'exchange', 'balanceAvailable'), token.name) }}
                            {{ token.name }}
                          </div>

                          <div class="bars-cell--item">
                            <span>Blocked</span>
                            {{ amountToFixedToken(filteredData(token.name, 'exchange', 'balanceLocked'), token.name) }} {{
                              token.name
                            }}
                          </div>
                          <div class="bars-cell--item">
                            <span>Total market</span>
                            {{
                              amountToFixed(filteredData(token.name, 'exchange', 'balanceAvailable') + filteredData(token.name, 'exchange', 'balanceLocked'), token.name)
                            }} {{ token.name }} <br>
                            {{
                              amountToFixedUsdt(filteredData(token.name, 'exchange', 'balanceUsdt'), token.name)
                            }} $
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <BalanceMirrorUsdt
                    v-else
                    :token="token"
                    :chart-data-mounted="chartDataMounted"
                    :coins-list="originCoins"
                  />
                </div>
              </div>
            </div>
          </div>
        </transition>

        <transition
          name="committee"
          appear
        >
          <perfect-scrollbar
            class="table_node-balances main-block"
          >
            <div class="table_node-balances__block">
              <div class="table_node-balances__block-table">
                <div class="title">
                  Node ratio
                </div>
                <div class="table_node-balances__block-table_list">
                  <div
                    v-for="(token, index) in getSortedByTokenName(sortedNodeTokensData)"
                    :key="'node-' + index"
                  >
                    <div
                      class="list--item"
                      :class="{
                        'list--item_first': token.balanceUsdt === maxBalanceUsdt(sortedNodeTokensData),
                        'list--item_last': token.balanceUsdt === minBalanceUsdt(sortedNodeTokensData)
                      }"
                    >
                      <div class="list--item_info">
                        <div class="icon">
                          <CoinsSvg
                            :coin="token.token"
                            :coins-list="originCoins"
                          />
                          <div
                            v-if="token.token === 'USDT' && (token.network === 'TRX' || token.network === 'ETH')"
                            class="icon-network"
                            :class="{
                              'icon-network_trx': token.network === 'TRX',
                              'icon-network_eth': token.network === 'ETH',
                            }"
                          >
                            {{ token.network }}
                          </div>
                          <div
                            v-if="token.token === 'USDC' && (token.network === 'TRX' || token.network === 'ETH')"
                            class="icon-network"
                            :class="{
                              'icon-network_trx': token.network === 'TRX',
                              'icon-network_eth': token.network === 'ETH',
                            }"
                          >
                            {{ token.network }}
                          </div>
                        </div>
                        <BalanceMirrorItemPercentage
                          v-if="token.balanceUsdt === maxBalanceUsdt(sortedNodeTokensData)"
                          :total="calculateTotalBalance(tokensDataArr, 'node')"
                          :usdt="token.balanceUsdt"
                        />
                        <BalanceMirrorItemPercentage
                          v-if="token.balanceUsdt !== maxBalanceUsdt(sortedNodeTokensData)"
                          :total-first="getHighestBalanceToken(sortedNodeTokensData).balanceUsdt"
                          :total="calculateTotalBalance(tokensDataArr, 'node')"
                          :usdt="token.balanceUsdt"
                        />
                      </div>
                      <div
                        v-if="token"
                        class="balance"
                      >
                        <div class="balance-token">
                          {{ amountToFixedToken(token.balanceAvailable + token.balanceLocked, token.token) }} {{
                            token.token
                          }}
                        </div>
                        <div class="balance-usdt">
                          {{ amountToFixedUsdt(token.balanceUsdt) }} <span>$</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="list--item_empty"
                  />
                  <div class="total">
                    <div class="total-text">
                      Total balance node
                    </div>
                    <div class="total-balance">
                      {{ amountToFixedUsdt(calculateTotalBalance(tokensDataArr, 'node')) }} <span>$</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="table_node-balances__block-table">
                <div class="title">
                  Market ratio
                </div>
                <div class="table_node-balances__block-table_list">
                  <div
                    v-for="(token, index) in getSortedByTokenName(filteredExchangeTokens)"
                    :key="'exchange-' + index"
                    class="list--item"
                    :class="{
                      'list--item_first': token.balanceUsdt === maxBalanceUsdt(filteredExchangeTokens),
                      'list--item_last': token.balanceUsdt === minBalanceUsdt(filteredExchangeTokens),
                      'list--item_double': token.token === 'USDC' || token.token === 'USDT'
                    }"
                  >
                    <div class="list--item_info">
                      <div class="icon">
                        <CoinsSvg
                          :coin="token.token"
                          :coins-list="originCoins"
                        />
                      </div>
                      <BalanceMirrorItemPercentage
                        v-if="token.balanceUsdt === maxBalanceUsdt(filteredExchangeTokens)"
                        :total="calculateTotalBalance(tokensDataArr, 'exchange')"
                        :usdt="token.balanceUsdt"
                      />
                      <BalanceMirrorItemPercentage
                        v-else
                        :total-first="getHighestBalanceToken(filteredExchangeTokens).balanceUsdt"
                        :total="calculateTotalBalance(tokensDataArr, 'exchange')"
                        :usdt="token.balanceUsdt"
                      />
                    </div>
                    <div
                      v-if="token && token.balanceUsdt"
                      class="balance"
                    >
                      <div class="balance-token">
                        {{ amountToFixedToken(token.balanceAvailable + token.balanceLocked, token.token) }} {{
                          token.token
                        }}
                      </div>
                      <div class="balance-usdt">
                        {{ amountToFixedUsdt(token.balanceUsdt) }} <span>$</span>
                      </div>
                    </div>
                  </div>
                  <div
                    class="list--item_empty"
                  />

                  <div class="total">
                    <div class="total-text">
                      Total balance market
                    </div>
                    <div class="total-balance">
                      {{ amountToFixedUsdt(calculateTotalBalance(tokensDataArr, 'exchange')) }} <span>$</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="table_node-balances__block-table table_node-balances__block-table_total">
                <div class="title">
                  Total ratio
                </div>
                <div class="table_node-balances__block-table_list">
                  <div
                    v-for="(token, index) in getSortedByTokenName(filteredTotalTokens)"
                    :key="'node-' + index"
                    class="list--item"
                    :class="{
                      'list--item_first': index === 0,
                      'list--item_last': index === filteredTotalTokens.length - 1,
                      'list--item_double': token.token === 'USDC' || token.token === 'USDT'
                    }"
                  >
                    <div class="list--item_info">
                      <div class="icon">
                        <CoinsSvg
                          :coin="token.token"
                          :coins-list="originCoins"
                        />
                      </div>
                      <BalanceMirrorItemPercentage
                        v-if="index === 0"
                        :total="totalBalance"
                        :usdt="token.balanceUsdt"
                      />
                      <BalanceMirrorItemPercentage
                        v-else
                        :total-first="filteredTotalTokens[0].balanceUsdt"
                        :total="totalBalance"
                        :usdt="token.balanceUsdt"
                      />
                    </div>
                    <div class="balance">
                      <div class="balance-token">
                        {{ amountToFixedToken(token.balanceAvailable, token.token) }} {{ token.token }}
                      </div>
                      <div class="balance-usdt">
                        {{ amountToFixedUsdt(token.balanceUsdt) }} <span>$</span>
                      </div>
                    </div>
                  </div>
                  <div
                    class="list--item_empty"
                  />
                  <div class="total">
                    <div class="total-text">
                      Total balance
                    </div>
                    <div class="total-balance">
                      {{
                        amountToFixedUsdt(calculateTotalBalance(tokensDataArr, 'exchange') + calculateTotalBalance(tokensDataArr, 'node'))
                      }} <span>$</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </perfect-scrollbar>
        </transition>

        <transition
          name="committee"
          appear
          :style="{ transitionDuration: '0.3s'}"
        >
          <balance-mirror-withdraw v-if="mirrorWithdrawData" />
        </transition>

        <transition
          name="committee"
          appear
        >
          <BalanceMirrorAddress
            v-if="chartDataNodas"
            :data="chartDataNodas.node"
            :coins="originCoins"
          />
        </transition>
      </div>
      <transition name="committee">
        <CopyNotification
          v-if="isUpdate"
          :text="updateText"
        />
      </transition>
    </template>
  </AccountLayout>
</template>

<style lang="scss">
.content_balance {
  position: relative;
}

.user-balances {
  max-width: 1124px;
  margin-top: 32px;
  margin-bottom: 16px;

  @media (max-width: 1079px) {
    border: none;
    background: none;
    margin-top: 16px;
  }

  .table-block-header-title {
    cursor: default;
  }

  .table-block-header-filters-timer {
    position: absolute;
    right: 24px;
    top: 24px;

    @media (max-width: 1079px) {
      background: white;
      right: 0;
    }

    .lv-progressbar {
      max-width: 91px;
      margin: 0 auto;
      background: #F0F1F7;
      height: 2px;
    }

    &_block {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .icon {
      width: 38px;
      height: 38px;
      display: grid;
      place-items: center;
      border: 1px solid #F0F1F7;
      border-radius: 8px 0px 0px 8px;
      cursor: pointer;
      transition: all .3s ease-in-out;

      &:hover {
        border: 1px solid #0A68F7;
      }
    }

    .minutes {
      width: 66px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 4px;
      color: #0A68F7;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      border: 1px solid #F0F1F7;
      border-radius: 0px 8px 8px 0px;
      cursor: pointer;
      transition: all .3s ease-in-out;

      &:hover {
        border: 1px solid #0A68F7;
      }
    }

    .dropdown {
      position: absolute;
      background: white;
      top: 96%;
      left: -10px;
      width: 127px;
      z-index: 999;
      border-radius: 12px;
      padding: 8px 0;
      box-shadow: 0px 4px 24px 0px rgba(15, 0, 58, 0.12);

      &-item {
        padding: 8px 16px;
        color: #1B1A1C;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        cursor: pointer;
        transition: all .3s ease-in-out;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        &:hover {
          background: rgba(27, 26, 28, 0.03);
        }

        .marked {
          width: 20px;
          height: 20px;
        }
      }
    }

    .timer-progress {
      position: absolute;
      top: calc(100% + 4px);
      width: 100%;
    }
  }
}

.balance-total {
  .table-block_table {
    width: 100%;

    &--row {
      width: 100%;
      padding: 12.5px 31px;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 32px;

      &_block {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 504px;
      }

      &:not(:first-child) {
        border-top: 1px solid #DFE0EB;
        @media (max-width: 1079px) {
          border-top: none;
        }
      }

      &_headers {
        padding-bottom: 12px;
        padding-top: 0;

        &--item {
          color: #9FA2B4;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: 0.2px;

          &:first-child {
            max-width: 110px;
            width: 100%;
          }
        }
      }

      &--item {
        &:first-child {
          max-width: 108px;
          width: 100%;
        }

        &_full-height {
          display: flex;
          flex-direction: column;
          width: 100%;
          gap: 12px;


          .table-block_table--row--item_coin {
            height: 78px;
          }
        }

        &_coin {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;

          .icon {
            display: flex;
            width: 32px;
            height: auto;

            svg {
              width: 32px;
              height: 32px;
            }
          }

          .info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            .name {
              color: var(--black, #1B1A1C);
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 20px; /* 142.857% */
              letter-spacing: 0.2px;
              position: relative;

              &-network {
                position: absolute;
                left: calc(100% + 4px);
                top: 3px;
                color: #FFF;
                text-align: center;
                font-family: 'Inter', sans-serif;
                font-size: 10px;
                font-style: normal;
                font-weight: 700;
                line-height: 14px;
                border-radius: 3px;
                padding: 0 2px;
                box-sizing: border-box;
                height: 14px;
                width: 26px;
                display: flex;
                align-items: center;
                justify-content: center;

                &_trx {
                  background: #0A68F7;
                }

                &_eth {
                  background: #FB0000;
                }
              }
            }

            .full-name {
              color: #1B1A1C;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 142.857% */
              letter-spacing: 0.2px;
            }
          }

          &-green {
            color: #00B18B;
            background: rgba(0, 177, 139, 0.12);
          }

          &-red {
            color: #FF603D;
            background: rgba(255, 96, 61, 0.12);
          }

          &-gray {
            color: #9FA2B4;
            background: rgba(159, 162, 180, 0.12);
          }

        }
      }

      .bars {
        max-width: 360px;
        width: 100%;
        text-align: center;

        &-cell {
          display: flex;
          flex-direction: column;
          width: 100%;

          &_block {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 12px;
            max-width: 360px;
          }

          &--row {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .container {
              width: 100%;
              height: 6px;
              display: flex;
              border-radius: 3px;
              overflow: hidden;
            }

            .part1, .part2, .part3 {
              height: 100%;
            }

            .part1 {
              background-color: #00B18B;
            }

            .part2 {
              background-color: #FF603D;
            }

            .part3 {
              background-color: gray;
            }
          }

          &--value {
            color: #1B1A1C;
            text-align: right;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.2px;

            span {
              color: #9FA2B4;
            }

            .right {
              color: #9FA2B4;

              &-black {
                color: #1B1A1C;
              }
            }
          }

          &--bar {
            margin: 6px 0;

            @media (max-width: 1079px) {
              margin: 8px 0;
            }
          }

          &--head {
            color: #9FA2B4;
            text-align: right;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
            letter-spacing: 0.2px;
          }

          &--available {

            .left {
              color: #00B18B;
              text-align: right;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 142.857% */
              letter-spacing: 0.2px;
            }

            .right {
              color: #9FA2B4;
              text-align: right;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 142.857% */
              letter-spacing: 0.2px;

              &-red {
                color: #FF603D;
              }

              &-black {
                color: #1B1A1C;
              }
            }
          }

          &__chart {
            align-items: center;
          }
        }
      }

      .percent-bar {
        max-width: 130px;
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-self: flex-end;
        transform: translateY(-16px);
      }

      &_headers--item {
        transform: translateY(0) !important;
      }

      &_chart {
        padding-top: 0;

        .highcharts-series-group, .highcharts-title, .highcharts-legend .highcharts-no-tooltip {
          transform: translateY(-20px);
        }
      }

      &_usdt {
        align-items: flex-start;

        .percent-bar {
          transform: translateY(0);

          &__networks-block {
            height: 176px;
            justify-content: center;
          }
        }
      }
    }

    &--mobile {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .table-block_table {
        &--row {
          align-items: flex-start;
          position: relative;
          gap: 4px;
          padding: 8px 8px 16px 8px;
          background: white;
          border-radius: 16px;
          border: 1px solid #DFE0EB;

          .percent-bar {
            position: absolute;
            top: 0;
            width: 100%;
            left: 0;
            max-width: 100%;
            transform: none;

            &__item {
              width: 100%;
              height: 56px;
              justify-content: flex-end;
              align-items: center;
              padding: 0 8px;

              &:last-child {
                justify-content: flex-start;
              }
            }
          }

          &_block {
            flex-direction: column;
            align-items: flex-start;

            .table-block_table--row--item {
              .bars-cell {
                &--row {
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 8px;
                }

                &--item {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                }
              }
            }

            &:last-child {
              align-items: flex-end;


              .table-block_table--row--item {
                .bars-cell {
                  &--row {
                    align-items: flex-end;
                  }

                  &--item {
                    align-items: flex-end;
                  }
                }

                &_coin {
                  flex-direction: row-reverse;

                  &-block {
                    flex-direction: row-reverse;
                  }
                }
              }
            }
          }

          &--item {
            &_coin {
              max-width: 100%;
              padding: 10px 8px 10px 8px;
              height: 56px;
              box-sizing: border-box;
              align-items: flex-start;
              justify-content: space-between;

              @media (max-width: 1079px) {
                &-left {
                  border-radius: 8px 0 0 8px;
                }

                &-right {
                  border-radius: 0 8px 8px 0;
                }
              }

              &-block {
                max-width: 108px;
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 6px;

                &_percentage {
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 20px;
                  display: flex;
                  flex-direction: column;
                }
              }
            }

            .bars-cell--value {
              padding: 0 8px;
              box-sizing: border-box;
            }
          }

          &_usdt {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            border-radius: 16px;
            border: 1px solid #DFE0EB;
            overflow: hidden;

            .table-block_table--row {
              border-radius: 0;
              border: none;

              &__networks {
                border-top: 1px solid #DFE0EB;;
              }

              &__head {
                padding-bottom: 8px;
              }
            }
          }
        }
      }
    }
  }
}

.table_node-balances {
  max-width: 1122px;

  &:not(:last-child) {
    margin-bottom: 40px;
  }

  .table-block-header {
    cursor: default;
  }

  .table-headers {
    th {
      &:hover {
        .text {
          cursor: default;
          color: RGB(159, 162, 180) !important;
        }

      }

    }
  }

  &__block {
    width: 100%;
    display: flex;
    flex-direction: row;

    @media (max-width: 1079px) {
      width: 1044px;
    }

    &-table {
      max-width: 376px;
      width: 100%;
      padding: 32px 24px;
      box-sizing: border-box;

      &:not(:first-child) {
        border-left: 1px solid #DFE0EB;
      }

      &:first-child {
        padding-left: 32px;
      }

      &:last-child {
        padding-right: 32px;
      }

      .title {
        color: #1B1A1C;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 16px;
      }

      .total {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-top: 8px;

        &-text {
          color: #9FA2B4;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
        }

        &-balance {
          color: var(--black, #1B1A1C);
          text-align: right;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px; /* 142.857% */
          letter-spacing: 0.2px;

          span {
            color: #9FA2B4;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.2px;
          }
        }
      }

      &_list {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .icon {
          width: 32px;
          height: 32px;
        }

        .list--item {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          &_double {
            margin-bottom: 48px;
          }

          &_empty {
            height: 40px;
          }

          &_info {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            gap: 8px;
            align-items: center;

            .icon {
              position: relative;

              &-network {
                position: absolute;
                bottom: -4px;
                left: 0;
                right: 0;
                margin: 0 auto;
                color: #FFF;
                text-align: center;
                font-family: 'Inter', sans-serif;
                font-size: 10px;
                font-style: normal;
                font-weight: 700;
                line-height: 14px;
                border-radius: 3px;
                display: grid;
                place-items: center;
                width: 26px;
                height: 14px;

                &_trx {
                  background: #0A68F7;
                }

                &_eth {
                  background: #FB0000;
                }
              }

            }
          }

          &_first {
            .percent {
              &-bar {
                width: 100% !important;
                background: rgba(0, 177, 139, 0.12);
              }

              &-number {
                color: #00B18B !important;
              }
            }
          }

          &_last {
            .percent {
              &-bar {
                background: rgba(255, 96, 61, 0.12);
              }

              &-number {
                color: #FF603D;
              }
            }
          }

          .balance {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            &-usdt {
              color: var(--black, #1B1A1C);
              text-align: right;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 142.857% */
              letter-spacing: 0.2px;

              span {
                color: #9FA2B4;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0.2px;
              }
            }

            &-token {
              color: #9FA2B4;
              text-align: right;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 142.857% */
              letter-spacing: 0.2px;
            }
          }
        }
      }

      &_total {
        .table_node-balances__block-table_list {
          .list--item {
            .balance {
              &-usdt {
                font-weight: 700;
              }
            }
          }
        }
      }
    }
  }
}

.charts {
  display: flex;
  flex-direction: row;

  .chart-legend {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: 0.2px;
    color: #1B1A1C;
  }


}

.highcharts-point {
  transform: translateY(-2px);
}
</style>
