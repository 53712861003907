export const TABLE_TH = [
    {
        name: 'ID',
        sort: '',
        noSort: false,
        key: 'id',
        description: 'Trade round is the latest round of trade negotiations'
    },
    {
        name: 'Fav',
        sort: '',
        noSort: false,
        key: 'fav',
        description: 'Trade round is the latest round of trade negotiations'
    },
    {
        name: 'Email',
        sort: '',
        noSort: false,
        key: 'login',
        description: 'Trade round is the latest round of trade negotiations'
    },
    {
        name: 'Role',
        sort: '',
        noSort: false,
        key: 'role',
        description: 'Trade round is the latest round of trade negotiations'
    },
    {
        name: 'Active',
        sort: '',
        noSort: false,
        key: 'active',
        description: 'Trade round is the latest round of trade negotiations'
    },
    {
        name: 'Create user',
        sort: '',
        noSort: false,
        key: 'createDate',
        description: 'Trade round is the latest round of trade negotiations'
    },
    {
        name: 'Partner program',
        sort: '',
        noSort: true,
        key: 'program',
        description: 'Trade round is the latest round of trade negotiations'
    },
    {
        name: 'IP list',
        sort: '',
        noSort: true,
        key: 'ipList',
        description: 'Trade round is the latest round of trade negotiations'
    },
];

export const TABLE_TH_ACTIVITY = [
    {
        name: 'No',
        sort: 'none',
        key: 'index',
        description: ''
    },
    {
        name: 'Create date',
        sort: '',
        key: 'createDate',
        description: 'Trade round is the latest round of trade negotiations'
    },
    {
        name: 'User agent',
        sort: '',
        key: 'userAgent',
        description: 'Trade round is the latest round of trade negotiations'
    },
    {
        name: 'IP Address',
        sort: '',
        key: 'ipAddress',
        description: 'Trade round is the latest round of trade negotiations'
    },
]
