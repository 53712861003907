export const TABLE_TH = [
    {
        name: 'Id',
        sort: 'DESC',
        id: 'table-id',
        key: 'id',
        description: 'Trade round is the latest round of trade negotiations',
        isSortable: true,
    },
    {
        name: 'Uid',
        sort: 'DESC',
        key: 'uid',
        description: 'Trade round is the latest round of trade negotiations',
        isSortable: false,
    },
    {
        name: 'From',
        sort: 'DESC',
        key: 'from',
        description: 'Trade round is the latest round of trade negotiations',
        isSortable: false,
    },
    {
        name: 'To',
        sort: 'DESC',
        key: 'to',
        description: 'Trade round is the latest round of trade negotiations',
        isSortable: false,
    },
    {
        name: 'Mode',
        sort: 'DESC',
        key: 'mode',
        description: 'Trade round is the latest round of trade negotiations',
        isSortable: false,
    },
    {
        name: 'Amount',
        sort: 'DESC',
        key: 'amount',
        description: 'Trade round is the latest round of trade negotiations',
        isSortable: false,
    },
    {
        name: 'User Amount',
        sort: 'DESC',
        key: 'userAmount',
        description: 'Trade round is the latest round of trade negotiations',
        isSortable: false,
    },
    {
        name: 'IP',
        sort: 'DESC',
        key: 'ip',
        description: 'Trade round is the latest round of trade negotiations',
        isSortable: false,
    },
    {
        name: 'AML',
        sort: 'DESC',
        key: 'aml',
        description: 'Trade round is the latest round of trade negotiations',
        isSortable: false,
    },
    {
        name: 'Partner login',
        sort: 'DESC',
        key: 'partnerLogin',
        description: 'Trade round is the latest round of trade negotiations',
        isSortable: false,
    },
    {
        name: 'Date',
        sort: '',
        key: 'date',
        description: 'Trade round is the latest round of trade negotiations',
        isSortable: true,
    },
    {
        name: 'Status',
        sort: '',
        key: 'status',
        description: 'Trade round is the latest round of trade negotiations',
        isSortable: true,
    },
];

export const STATUSES = [
    {
        id: 'Waiting',
        name: 'Waiting',
        status: 10,
        cssClass: 'status-empty',
    },
    {
        id: 'Confirmation',
        name: 'Confirmation',
        status: 20,
        cssClass: 'status-pay',
    },
    {
        id: 'Payment',
        name: 'Payment',
        status: 30,
        cssClass: 'status-exchange',
    },
    {
        id: 'Exchanging',
        name: 'Exchanging',
        status: 40,
        cssClass: 'status-exchange',
    },
    {
        id: 'ExchangingInProgress',
        name: 'Exchanging in progress',
        status: 50,
        cssClass: 'status-exchange',
    },
    {
        id: 'ReadyToSend',
        name: 'Ready to send',
        status: 60,
        cssClass: 'status-withdrawal',
    },
    {
        id: 'Sending',
        name: 'Sending',
        status: 70,
        cssClass: 'status-withdrawal',
    },
    {
        id: 'Success',
        name: 'Success',
        status: 80,
        cssClass: 'status-completed',
    },
    {
        id: 'Overdue',
        name: 'Overdue',
        status: 110,
        cssClass: 'status-overdue',
    },
    {
        id: 'Refunded',
        name: 'Refunded',
        status: 100,
        cssClass: 'status-returned',
    },
    {
        id: 'Frozen',
        name: 'Frozen',
        status: 90,
        cssClass: 'status-hold',
    },
    {
        id: 'Suspended',
        name: 'Suspended',
        status: 120,
        cssClass: 'status-profit',
    },
];

export const ALLOW_STATUS = [1, 2, 3, 4, 5];
