<template>
  <AccountLayoutNew>
    <template #title>
      <div class="layout-header">
        <h2>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
          >
            <path
              d="M2 18C1.45 18 0.979167 17.8042 0.5875 17.4125C0.195833 17.0208 0 16.55 0 16V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H18C18.55 0 19.0208 0.195833 19.4125 0.5875C19.8042 0.979167 20 1.45 20 2V16C20 16.55 19.8042 17.0208 19.4125 17.4125C19.0208 17.8042 18.55 18 18 18H2ZM2 16H18V2H2V16ZM4 14H16V12H4V14ZM4 10H8V4H4V10ZM10 10H16V8H10V10ZM10 6H16V4H10V6Z"
              fill="#0065FF"
            />
          </svg>
          Blog Articles
        </h2>
      </div>
    </template>
    <template #content>
      <div
        v-if="articlesData"
        class="main-block blogArticles"
      >
        <loading
          v-model:active="isLoading"
          :can-cancel="true"
          :is-full-page="true"
          color="#0A68F7"
        />
        <div class="table-block blog-articles">
          <div
            ref="stickyBlock"
            class="table-block-header"
            :class="{ sticky: false }"
          >
            <div class="table-block-header-title">
              List of Articles
            </div>

            <div class="filters-component-container">
              <div class="filters-component-left-side">
                <StatusFilter
                  :is-status-search="isStatusSearch"
                  :search-status-toggle="searchStatusToggle"
                  :status-handler="statusHandler"
                  :selected-status="selectedStatus"
                  @handleSearchStatusClickOutside="() => isStatusSearch = false"
                />
                <TagsFilter
                  :is-tags-search="isTagsSearch"
                  :tags-search-toggle="tagsSearchToggle"
                  :tags-search-handler="tagsSearchHandler"
                  :selected-tag="selectedTag"
                  @handleTagsSearchClickOutside="() => isTagsSearch = false"
                />
                <AdminFilter
                  :is-admin-search="isAdminSearch"
                  :admin-search-toggle="adminSearchToggle"
                  :admin-search-handler="adminSearchHandler"
                  :selected-admin="selectedAdmin"
                  @handleAdminSearchClickOutside="() => isAdminSearch = false"
                />
                <DatesFilter
                  :is-dates-open="isDatesOpen"
                  @dateHandler="dateHandler"
                />
                <div
                  v-if="selectedDate || selectedTag || selectedStatus || selectedAdmin"
                  class="selected-filter-container"
                >
                  <div
                    v-if="selectedStatus"
                    class="selected-filter-item"
                  >
                    <span>Status:</span>
                    <span>{{ selectedStatus.name }}</span>
                    <FilterClose @click="statusHandler(null)" />
                  </div>
                  <div
                    v-if="selectedTag"
                    class="selected-filter-item"
                  >
                    <span>Tag:</span>
                    <span>{{ selectedTag.name }}</span>
                    <FilterClose @click="tagsSearchHandler(null)" />
                  </div>
                  <div
                    v-if="selectedAdmin"
                    class="selected-filter-item"
                  >
                    <span>Author:</span>
                    <span>{{ selectedAdmin.login }}</span>
                    <FilterClose @click="adminSearchHandler(null)" />
                  </div>
                  <div
                    v-if="selectedDate && selectedDate.length"
                    class="selected-filter-item"
                  >
                    <span>Date:</span>
                    <span>{{ selectedDate[0] }} - {{ selectedDate[1] }}</span>
                    <FilterClose @click="dateHandler(null)" />
                  </div>
                </div>
              </div>
              <button
                class="new-post-button"
                @click="$router.push({ name: 'Article Editor', params: {article: 'new'} })"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M4.16667 17.5C3.70833 17.5 3.31597 17.3368 2.98958 17.0104C2.66319 16.684 2.5 16.2917 2.5 15.8333V4.16667C2.5 3.70833 2.66319 3.31597 2.98958 2.98958C3.31597 2.66319 3.70833 2.5 4.16667 2.5H11.6042L9.9375 4.16667H4.16667V15.8333H15.8333V10.0417L17.5 8.375V15.8333C17.5 16.2917 17.3368 16.684 17.0104 17.0104C16.684 17.3368 16.2917 17.5 15.8333 17.5H4.16667ZM7.5 12.5V8.95833L15.1458 1.3125C15.3125 1.14583 15.5 1.02083 15.7083 0.9375C15.9167 0.854167 16.125 0.8125 16.3333 0.8125C16.5556 0.8125 16.7674 0.854167 16.9687 0.9375C17.1701 1.02083 17.3542 1.14583 17.5208 1.3125L18.6875 2.5C18.8403 2.66667 18.9583 2.85069 19.0417 3.05208C19.125 3.25347 19.1667 3.45833 19.1667 3.66667C19.1667 3.875 19.1285 4.07986 19.0521 4.28125C18.9757 4.48264 18.8542 4.66667 18.6875 4.83333L11.0417 12.5H7.5ZM9.16667 10.8333H10.3333L15.1667 6L14.5833 5.41667L13.9792 4.83333L9.16667 9.64583V10.8333Z"
                    fill="white"
                  />
                </svg>
                Create new
              </button>
            </div>
          </div>
          <div class="table-block-table">
            <Transition name="committee">
              <table class="table">
                <thead>
                  <tr class="table-headers">
                    <th
                      v-for="(item, index) in tableTh"
                      :id="item.id"
                      :key="index"
                      scope="col"
                      :class="'table-' + item.key"
                    >
                      <div
                        class="text"
                        @click="sortData(item)"
                        @mouseover="tokenTo = index"
                        @mouseleave="tokenTo = null"
                      >
                        {{ item.name }}
                        <span v-if="item.isSortable">
                          <sort-passive v-if="!item.sort" />
                          <sort-a-z v-if="item.sort === 'ASC'" />
                          <sort-z-a v-if="item.sort === 'DESC'" />
                        </span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody v-if="articlesData.length">
                  <BlogListItem
                    v-for="article in articlesData"
                    :key="article.id"
                    :article="article"
                  />
                </tbody>
              </table>
            </Transition>
            <Transition name="committee">
              <no-data-block v-if="!articlesData.length && !isLoading" />
            </Transition>
          </div>
          <paged-pagination
            v-if="articlesData.length && !isLoading && maxPage"
            :total="maxPage"
            :current="page"
            @change-page="pageHandler"
            @prev-page="pageHandler"
            @next-page="pageHandler"
          />
        </div>
      </div>
    </template>
  </AccountLayoutNew>
</template>

<script>
import {defineComponent} from 'vue';
import ExchangesIcon from '@/assets/img/icons/menu/exchangesIcon.vue';
import AccountLayoutNew from '@/components/admin/AccountLayoutNew.vue';
import Loading from 'vue-loading-overlay';
import SortAZ from '@/icons/sorting/sort-a-z.vue';
import SortPassive from '@/icons/sorting/sort-passive.vue';
import SortZA from '@/icons/sorting/sort-z-a.vue';
import {STATUSES, TABLE_TH} from '@/pages/blog-articles/blogArticlesData';
import StatusFilter from '@/pages/blog-articles/partials/StatusFilter.vue';
import DatesFilter from '@/components/admin/DatesFilter.vue';
import TagsFilter from '@/pages/blog-articles/partials/TagsFilter.vue'
import FilterClose from '@/icons/filterClose.vue';
import filterMixin from '@/mixins/filter.mixin';
import helperMixin from '@/mixins/helper.mixin';
import PagedPagination from '@/components/admin/PagedPagiantion.vue';
import BlogListItem from '@/pages/blog-articles/partials/BlogListItem.vue';
import BlogService from '@/services/blog.service';
import UserService from '@/services/user.service';
import AdminFilter from '@/pages/blog-articles/partials/AdminFilter.vue';
import NoDataBlock from '@/components/admin/NoDataBlock.vue';

export default defineComponent({
  components: {
    NoDataBlock,
    AdminFilter,
    StatusFilter,
    BlogListItem,
    PagedPagination,
    FilterClose,
    DatesFilter,
    TagsFilter,
    SortZA, SortPassive, SortAZ, Loading, AccountLayoutNew},
  mixins: [
    filterMixin,
    helperMixin
  ],
  data() {
    return {
      articlesData: [],
      tableTh: TABLE_TH,
      isLoading: true,
      maxPage: 0,
      page: 1,
      tokenTo: false,
      isUpdate: false,
      updateText: '',

      // status filter
      isStatusSearch: false,
      selectedStatus: null,
      articlesStatusList: STATUSES,
      // tags filter
      isTagsSearch: false,
      selectedTag: null,
      // partner filter
      isPartnerSearch: false,
      selectedPartner: null,
      // admin filter
      isAdminSearch: false,
      selectedAdmin: null,
      // date picker
      isDatesOpen: true,
      selectedDate: null,
      // sticky header
      isSticky: false,
      stickyOffset: 0
    }
  },
  mounted() {
    this.mountList()
    this.stickyOffset = this.$refs.stickyBlock.getBoundingClientRect().top;
  },
  beforeUnmount() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    mountList() {
      const query = this.$route.query;

      if (query.status) {
        const statusName = this.articlesStatusList.find(statusArrItem => statusArrItem.status === +query.status).name
        this.selectedStatus = {status: query.status,name: statusName }
      }

      if (query.tagId) {
        BlogService.getTags().then((res) => {
          if (res.status === 200) {
            this.selectedTag = res.data.result.find(item => item.id === +query.tagId);
          }
        })
      }

      if (query.AuthorID) {
        UserService.getAdmins().then((res) => {
          if (res.status === 200) {
            this.selectedAdmin = res.data.result.find(item => item.userId === +query.AuthorID);
          }
        })
      }

      if (query.start) {
        this.selectedDate = [query.start, query.end];
      }

      if (query.page) {
        this.page = +query.page;
      }
      this.getListByQuery(query);
    },
    getListByQuery(query) {
      this.$router.push({query: query}).then(() => {
        this.getList(query);
      });
    },
    getList(query) {
      BlogService.search(query).then((result) => {
        if (result.status === 200) {
          this.page = result.data.result.currentPage
          this.maxPage = result.data.result.maxPage
          this.articlesData = result.data.result.result
          this.isLoading = false;
        }
      }).catch((error) => {
        console.log(error.response.data.result, 'get articles error')
      })
    },
    // sticky header
    handleScroll() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      this.isSticky = scrollTop - 100 >= this.stickyOffset;
    },
    // tags search
    tagsSearchToggle() {
      this.isTagsSearch = !this.isTagsSearch;
    },
    tagsSearchHandler(tag) {
      this.selectedTag = tag
      this.isTagsSearch = false;
      this.isLoading = true
      this.page = 1

      let query = {...this.$route.query, page: 1, tagId: tag?.id};

      if (tag === null) {
        delete query.tagId;
      }

      this.$router.push({
        name: 'Blog Articles',
        query: query
      }).then(() => {
        this.getList(query);
      });
    },
    // admin search
    adminSearchToggle() {
      this.isAdminSearch = !this.isAdminSearch;
    },
    adminSearchHandler(admin) {
      this.selectedAdmin = admin
      this.isAdminSearch = false;
      this.isLoading = true
      this.page = 1

      let query = {...this.$route.query, page: 1, AuthorID: admin?.userId};

      if (admin === null) {
        delete query.AuthorID;
      }

      this.$router.push({
        name: 'Blog Articles',
        query: query
      }).then(() => {
        this.getList(query);
      });
    },
    // status search
    searchStatusToggle() {
      this.isStatusSearch = !this.isStatusSearch;
    },
    statusHandler(status) {
      this.selectedStatus = status
      this.isStatusSearch = false;
      this.isLoading = true
      this.page = 1

      let query = {...this.$route.query, page: 1, status: status?.status};

      if (status === null) {
        delete query.status;
      }

      this.$router.push({
        name: 'Blog Articles',
        query: query
      }).then(() => {
        this.getList(query);
      });
    },
    // date picker
    dateHandler(date) {
      this.selectedDate = date
      this.isLoading = true
      this.page = 1
      let query

      if (date === null) {
        query = {...this.$route.query, page: 1};
        delete query.start;
        delete query.end;
      } else {
        query = {...this.$route.query, page: 1, start: this.selectedDate[0], end: this.selectedDate[1]};
      }

      this.$router.push({
        name: 'Blog Articles',
        query: query
      }).then(() => {
        this.getList(query);
      });
    },
    // pagination
    pageHandler(page) {
      this.isLoading = true;
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;

      this.page = page;

      const query = {...this.$route.query, page: page};

      this.getListByQuery(query);
    },
    // sort
    sortData(item) {

    },
  }
})
</script>

<style lang="scss">
@import "../../assets/styles/pages/blog-articles/blog-articles.scss";
@import "../../assets/styles/components/filters";

.blog-articles {
  .table-block-header {
    flex-direction: row;
    @media (max-width: 1140px) {
      flex-direction: column !important;
    }
  }

  .table-block-header.sticky {
    position: fixed;
    top: 0;
    background: #fff;
    width: calc(100% - 385px);
    z-index: 10;

    @media (max-width: 1079px) {
      left: 0;
      width: calc(100% - 32px);
    }
  }

  .table-block-header-title {
    display: flex;
    align-items: center;
    align-self: flex-start;
    height: 32px;
    min-width: fit-content !important;
  }
}
</style>
