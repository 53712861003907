<template>
  <div
    ref="timer"
    class="timer"
  >
    <div class="timer_block">
      <div
        class="icon"
        @click="handleTimerRefresh()"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_4068_2960)">
            <path
              d="M9.16667 6.66667V10.8333L12.7083 12.9333L13.35 11.8667L10.4167 10.125V6.66667H9.16667ZM17.5 8.33333V2.5L15.3 4.7C13.95 3.34167 12.075 2.5 10 2.5C5.85833 2.5 2.5 5.85833 2.5 10C2.5 14.1417 5.85833 17.5 10 17.5C14.1417 17.5 17.5 14.1417 17.5 10H15.8333C15.8333 13.2167 13.2167 15.8333 10 15.8333C6.78333 15.8333 4.16667 13.2167 4.16667 10C4.16667 6.78333 6.78333 4.16667 10 4.16667C11.6083 4.16667 13.0667 4.825 14.125 5.875L11.6667 8.33333H17.5Z"
              fill="#0A68F7"
            />
          </g>
          <defs>
            <clipPath id="clip0_4068_2960">
              <rect
                width="20"
                height="20"
                fill="white"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div
        class="minutes"
        @click="timerHandler()"
      >
        {{ selectedTimer.name }}
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.40372 4.19995H8.59628C8.95551 4.19995 9.13512 4.65889 8.88084 4.92785L6.28556 7.67508C6.12815 7.84158 5.87185 7.84158 5.71444 7.67508L3.11916 4.92785C2.86488 4.65889 3.0445 4.19995 3.40372 4.19995Z"
            fill="#9FA2B4"
          />
        </svg>
      </div>
    </div>
    <div class="timer-progress">
      <LvProgressBar
        v-if="selectedTimer.interval"
        :value="timerValue"
        :show-value="false"
        color="#0065FF"
      />
    </div>
    <div
      v-if="timerHandle"
      class="dropdown"
    >
      <div
        v-for="(item, index) in dropdownItems"
        :key="index"
        class="dropdown-item"
        @click="handleSelectTimerValue(item)"
      >
        {{ item.name }}

        <svg
          v-if="selectedTimer.name === item.name"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_4068_2939)">
            <path
              d="M7.49896 13.5L4.58229 10.5833C4.25729 10.2583 3.74063 10.2583 3.41563 10.5833C3.09062 10.9083 3.09062 11.425 3.41563 11.75L6.90729 15.2416C7.23229 15.5666 7.75729 15.5666 8.08229 15.2416L16.9156 6.41664C17.2406 6.09164 17.2406 5.57498 16.9156 5.24998C16.5906 4.92498 16.074 4.92498 15.749 5.24998L7.49896 13.5Z"
              fill="#0A68F7"
            />
          </g>
          <defs>
            <clipPath id="clip0_4068_2939">
              <rect
                width="20"
                height="20"
                fill="white"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import LvProgressBar from "lightvue/progress-bar";
import {onBeforeUnmount, onMounted, ref} from 'vue';
export default {
  name: "Timer",
  components: {
    LvProgressBar
  },
  props: {
    handleTimerRefresh: {
      type: Function,
      required: true
    },
    handleSelectTimerValue: {
      type: Function,
      required: true
    },
    timerHandler: {
      type: Function,
      required: true
    },
    selectedTimer: {
      type: Object,
      required: true
    },
    timerValue: {
      type: Number,
      required: true
    },
    timerHandle: {
      type: Boolean,
      required: true
    }
  },
  emits: ['click-outside'],
  setup(props, { emit }) {
    const timer = ref(null);

    const handleClickOutside = (event) => {
      if (timer.value && !timer.value.contains(event.target)) {
        emit('click-outside');
      }
    };

    onMounted(() => {
      document.addEventListener('click', handleClickOutside);
    });

    onBeforeUnmount(() => {
      document.removeEventListener('click', handleClickOutside);
    });

    return {
      timer,
    };
  },
  data() {
    return {
      dropdownItems: [
        {name: "Off", interval: null},
        {name: "10s", interval: 10000},
        {name: "30s", interval: 30000},
        {name: "1m", interval: 60000},
        {name: "2m", interval: 120000},
        {name: "5m", interval: 300000},
        {name: "15m", interval: 900000}
      ],
    }
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables';

.timer {
  position: relative;

  .timer_block {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .icon {
      width: 32px;
      height: 32px;
      display: grid;
      place-items: center;
      background: $light-gray-color;
      border-right: 1px solid rgba(0, 101, 255, 0.12);
      border-radius: 8px 0 0 8px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      box-sizing: border-box;

      &:hover {
        background: $light-gray-hover-color;
      }
    }

    .minutes {
      width: 56px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 4px;
      color: $blue-color;
      background: $light-gray-color;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      border-radius: 0 8px 8px 0;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        background: $light-gray-hover-color;
      }
    }
  }

  .timer-progress {
    position: absolute;
    top: calc(100% + 4px);
    width: 100%;

    .lv-progressbar {
      max-width: 75px;
      margin: 0 auto;
      background: $light-gray-color;
      height: 2px;
    }
  }

  .dropdown {
    position: absolute;
    background: white;
    top: 37px;
    left: -10px;
    width: 120px;
    z-index: 999;
    border-radius: 8px;
    padding: 8px 0;
    box-shadow: 0 1px 4px 0 rgba(6, 59, 122, 0.12), 0 4px 24px 0 rgba(6, 59, 122, 0.08);

    .dropdown-item {
      padding: 6px 12px;
      color: $black-color1;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      &:hover {
        background: $hover-color;
      }
    }
  }
}
</style>
