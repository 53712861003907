<template>
  <svg
    width="93"
    height="32"
    viewBox="0 0 93 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  ><path
    d="M93 0H0V32H86L93 24V0Z"
    fill="#FF3D00"
  /><path
    d="M69.5798 27.2008V21.1208H80.4598V18.8808L69.2598 17.8248V9.28078L73.7398 4.80078H87.1798V10.8808H76.2998V12.8008L87.4998 13.8568V22.7208L83.0198 27.2008H69.5798Z"
    fill="#0B0B0B"
  /><path
    d="M56.2939 16.3208H61.0939V10.2408H56.2939V16.3208ZM68.1339 9.60078V16.7048L65.2539 19.5848L69.0939 27.2008H61.1579L58.8219 21.7608H56.2939V27.2008H49.2539V4.80078H63.3339L68.1339 9.60078Z"
    fill="#0B0B0B"
  /><path
    d="M25.2461 27.2008V4.80078H33.5661L36.4461 12.6088L39.3261 4.80078H47.6461V27.2008H40.7341V13.8568L37.7261 21.4408H35.1661L32.1581 13.8568V27.2008H25.2461Z"
    fill="#0B0B0B"
  /><path
    d="M3.80078 27.2008L8.28078 15.3608L4.28078 4.80078H11.8008L13.8808 12.4808H14.8408L16.9208 4.80078H24.4408L20.4408 15.3608L24.9208 27.2008H17.3048L15.0008 18.8808H13.7208L11.4168 27.2008H3.80078Z"
    fill="#0B0B0B"
  /></svg>
</template>

<script>
    export default {
        name: "LogoDev"
    }
</script>

<style scoped>

</style>
