<script>
import OrderService from "@/services/order.service";
import LevelItem from "@/pages/exchanges/partials/ExchangeInfoTabs/LevelItem.vue";
import {mapGetters} from "vuex";
import Avatar from "@/components/admin/AvatarComponent.vue";
import {PerfectScrollbar} from "vue3-perfect-scrollbar";



export default {
  name: 'ExchangeInfoComments',
  components: {PerfectScrollbar, Avatar, LevelItem},
  props: {
    data: {
      type: Object,
      default: null,
    },
    comments: {
      type: Array,
      default: null
    },
  },
  data() {
    return {
      newComment: {},
      logData: null,
      comment: '',
      logId: ''
    }
  },
  computed: {
    ...mapGetters({
      UserProfileData: 'getUserProfileData',
      ExchangeData: 'getExchangeData',
      /*CommentsLogsData: 'getCommentsLogsData'*/
    })
  },
  mounted() {
    this.logData = this.CommentsLogsData;
  },
  methods: {
    createComment() {
      OrderService.createComment(this.data.id, this.comment, null).then(res => {
        if (res) {
          this.comment = '';
          this.logId = '';
          this.updateLogs();
        }
      })
    },
    addCommentToLastItem() {
      if (this.logData.length > 0) {
        const lastItem = this.logData[this.logData.length - 1];
        if (lastItem.hasOwnProperty('level')) {
          this.logId = lastItem.id

          this.newComment = {level: lastItem.level, isAddComment: true, commentTheme: lastItem.commentTheme}
          this.logData.push(this.newComment);

          this.$nextTick(() => {
            this.scrollToBottom();
          });
        }
      }
    },
    scrollToBottom() {
      const scrollbar = this.$refs.scrollbar;
      if (scrollbar && scrollbar.$el) {
        scrollbar.$el.scrollTop = scrollbar.$el.scrollHeight;
      }
    },
    containsAMLCheck(log) {
      if (log && log.commentTheme) {
        return log.commentTheme.includes("AML Check");
      }
    },
    updateLogs() {
      /*OrderService.getComments(this.ExchangeData.id).then((res) => {
        this.$store.commit('setCommentsLogsData', res.data.result);
        this.logData = res.data.result;
      })*/
    },
    updateValue(event) {
      this.comment = event.target.innerText
    },
    removeLastItem() {
      if (this.logData.length > 0) {
        this.logData.pop();
      }
    }
  }
}

</script>

<template>
  <PerfectScrollbar
    ref="scrollbar"
    class="exchange-info-form-inputs-block"
  >
    <div class="comments-block">
      <div
        v-for="(item, index) in logData"
        :key="index"
        :class="{
          'level-first': item.level === 0,
          'level-second': item.level === 1,
          'level-three': item.level === 2,
        }"
      >
        <LevelItem
          v-if="!item.isAddComment"
          :item="item"
          :order-data="data"
          :avatar="UserProfileData"
          :add-last-log="updateLogs"
        />
        <div
          v-else
          class="level--item_block"
        >
          <div
            v-if="item.level > 0"
            class="separator"
          >
            <div class="text text-account">
              Account
            </div>
          </div>
          <div
            v-if="item.level === 2"
            class="separator"
          >
            <div class="text text-node">
              Node
            </div>
          </div>
          <div class="level--item ">
            <div
              class="status-comment"
              :class="{'status-comment_red': containsAMLCheck(item)}"
            />
            <div class="comment">
              <div
                class="comment_user"
              >
                <div class="avatar">
                  <avatar :img="UserProfileData.avatar" />
                </div>
                <div class="comment_user-text">
                  <span
                    class="text textarea"
                    role="textbox"
                    contenteditable
                    @input="updateValue"
                  />
                  <div
                    class="buttons"
                  >
                    <div
                      class="save"
                      @click="createComment"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_4241_49091)">
                          <path
                            d="M7.4987 13.5003L3.9987 10.0003L2.83203 11.167L7.4987 15.8337L17.4987 5.83366L16.332 4.66699L7.4987 13.5003Z"
                            fill="#0A68F7"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_4241_49091">
                            <rect
                              width="20"
                              height="20"
                              fill="white"
                            />
                          </clipPath>
                        </defs>
                      </svg>

                      Save
                    </div>
                    <div
                      class="cancel"
                      @click="removeLastItem"
                    >
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_4241_49094)">
                          <path
                            d="M21.8346 11.342L20.6596 10.167L16.0013 14.8253L11.343 10.167L10.168 11.342L14.8263 16.0003L10.168 20.6587L11.343 21.8337L16.0013 17.1753L20.6596 21.8337L21.8346 20.6587L17.1763 16.0003L21.8346 11.342Z"
                            fill="#FF783E"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_4241_49094">
                            <rect
                              width="20"
                              height="20"
                              fill="white"
                              transform="translate(6 6)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </PerfectScrollbar>
  <div
    class="comments-add"
    @click="addCommentToLastItem()"
  >
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_4241_49477)">
        <path
          d="M2.01 4C2.01 2.9 2.9 2 4 2H20C21.1 2 22 2.9 22 4V16C22 17.1 21.1 18 20 18H6L2 22L2.01 4ZM7 11H11V15H13V11H17V9H13V5H11V9H7V11Z"
          fill="#0A68F7"
        />
      </g>
      <defs>
        <clipPath id="clip0_4241_49477">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="matrix(-1 0 0 1 24 0)"
          />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>

<style lang="scss">
.comments {
  &-add {
    position: absolute;
    display: grid;
    place-items: center;
    right: 24px;
    bottom: 101px;
    width: 48px;
    height: 48px;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 2px 8px 0px rgba(27, 26, 28, 0.16);
    transition: all .3s ease-in-out;

    path {
      transition: all .3s ease-in-out;
    }

    &:hover {
      background: #0A68F7;
      cursor: pointer;

      path {
        fill: white;
      }
    }
  }

  &-block {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .level {
      &--item {
        width: 100%;
        border-radius: 6px;
        border: 1px solid #DFE0EB;
        background: #FFF;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        gap: 14px;
        margin: 2px 0;
        position: relative;

        .status-comment {
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 6px;
          min-height: 100%;
          background: var(--green, #02C076);

          &_red {
            background: var(--green, #FF783E);
          }
        }

        .comment {
          padding: 12px 12px 12px 18px;
          box-sizing: border-box;
          width: 100%;
          position: relative;
          display: flex;
          flex-direction: column;
          gap: 14px;

          &_block {
            position: relative;

            .data {
              position: absolute;
              top: 0;
              right: 0;
              color: #7C86A0;
              text-align: right;
              font-family: 'Inter', sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 8px;

              .user {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 3px;
              }
            }

            .head {
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: flex-start;

              &-aml {
                margin-bottom: 6px;
              }

              .status {
                color: white;
                font-family: 'Inter', sans-serif;
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;
                padding: 2px 8px;
                border-radius: 6px;
                background: rgba(159, 162, 180, 0.20);
                margin-bottom: 6px;

                &_block {
                  display: flex;
                  flex-direction: row;
                  gap: 2px;
                  align-items: center;
                }

                &--remaining {
                    background: #8B81FF;
                }

                &--withdrawal {
                    background: #8500ED;
                }

                &--canceled {
                    background: #FF783E;
                }

                &--empty {
                    background: #c00fb2;
                }

                &--completed {
                  background: #02C076;
                }

                &--pay {
                    background: #44A5FF;
                }

                &--exchange {
                    background: #673DFD;
                }

                &--overdue {
                    background: #FF783E;
                }

                &--returned, &--hold, &--profit {
                    background: red;
                }
              }

              .title {
                color: var(--text, #131521);
                font-family: 'Inter', sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
                margin-bottom: 2px;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 6px;

                .aml {
                  display: flex;
                }
              }

            }

            .info {
              max-width: 100%;

              &--item {
                color: #000;
                font-family: 'Inter', sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                word-wrap: break-word;
                max-width: calc(100% - 80px);
              }
            }

            .buttons {
              position: absolute;
              bottom: -4px;
              right: 0;
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 8px;

              .copy, .add {
                width: 24px;
                height: 24px;
                display: grid;
                place-items: center;
                transition: all .3s ease-in-out;

                svg {
                  path {
                    transition: all .3s ease-in-out;
                  }
                }

                &:hover {
                  border-radius: 4px;
                  background: rgba(10, 104, 247, 0.12);
                  cursor: pointer;

                  path {
                    fill: #0A68F7;
                  }
                }
              }
            }
          }

          &_user {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            gap: 12px;
            border-radius: 6px;
            background: rgba(159, 162, 180, 0.12);
            padding: 8px 16px 16px 8px;
            position: relative;

            .avatar {
              border-radius: 50%;
              overflow: hidden;
              min-width: 32px;
              img, svg {
                width: 32px;
                height: 32px;
              }
            }

            &-text {
              display: flex;
              flex-direction: column;
              width: 100%;

              .textarea[contenteditable]:empty::before {
                content: "Write your comment here";
                color: gray;
              }

              .textarea {
                &:focus {
                  outline: none;
                  border: none;
                }
              }

              .buttons {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 2px;
                position: absolute;
                right: 8px;
                bottom: 8px;

                .save {
                  padding: 6px 12px 6px 6px;
                  display: flex;
                  flex-direction: row;
                  gap: 2px;
                  align-items: center;
                  color: #0A68F7;
                  font-family: 'Inter', sans-serif;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 20px; /* 142.857% */
                  cursor: pointer;
                  border-radius: 6px;
                  transition: all .3s ease-in-out;

                  &:hover {
                    background: rgba(10, 104, 247, 0.12);
                  }
                }

                .cancel {
                  display: flex;
                  cursor: pointer;
                  border-radius: 6px;
                  transition: all .3s ease-in-out;

                  &:hover {
                    background: rgba(255, 120, 62, 0.12);
                  }
                }
              }

              .head {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 8px;

                .login {
                  color: #131521;
                  text-align: right;
                  font-family: 'Inter', sans-serif;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 18px; /* 128.571% */
                }

                .date {
                  color: #7C86A0;
                  text-align: right;
                  font-family: 'Inter', sans-serif;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 18px; /* 128.571% */
                }
              }

              .text {
                margin-top: 4px;
                color: #000;
                font-family: 'Inter', sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                word-wrap: break-word;
                max-width: calc(100% - 80px);
              }

              &__add {
                input {
                  width: 100%;
                }
              }
            }
          }
        }

        &_block {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          gap: 34px;
          position: relative;
        }
      }

      &-first {
        width: 100%;
      }

      &-second {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 34px;
        align-items: stretch;

        .separator {
          display: flex;
          flex-direction: row;
          border-left: 1px solid #DFE0EB;
          position: relative;
          box-sizing: border-box;

          .text {
            left: -22px;
            top: 26px;
            position: absolute;
            transform: rotate(-90deg);
            z-index: 9;
            color: #DFE0EB;
            font-family: 'Golos Text', sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 12px; /* 100% */
            letter-spacing: 0.6px;
            text-transform: uppercase;

            &-account {
              display: none;
            }

            &-node {
              display: none;
            }
          }
        }

        .items {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 3px;
        }

        &:first-child {
          .separator {
            margin-top: 8px;
          }

          .separator {
            .text {
              display: flex !important;
            }
          }
        }

        &:last-child {
          .separator {
            margin-bottom: 8px;
          }
        }
      }

      &-three {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 34px;
        align-items: stretch;

        .separator {
          display: flex;
          flex-direction: row;
          border-left: 1px solid #DFE0EB;
          position: relative;
          margin-top: 8px;
          margin-bottom: 8px;
          box-sizing: border-box;

          .text {

            position: absolute;
            transform: rotate(-90deg);
            z-index: 9;
            color: #DFE0EB;
            font-family: 'Golos Text', sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 12px; /* 100% */
            letter-spacing: 0.6px;
            text-transform: uppercase;

            &-account {
              left: -22px;
              top: 25px;
            }

            &-node {
              left: -9px;
              top: 12px;
            }
          }
        }

        .items {
          width: 100%;
        }
      }
    }
  }
}
</style>
