<template>
  <div
    v-if="exchangeData"
    ref="modal"
    tabindex="0"
    class="exchange-info"
    @keyup.esc="closeModal"
    @click="modalOverlayClose"
  >
    <div

      class="exchange-info-form"
      :class="{'exchange-info-form--logs': tab === 'comments'}"
    >
      <div
        v-if="isLoader"
        class="loader"
      >
        <trade-loader />
      </div>
      <div class="exchange-info-form-info">
        <div class="exchange-info-form-info-head">
          <div class="block">
            <div class="block-statusId">
              <div class="id">
                ID {{ exchangeData.id }}
              </div>
              <div class="status">
                <select-status-popup-exchange
                  v-if="statuses"
                  :options="statuses"
                  :value-input="exchangeData.status"
                  class="select select-popup"
                  :data="exchangeData"
                  @change-select="changeStatus"
                />
              </div>
            </div>
            <div
              v-if="allowStatuses.includes(exchangeData.status)"
              class="botStart"
              :class="{'botStart-running': isRecalcLoader || isEdited}"
              @mouseover="isBotHover = true"
              @mouseleave="isBotHover = false"
              @click="recalculateOrder(exchangeData)"
            >
              <play-icon />
              <span v-if="!isRecalcLoader">Run bot</span>
              <span v-else>Bot is running</span>

              <transition
                name="fade"
                mode="in-out"
              >
                <hover-text
                  v-if="isEdited && isBotHover"
                  class="botStart_hover"
                  option="First save changes"
                />
              </transition>
            </div>
          </div>
          <div class="date">
            {{ dateFormatting(exchangeData.createDate) }}
          </div>
        </div>
        <div class="exchange-info-form-info-block exchange-info-form-info-block_auto">
          <div class="exchange-info-form-info-block_items">
            <div class="exchange-info-form-info-block-item exchange-info-form-info-block-item_coin">
              <div class="exchange-info-form-info-block-item-main">
                <div class="desc">
                  From
                </div>
                <div
                  class="data"
                  @mouseover="currentCurs1 = exchangeData.curs1"
                  @mouseleave="currentCurs1 = null"
                >
                  {{ exchangeData.coinFrom.symbol }}
                  <span v-if="exchangeData.coinFromNetwork">({{ exchangeData.coinFromNetwork }})</span>
                </div>
                <transition
                  name="fade"
                  mode="in-out"
                >
                  <hover-text
                    v-if="currentCurs1 === data.curs1"
                    :option="exchangeData.curs1"
                  />
                </transition>
              </div>
              <div class="exchange-info-form-info-block-item-main">
                <div class="desc">
                  To
                </div>
                <div
                  class="data"
                  @mouseover="currentCurs2 = data.curs2"
                  @mouseleave="currentCurs2 = null"
                >
                  {{ data.coinTo.symbol }} <span
                    v-if="exchangeData.coinToNetwork"
                  >({{ data.coinToNetwork }})</span>
                </div>
                <transition
                  name="fade"
                  mode="in-out"
                >
                  <hover-text
                    v-if="currentCurs2 === data.curs2"
                    :option="exchangeData.curs2"
                  />
                </transition>
              </div>
            </div>
            <div
              class="exchange-info-form-info-block-item exchange-info-form-info-block-item_coin"
            >
              <div class="exchange-info-form-info-block-item-main">
                <div class="desc">
                  Amount
                </div>
                <div class="data inputs">
                  <ValidateInput
                    :value="exchangeData.amountFrom"
                    @update:value="exchangeData.amountFrom = $event"
                    @update:error="error = $event"
                  />
                  <!--                  <input-->
                  <!--                    :value="exchangeData.amount"-->
                  <!--                    type="text"-->
                  <!--                  >-->
                  <!--                {{ data.amount }}-->
                </div>
              </div>
              <div
                v-if="exchangeData"
                class="exchange-info-form-info-block-item-main amount-result"
              >
                <div
                  class="desc"
                >
                  Amount Result
                  <div
                    v-if="exchangeData"
                    class="inputs-block"
                    @mouseover="currentAmountResult = exchangeData.amountResult"
                    @mouseleave="currentAmountResult = null"
                  >
<!--                    <up-icon-big
                      v-if="isAmountUp"
                      class="desc-icon"
                    />
                    <down-iconbig
                      v-else
                      class="desc-icon"
                    />-->
                    <transition name="fade">
                      <amount-history
                        v-if="currentAmountResult === exchangeData.amountResult"
                        :options="exchangeData.amountHistory"
                      />
                    </transition>
                  </div>
                </div>
                <div class="data inputs">
                  <ValidateInput
                    :value="exchangeData.amountTo"
                    @update:value="exchangeData.amountTo = $event"
                    @update:error="error = $event"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="exchange-info-form-info-block">
          <div
            class="exchange-info-form-info-block-item"
          >
            <div
              v-if="exchangeData.partnerFee"
              class="exchange-info-form-info-block-item-block"
            >
              <div class="desc">
                Partner fee
              </div>
              <div
                class="input"
                @mouseover="currentPartnerFee = exchangeData.partnerFee"
                @mouseleave="currentPartnerFee = null"
              >
                <ValidateInput
                  :value="exchangeData.partnerFee"
                  @update:value="exchangeData.partnerFee = $event"
                  @update:error="error = $event"
                />

                <transition name="fade">
                  <hover-text
                    v-if="currentPartnerFee === exchangeData.partnerFee"
                    :option="exchangeData.partnerFee"
                  />
                </transition>
              </div>
            </div>
            <div
              v-if="exchangeData.companyFee || exchangeData.companyFee === ''"
              class="exchange-info-form-info-block-item-block"
            >
              <div class="desc">
                Company fee
              </div>
              <div
                class="input"
                @mouseover="currentCompanyFee = exchangeData.companyFee"
                @mouseleave="currentCompanyFee = null"
              >
                <ValidateInput
                  :value="exchangeData.companyFee"
                  @update:value="exchangeData.companyFee = $event"
                  @update:error="error = $event"
                />

                <transition name="fade">
                  <hover-text
                    v-if="currentCompanyFee === exchangeData.companyFee"
                    :option="exchangeData.companyFee"
                  />
                </transition>
              </div>
            </div>
          </div>
          <div
            class="exchange-info-form-info-block-item"
          >
            <div
              v-if="exchangeData.partnerName"
              class="exchange-info-form-info-block-item-block"
            >
              <div class="desc">
                Partner login
              </div>
              <div class="data">
                {{ exchangeData.partnerName }}
              </div>
            </div>
            <div
              v-if="exchangeData.binanceAcc"
              class="exchange-info-form-info-block-item-block"
            >
              <div class="desc">
                Binance account
              </div>
              <div class="data">
                {{ exchangeData.binanceAcc }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="exchange-info-form-inputs">
        <div class="exchange-info-form-inputs-controls">
          <div
            v-if="exchangeData"
            class="exchange-info-form-inputs-controls-item"
            :class="{active: tab === 'general'}"
            @click="tab = 'general'"
          >
            general
          </div>
          <div
            v-if="exchangeData.deposit || data.status === 10"
            class="exchange-info-form-inputs-controls-item"
            :class="{active: tab === 'deposit'}"
            @click="tab = 'deposit'"
          >
            deposit
          </div>
          <div
            v-if="exchangeData.depositNodeId"
            class="exchange-info-form-inputs-controls-item"
            :class="{active: tab === 'node'}"
            @click="tab = 'node'"
          >
            node
          </div>
          <div
            v-if="exchangeData.allTransaction && exchangeData.allTransaction.length"
            class="exchange-info-form-inputs-controls-item"
            :class="{active: tab === 'transactions'}"
            @click="tab = 'transactions'"
          >
            transactions
          </div>
          <div
            v-if="exchangeData.withdrawalHistory && exchangeData.withdrawalHistory.length"
            class="exchange-info-form-inputs-controls-item"
            :class="{active: tab === 'withdrawal'}"
            @click="tab = 'withdrawal'"
          >
            withdrawal
          </div>
          <div
            v-if="exchangeData"
            class="exchange-info-form-inputs-controls-item"
            :class="{active: tab === 'info'}"
            @click="tab = 'info'"
          >
            Info
          </div>
          <div
            v-if="exchangeData.jsonVisitor"
            class="exchange-info-form-inputs-controls-item"
            :class="{active: tab === 'user'}"
            @click="tab = 'user'"
          >
            User
          </div>
          <div
            v-if="CommentLogData"
            class="exchange-info-form-inputs-controls-item"
            :class="{active: tab === 'comments'}"
            @click="tab = 'comments'"
          >
            Logs
            <div class="number">
              <div class="number-value">
                {{ CommentLogData.length }}
              </div>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_4241_49471)">
                  <path
                    d="M16.668 1.66699H3.33464C2.41797 1.66699 1.66797 2.41699 1.66797 3.33366V18.3337L5.0013 15.0003H16.668C17.5846 15.0003 18.3346 14.2503 18.3346 13.3337V3.33366C18.3346 2.41699 17.5846 1.66699 16.668 1.66699Z"
                    fill="#02C076"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_4241_49471">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </div>
        <exchange-info-general
          v-if="(tab === 'general')"
          :data="exchangeData"
          :exchange="exchangeData"
          @wtx-id-updated="handleWtxId"
        />
        <exchange-info-deposit
          v-if="(tab === 'deposit' && exchangeData.deposit) || (tab === 'deposit' && data.status === 0)"
          :data="exchangeData.deposit"
          :exchange="exchangeData"
        />
        <exchange-info-node
          v-if="(tab === 'node' && exchangeData.depositNodeId)"
          :exchange="exchangeData"
        />
        <exchange-info-transactions
          v-if="tab === 'transactions'"
          :data="exchangeData.allTransaction"
        />
        <exchange-info-withdrawal
          v-if="tab === 'withdrawal'"
          :data="exchangeData.withdrawalHistory"
        />
        <exchange-info-info
          v-if="tab === 'info'"
          :data="exchangeData"
        />
        <exchange-info-user
          v-if="tab === 'user'"
          :data="exchangeData"
        />
        <exchange-info-comments
          v-if="tab === 'comments'"
          :data="exchangeData"
          :comments="CommentLogData"
        />
        <div class="exchange-info-form-inputs-buttons">
          <div class="buttons">
            <div
              v-if="exchangeData.amountFrom && !isEdited"
              class="buttons-item buttons-discard"
              @click="closeModal"
              @keydown.esc="closeModal()"
            >
              Exit
            </div>
            <div
              v-if="exchangeData.amountFrom && isEdited"
              class="buttons-item buttons-discard"
              @click="closeModal"
              @keydown.esc="closeModal()"
            >
              Exit without changes
            </div>
            <div
              v-if="exchangeData.amountFrom && isEdited && !error"
              class="buttons-item buttons-save"
              @click="updateOrder"
            >
              Save changes
            </div>
            <div
              v-if="exchangeData.amountFrom && !isEdited && error"
              class="buttons-item buttons-save buttons-save_disabled"
            >
              Save changes
            </div>
            <div
              v-if="isEdited && error"
              class="buttons-item buttons-save buttons-save_disabled"
            >
              Save changes
            </div>
          </div>
        </div>
      </div>
      <div
        class="exchange-info-form-close"
        @click="closeModal"
      >
        <close-icon v-if="!isMobile()" />
        <close-mob v-if="isMobile()" />
      </div>
    </div>
    <CopyNotification
      v-if="isSuccessCopy"
      :text="copyText"
    />
  </div>
</template>

<script>
import CloseIcon from "../../../icons/closeIcon";
import HoverText from "../../coins/partials/hoverText";
import {ALLOW_STATUS, STATUSES} from "@/pages/exchanges/exchangeData";
import OrderService from "../../../services/order.service";
import {mapGetters} from 'vuex';
import ExchangeInfoDeposit from "./ExchangeInfoTabs/ExchangeInfoDeposit";
import ExchangeInfoTransactions from "./ExchangeInfoTabs/ExchangeInfoTransactions";
import ExchangeInfoWithdrawal from "./ExchangeInfoTabs/ExchangeInfoWithdrawal";
import ExchangeInfoUser from "./ExchangeInfoTabs/ExchangeInfoUser";
import UpIconBig from "../../../icons/UpIconBig";
import DownIconbig from "../../../icons/downIconbig";
import AmountHistory from "./AmountHistory";
import helperMixin from "@/mixins/helper.mixin";
import CloseMob from "@/icons/CloseMob";
import CopyNotification from "@/pages/notification/CopyNotification";
import ExchangeInfoGeneral from "@/pages/exchanges/partials/ExchangeInfoTabs/ExchangeInfoGeneral.vue";
import ExchangeInfoNode from "@/pages/exchanges/partials/ExchangeInfoTabs/ExchangeInfoNode.vue";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import SelectStatusPopupExchange from "@/pages/exchanges/partials/SelectStatusPopupExchange.vue";
import PlayIcon from "@/assets/img/icons/PlayIcon.vue";
import TradeLoader from "@/components/trade/components/TradeLoader.vue";
import ValidateInput from "@/pages/exchanges/partials/ValidateInput.vue";
import ExchangeInfoComments from "@/pages/exchanges/partials/ExchangeInfoTabs/ExchangeInfoComments.vue";
import ExchangeInfoInfo from "@/pages/exchanges/partials/ExchangeInfoTabs/ExchangeInfoInfo.vue";

export default {
  name: "ExchangeInfo",
  components: {
    ExchangeInfoInfo,
    ExchangeInfoComments,
    ValidateInput,
    TradeLoader,
    PlayIcon,
    SelectStatusPopupExchange,
    ExchangeInfoNode,
    ExchangeInfoGeneral,
    CopyNotification,
    CloseMob,
    AmountHistory,
    /*DownIconbig,
    UpIconBig,*/
    ExchangeInfoUser,
    ExchangeInfoWithdrawal,
    ExchangeInfoTransactions, ExchangeInfoDeposit, HoverText, CloseIcon
  },
  mixins: [
    helperMixin
  ],
  props: {
    data: {
      type: Object,
      default: null,
    },
    logs: {
      type: Object,
      default: null,
    },
    routeTo: {
      type: Object,
      default: null
    },
    routeToPage: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      exchangeData: null,
      isEdited: false,
      isBotHover: false,
      currentAddress: null,
      currentBinanceAddress: null,
      currentNodeAddress: null,
      currentMemo: null,
      currentCurs1: null,
      currentCurs2: null,
      currentPartnerFee: null,
      currentCompanyFee: null,
      currentAmountResult: null,
      tab: 'deposit',
      statuses: STATUSES,
      statusClass: '',
      currentStatus: {},
      maxTextLength: 30,
      maxNumberLength: 11,
      statusId: null,
      amountResult: '',
      isAmountUp: false,
      copyText: '',
      isSuccessCopy: true,
      logsData: null,
      dateFormat: 'DD MMM YYYY - HH:mm',
      wTxIdData: '',
      isRecalcLoader: false,
      allowStatuses: ALLOW_STATUS,
      partnerFeeCustom: '',
      companyFeeCustom: '',
      isLoader: false,
      error: false,
      logData: null
    }
  },
  computed: {
    ...mapGetters({
      Notification: 'getNotification',
      /*ActivityData: 'getOrderStatusActivityData',*/
      RouteBack: 'getRouterBackPath',
      /*CommentLogData: 'getCommentsLogsData'*/
    }),
  },
  watch: {
    exchangeData: {
      handler(newData, oldData) {
        delete newData.wTxId
        console.log(newData , this.data,'AAAAAAA')
        this.isEdited = JSON.stringify(newData) !== JSON.stringify(this.data);
      },
      deep: true
    },
    isEdited(newValue) {
      if (newValue) {
        this.stopTimer();
      } else {
        this.startTimer();
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.$refs.modal.focus();
    }, 100);
    this.exchangeData = {...this.data};
    console.log(this.exchangeData)
    this.logsData = this.logs;
    this.setCurrentStatus();
    /*this.getCommentsData();*/
    this.partnerFeeCustom = this.data.partnerFee;
    this.companyFeeCustom = this.data.companyFee;
    this.statusId = this.currentStatus.status;
    if (this.data.status === 0) {
      this.tab = 'deposit'
    } else {
      this.tabSelect();
    }
    this.calcAmount();
    this.getLogsData();
    this.startTimer();
  },
  beforeUnmount() {
    this.$refs.modal.blur();
    this.stopTimer();
  },
  methods: {
    startTimer() {
      this.timer = setInterval(() => {
        if (!this.isEdited) {
          this.updateOrderData(this.data.id);
        }
      }, 20000);
    },
    stopTimer() {
      clearInterval(this.timer);
    },
    onCopyAddress() {
      this.copyText = 'Copied to clipboard!';

      this.isSuccessCopy = true;

      setTimeout(() => {
        this.isSuccessCopy = false
      }, 2000);
    },
    depositHandler(deposit) {
      return deposit !== null
    },
    /*getCommentsData() {
      OrderService.getComments(this.exchangeData.id).then((res) => {
        this.$store.commit('setCommentsLogsData', res.data.result);
      })
    },*/
    calcAmount() {
      let options = this.exchangeData.amountHistory;

      if (options) {
        let history = options.sort((a, b) => a.createDate - b.createDate);

        history = history.pop();
        if (history) {
          this.isAmountUp = (this.exchangeData.amountResult - history.oldAmount) > 0;
        }
      }
    },
    recalculateOrder() {
      if (!this.isEdited) {
        this.setNotification({name: 'Bot is running'});
        this.isRecalcLoader = true;
        this.emitter.off('update-notification');
        this.emitter.on('update-notification', () => {
          if (this.Notification && this.Notification.name) {
            OrderService.orderRecalc(this.data.id)
                .then((res) => {
                  if (res.data.result === 'OK') {
                    this.isRecalcLoader = false;
                    this.$store.commit('setNotification', {});
                  }
                })
                .catch(() => {
                  this.setNotification({name: 'Error'});
                });
          } else {
            this.isRecalcLoader = false
          }
        });
      }
    },
    tabSelect() {
      const tabData = {
        general: this.exchangeData,
        deposit: this.exchangeData.deposit,
        node: this.exchangeData.depositNodeId,
        transactions: this.exchangeData.allTransaction,
        withdrawal: this.exchangeData.withdrawalHistory,
        user: this.exchangeData,
        logs: this.CommentsLogsData,
      };

      const firstTab = Object.entries(tabData).find(([tab, data]) => data !== null && data !== 0);
      if (firstTab) {
        this.tab = firstTab[0];
      }
    },
    handleWtxId(wtxId) {
      if (!wtxId) {
        //this.exchangeData.wTxId = null
      } else {
        this.exchangeData.wTxId = wtxId
      }
    },
    setCurrentStatus() {
      let status = this.data.status;
      const exchangeStatuses = [2, 3, 5, 4];

      if (exchangeStatuses.includes(status)) {
        status = 5;
      }

      this.currentStatus = this.statuses.find((item) => item.status === status)
    },
    setNotification(data) {
      let result = {};

      if (data.name) {
        result = {
          name: data.name,
          value: data.value,
        }
      }
      this.$store.commit('setNotification', result);
    },
    changeStatus(data) {
      this.exchangeData.status = data.option.status;
      // this.setNotification({name: 'ID ' + data.data.id + ' status update to:', value: '' + data.option.name });
      this.emitter.off('update-notification');
      this.emitter.on('update-notification', () => {
        this.exchangeData.status = data.option.status;
      });
      // setTimeout(() =>  {
      //   this.setNotification({});
      // },3000)
    },
    updateOrder() {
      const order = {
        amount: this.exchangeData.amount,
        amountResult: this.exchangeData.amountResult,
        status: this.exchangeData.status,
        partnerFee: this.exchangeData.partnerFee,
        companyFee: this.exchangeData.companyFee,
        trId: this.exchangeData.wTxId,
      };
      this.setNotification({name: 'Update row ID', value: this.data.id});
      this.emitter.off('update-notification');
      this.isLoader = true;
      this.emitter.on('update-notification', () => {
        if (this.Notification && this.Notification.name) {
          OrderService.editOrderById(this.data.id, order).then((res) => {
            if (res.data.result) {
              this.updateOrderData(this.data.id)
              this.isEdited = false;
              this.isLoader = false;
            }
            this.$store.commit('setNotification', {});
          }).catch((error) => {
            if (error) {
              this.$store.commit('setExchangeData', res.data.result);
            }
          })
        } else {
          this.exchangeData = {...this.data}
          this.isLoader = false;
          this.isRecalcLoader = false
        }
      });

    },
    updateOrderData(exchangeId) {
      OrderService.getOrderAdminById(exchangeId).then((res) => {
        this.$store.commit('setExchangeData', res.data.result);

      })

      /*OrderService.getOrderActivity(exchangeId).then((res) => {
        this.$store.commit('setOrderStatusActivityData', res.data.result);
      })*/
    },
    closeModal() {
      this.$store.commit('setExchangeData', null);
      this.emitter.emit('exchange-binance-timer', true)
      document.body.classList.remove('modal-open');

      if (this.routeTo) {
        if (this.routeToPage === 'Exchanges') {
          this.emitter.emit('exchanges-list-refresh', this.routeTo)
          this.$router.push({name: 'Exchanges retro', query: this.routeTo});
        } else {
          this.$router.push({
            name: this.routeToPage,
            query: this.routeTo
          });
        }
      } else {
        if (this.routeToPage === 'Exchanges retro') {
          this.emitter.emit('exchanges-list-refresh', {page: 1})
          this.$router.push({name: this.routeToPage, query: {page: 1}});
        } else {
          this.$router.push({
            name: this.routeToPage,
            query: this.$store.state.routePathBack
          });
        }

      }

      this.$store.commit('setCommentsLogsData', null);
    },
    formattingData(data) {
      const text = data;

      if (text.length >= this.maxTextLength && !this.isMobile()) {
        return text.slice(0, this.maxTextLength) + '...';
      }

      return text;
    },
    numbersFormatting(data) {
      if (data.toString().length > this.maxNumberLength) {
        return data.toFixed(this.maxNumberLength) + '...';
      } else {
        return data
      }
    },
    getLogsData() {
      /*OrderService.getOrderActivity(this.exchangeData.id).then((res) => {
        this.$store.commit('setOrderStatusActivityData', res.data.result);
      })*/
    },
    dateFormatting(value) {
      dayjs.extend(utc)
      dayjs.extend(timezone)

      return dayjs.utc(value).local().format(this.dateFormat)
    },
    modalOverlayClose(event) {
      if (event.target === this.$refs.modal) {
        this.closeModal()
      }
    },
  }
}
</script>

<style lang="scss">
@import "../../../assets/styles/pages/exchanges/exchanges-modal";
</style>
