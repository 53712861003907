<template>
  <tr
    class="exchanges-list-item-tr"
    @click="exchangeInfo(item.id)"
  >
    <td class="table-run-bot">
      <div class="buttons-wrapper">
        <div
          class="run-bot-button"
          :class="{'run-bot-button-disabled': currentStatusIndex >= 6 || currentStatusIndex === 0 || isAllowedToRestart}"
          @click.stop="recalculateOrder(item)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M6.66663 15.8334V4.16669L15.8333 10L6.66663 15.8334Z"
              fill="#0065FF"
            />
          </svg>
        </div>
        <div
          class="restart-button"
          :class="{'restart-button-active': isAllowedToRestart}"
          @click.stop="onRestartHandler"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <path
                d="M9.16668 17.4583C7.48612 17.25 6.09376 16.5173 4.98959 15.2604C3.88543 14.0035 3.33334 12.5278 3.33334 10.8333C3.33334 9.91665 3.5139 9.03817 3.87501 8.1979C4.23612 7.35762 4.75001 6.62498 5.41668 5.99998L6.60418 7.18748C6.0764 7.6597 5.67709 8.20831 5.40626 8.83331C5.13543 9.45831 5.00001 10.125 5.00001 10.8333C5.00001 12.0555 5.3889 13.1354 6.16668 14.0729C6.94445 15.0104 7.94445 15.5833 9.16668 15.7916V17.4583ZM10.8333 17.4583V15.7916C12.0417 15.5694 13.0382 14.993 13.8229 14.0625C14.6076 13.1319 15 12.0555 15 10.8333C15 9.44442 14.5139 8.26387 13.5417 7.29165C12.5695 6.31942 11.3889 5.83331 10 5.83331H9.93751L10.8542 6.74998L9.68751 7.91665L6.77084 4.99998L9.68751 2.08331L10.8542 3.24998L9.93751 4.16665H10C11.8611 4.16665 13.4375 4.81248 14.7292 6.10415C16.0208 7.39581 16.6667 8.9722 16.6667 10.8333C16.6667 12.5139 16.1146 13.9826 15.0104 15.2396C13.9063 16.4965 12.5139 17.2361 10.8333 17.4583Z"
                fill="#A1BBD9"
              />
            </g>
          </svg>
        </div>
      </div>
    </td>
    <td class="table-date">
      {{ dataFormatting(item.createDate, 'DD.MM.YY') }} <br>
      {{ dataFormatting(item.createDate, 'HH:mm') }}
    </td>
    <td class="table-id">
      <span>{{ item.id }}</span> <br>
      <div class="copy-wrapper">
        <div
          class="copy"
          @click.stop="copyOrderLink(item.uid)"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 14C7.63333 14 7.31944 13.8694 7.05833 13.6083C6.79722 13.3472 6.66667 13.0333 6.66667 12.6666V4.66665C6.66667 4.29998 6.79722 3.98609 7.05833 3.72498C7.31944 3.46387 7.63333 3.33331 8 3.33331H14C14.3667 3.33331 14.6806 3.46387 14.9417 3.72498C15.2028 3.98609 15.3333 4.29998 15.3333 4.66665V12.6666C15.3333 13.0333 15.2028 13.3472 14.9417 13.6083C14.6806 13.8694 14.3667 14 14 14H8ZM8 12.6666H14V4.66665H8V12.6666ZM5.33333 16.6666C4.96667 16.6666 4.65278 16.5361 4.39167 16.275C4.13056 16.0139 4 15.7 4 15.3333V5.99998H5.33333V15.3333H12.6667V16.6666H5.33333Z"
              fill="#A1BBD9"
            />
          </svg>
        </div>
        <a
          :href="mainDomain + '/order/' + item.uid"
          target="_blank"
          rel="noopener noreferrer"
          @click.stop
        >
          {{ item.uid }}
        </a>
      </div>
    </td>
    <td class="table-partner">
      <div>
        <div>
          {{ item.partner ? item.partner : 'Direct' }} <br>
          <span>{{ item.market ? item.market : 'null' }}</span>
        </div>

        <svg
          v-if="item.mode === 'fix'"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_2988_15589)">
            <circle
              opacity="0.12"
              cx="12"
              cy="12"
              r="12"
              fill="#A1BBD9"
            />
            <g clip-path="url(#clip1_2988_15589)">
              <path
                d="M16 9.33342H15.3333V8.00008C15.3333 6.16008 13.84 4.66675 12 4.66675C10.16 4.66675 8.66663 6.16008 8.66663 8.00008V9.33342H7.99996C7.26663 9.33342 6.66663 9.93341 6.66663 10.6667V17.3334C6.66663 18.0667 7.26663 18.6667 7.99996 18.6667H16C16.7333 18.6667 17.3333 18.0667 17.3333 17.3334V10.6667C17.3333 9.93341 16.7333 9.33342 16 9.33342ZM12 15.3334C11.2666 15.3334 10.6666 14.7334 10.6666 14.0001C10.6666 13.2667 11.2666 12.6667 12 12.6667C12.7333 12.6667 13.3333 13.2667 13.3333 14.0001C13.3333 14.7334 12.7333 15.3334 12 15.3334ZM9.99996 9.33342V8.00008C9.99996 6.89341 10.8933 6.00008 12 6.00008C13.1066 6.00008 14 6.89341 14 8.00008V9.33342H9.99996Z"
                fill="#A1BBD9"
              />
            </g>
          </g>
          <defs>
            <clipPath id="clip0_2988_15589">
              <rect
                width="24"
                height="24"
                fill="white"
              />
            </clipPath>
            <clipPath id="clip1_2988_15589">
              <rect
                width="16"
                height="16"
                fill="white"
                transform="translate(4 4)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
    </td>
    <td class="table-pair">
      {{ item.coinFrom.shortName }} [{{ item.addressFromNetwork }}] <br>
      {{ item.coinTo.shortName }} [{{ item.addressToNetwork }}]
    </td>
    <td class="table-amount-exchange">
      {{ formatNumberWithComma(item.amountFrom,6) }} <br>
      {{ formatNumberWithComma(item.amountTo,6) }}
    </td>
    <td class="table-aml">
      <AmlIconGrey v-if="item.amlError === 0" />
      <AmlIconGreen v-if="item.amlError === 1" />
      <AmlIconRed v-if="item.amlError === 2" />
    </td>
    <td class="table-status">
      <div class="shadow" />
      <div
        v-if="currentStatusIndex !== null"
        ref="statusDropdown"
        class="status-button"
        :class="{
          ['status-button-' + ExchangeStatusList.find(statusArrItem => statusArrItem?.status === currentStatusIndex).id.toLowerCase()]: true
        }"
        @click.stop="statusDropdownHandler()"
      >
        {{ ExchangeStatusList.find(statusArrItem => statusArrItem?.status === currentStatusIndex).name }}
      </div>
      <div
        v-if="isOpen"
        class="dropdown"
        :class="{'position-top': positionOfDropdown === 'top'}"
      >
        <div
          v-for="(statusItem) in ExchangeStatusList"
          :key="statusItem.status"
          class="dropdown-item"
          @click.stop="setStatusHandler(statusItem.status)"
        >
          <div>
            <span
              class="status-label"
              :class="statusItem.cssClass"
            >
              {{ statusItem.name[0] }}
            </span>
            {{ statusItem.name }}
          </div>
          <svg
            v-if="statusItem.status === currentStatusIndex"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_4068_2939)">
              <path
                d="M7.49896 13.5L4.58229 10.5833C4.25729 10.2583 3.74063 10.2583 3.41563 10.5833C3.09062 10.9083 3.09062 11.425 3.41563 11.75L6.90729 15.2416C7.23229 15.5666 7.75729 15.5666 8.08229 15.2416L16.9156 6.41664C17.2406 6.09164 17.2406 5.57498 16.9156 5.24998C16.5906 4.92498 16.074 4.92498 15.749 5.24998L7.49896 13.5Z"
                fill="#0A68F7"
              />
            </g>
            <defs>
              <clipPath id="clip0_4068_2939">
                <rect
                  width="20"
                  height="20"
                  fill="white"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    </td>
  </tr>
  <CopyNotification
    v-if="isNotificationShow"
    :text="notificationText"
  />
</template>

<script>
import {mapGetters} from 'vuex';
import {STATUSES} from "../exchangeData";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import AmlIconGreen from '@/assets/img/icons/AmlIconGreen.vue';
import OrderService from '@/services/order.service';
import AmlIconGrey from '@/assets/img/icons/AmlIconGrey.vue';
import AmlIconRed from '@/assets/img/icons/AmlIconRed.vue';
import CopyNotification from '@/pages/notification/CopyNotification.vue';

export default {
  name: "ExchangesListItem",
  components: {CopyNotification, AmlIconRed, AmlIconGrey, AmlIconGreen},
  props: {
    item: {
      type: Object,
      required: true
    },
    isOpen: {
      type: Boolean,
      required: true
    }
  },
  emits: ['toggleDropdown', 'closeDropdown'],
  data() {
    return {
      notificationText: null,
      isNotificationShow: false,
      mainDomain: process.env.VUE_APP_MAIN_DOMAIN,
      ExchangeStatusList: STATUSES,
      currentStatusIndex: null,
      positionOfDropdown: 'bottom',
      isAllowedToRestart: false
    }
  },
  computed: {
    ...mapGetters({
      Notification: 'getNotification',
    }),
  },
  mounted() {
    this.getCurrentStatusIndex()
    this.checkIsAllowedToRestart()
    document.addEventListener('click', this.statusClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.statusClickOutside);
  },
  methods: {
    checkIsAllowedToRestart() {
      if (this.item.nodeStatus === 'refill' || this.item.nodeStatus === 'on hold' || this.item.nodeStatus === 'checking limit' || this.item.nodeStatus === 'stopped' || this.item.nodeStatus === 'received'  || this.item.nodeStatus === 'on pause' || this.item.nodeStatus === 'segmented') {
        this.isAllowedToRestart = true
      }
    },
    onRestartHandler() {
      if (!this.isAllowedToRestart) {
        return false
      }
      this.$store.commit('setShouldTimerStopped', true)
      const result = confirm("Are you sure you want to restart?");
      if (result) {
        this.setNotification({name: 'Update', value: 'Restart '});
        this.emitter.off('update-notification');
        this.emitter.on('update-notification', () => {
          if (this.Notification && this.Notification.name) {
            OrderService.getRefundOrder(this.item.id).then((result) => {
              if (result.data.result === 'OK') {
                this.$store.commit('setNotification', {name: 'Successfull',value: 'restarted'});
                this.emitter.off('update-notification');
                this.$store.commit('setShouldTimerStopped', false)
              }
            }).catch((error) => {
              this.$store.commit('setError', {
                name: 'Restart error',
                value: error?.response.data.message,
              });
              this.$store.commit('setShouldTimerStopped', false)
            });
          }
        });
      } else {
        this.$store.commit('setShouldTimerStopped', false)
        console.log("User clicked Cancel");
      }
    },
    copyOrderLink(uid) {
      navigator.clipboard.writeText(uid)
        .then(() => {
          this.notificationText = 'Copied to clipboard!';

          this.isNotificationShow = true;

          setTimeout(() => {
            this.isNotificationShow = false
          }, 2000);
        })
        .catch(err => {
          console.error('Could not copy text: ', err);
        });
    },
    setDropdownPosition() {
      const statusDropdown = this.$refs.statusDropdown;
      const rect = statusDropdown.getBoundingClientRect();
      const dropdownHeight = 304;

      if (rect.bottom + dropdownHeight > window.innerHeight) {
        this.positionOfDropdown = 'top';
      } else {
        this.positionOfDropdown = 'bottom';
      }
    },
    getCurrentStatusIndex()  {
      this.currentStatusIndex = this.item.status
    },
    dataFormatting(value, format) {
      dayjs.extend(utc)
      dayjs.extend(timezone)

      return dayjs.utc(value).local().format(format)
    },
    setNotification(data) {
      let result = {};

      if (data.name) {
        result = {
          name: data.name,
          value: data.value,
        }
      }
      this.$store.commit('setNotification', result);
    },
    exchangeInfo(exchangeId) {
      OrderService.getOrderAdminById(exchangeId).then((res) => {
        this.$store.commit('setExchangeData', res.data.result);
        this.emitter.emit('exchange-multi-info', this.$route.query)
        document.body.classList.add('modal-open');

        this.$store.commit('setRoutePathBack', this.$route.query)
        this.$router.push({
          name: 'Exchange modal',
          params: {
            id: exchangeId,
          }
        })
      })

      /*OrderService.getOrderActivity(exchangeId).then((res) => {
        this.$store.commit('setOrderStatusActivityData', res.data.result);
      })*/
      this.emitter.emit('exchange-binance-timer', false)
    },
    recalculateOrder(data) {
      if (this.currentStatusIndex >= 6 || this.currentStatusIndex === 0) {
        return false
      }
      this.$store.commit('setShouldTimerStopped', true)
      this.setNotification({name: 'Update', value: 'Recalc '});
      this.emitter.off('update-notification');
      this.emitter.on('update-notification', () => {
        if (this.Notification && this.Notification.name) {

          OrderService.orderRecalc(this.item.id).then((result) => {
            if (result.data.result === 'OK') {
              this.$store.commit('setNotification', {name: 'Successfull',value: 'recalc'});
              this.emitter.off('update-notification');
              this.$store.commit('setShouldTimerStopped', false)
            }
          }).catch((error) => {
            this.$store.commit('setError', {
              name: 'Recalc error',
              value: error?.response.data.message,
            });
            this.$store.commit('setShouldTimerStopped', false)
          });
        }
      });
    },
    setStatusHandler(status) {
      const newStatusName = this.ExchangeStatusList.find(statusArrItem => statusArrItem.status === status).name
      this.setNotification({name: 'ID ' + this.item.id + ', status has been changed to "' + newStatusName + '" status' });
      this.emitter.off('update-notification');
      this.emitter.on('update-notification', () => {
        if (this.Notification && this.Notification.name) {
          OrderService.updateOrderStatusById(this.item.id, {status: status}).then((result) => {
            if (result.status === 200) {
              this.currentStatusIndex = status;
            }
            this.$store.commit('setNotification', {});
          }).catch(() => {
            this.$store.commit('setNotification', {name: 'Error', value: 'status: ' + status});
          });
        }
      });
      this.$emit('close-dropdown');
      this.emitter.emit('exchange-binance-timer', true)
    },
    statusDropdownHandler() {
      this.setDropdownPosition()
      this.$emit('toggle-dropdown', this.item.id);
      },
    statusClickOutside(event) {
      if (this.$refs.statusDropdown && !this.$refs.statusDropdown.contains(event.target)) {
        this.$emit('close-dropdown');
      }
    },
    formatNumberWithComma(number, toFixed) {
      const parts = number.toString().split(".");
      const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      const decimalPart = parts.length > 1
        ? parseFloat("0." + parts[1]).toFixed(toFixed).replace(/\.?0+$/, "")
        : "";

      return decimalPart ? integerPart + "." + decimalPart.slice(2) : integerPart;
    }
}}
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables';

  .exchanges-list-item-tr {
    position: relative;
    border-bottom: 1px solid #EAEFF4;
    transition: .3s;
    cursor: pointer;

    &:hover {
      background: #F5F9FF;
    }

    td {
      position: relative;
      height: 56px;
      box-sizing: border-box;
      color: $black-color1;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.28px;

      @media (max-width: 1079px) {
        height: 48px;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.24px;
      }
    }

    td.table-run-bot {
      padding-left: 32px;
      padding-right: 16px;
      width: 10%;

      @media (max-width: 1079px) {
        padding-left: 12px;
        padding-right: 12px;

        @media (max-width: 819px) {
          width: 8%;
        }
      }
    }

    td.table-data-time {
      padding-right: 16px;
      width: 11.4%;

      @media (max-width: 1079px) {
        padding-right: 12px;
      }
    }

    td.table-id {
      padding-right: 16px;
      width: 15%;

      span:first-child {
        padding-left: 22px;
      }

      a {
        color: $black-color1;
        &:hover {
          color: $blue-color;
        }
      }

      .copy-wrapper {
        display: inline-flex;
        align-items: center;
        gap: 2px;

        .copy {
          display: inline-flex;
          width: 20px;
          height: 20px;
          border-radius: 4px;
          transition: all .3s;
          &:hover {
            background: $light-gray-color;
            svg path {
              fill: $blue-color;
            }
          }
        }
      }

      @media (max-width: 1079px) {
        padding-right: 12px;
      }
    }

    td.table-partner {
      padding-right: 16px;
      width: 14%;

      >div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      @media (max-width: 1079px) {
        padding-right: 12px;
      }
    }

    td.table-pair {
      padding-right: 16px;
      width: 16%;

      @media (max-width: 1079px) {
        padding-right: 12px;
      }
    }

    td.table-amount-exchange {
      padding-right: 16px;
      width: 18%;

      @media (max-width: 1079px) {
        padding-right: 12px;
      }
    }

    td.table-aml {
      padding-right: 16px;
      width: 5.7%;

      @media (max-width: 1079px) {
        padding-right: 12px;

        @media (max-width: 819px) {
          padding-right: 0;
        }
      }
    }

    td.table-status {
      padding-right: 32px;
      width: 7.8%;
      min-width: 88px;
      transition: 0.3s;

      @media (max-width: 1079px) {
        padding-right: 12px;
        min-width: 64px;

        @media (max-width: 819px) {
          padding-left: 12px;
          position: sticky;
          right: 0;
          background: white;
          z-index: 1;
          border-bottom: 1px solid #EAEFF4;

          div.shadow {
            position: absolute;
            top: 0;
            left: -5px;
            height: 100%;
            width: 5px;
            background: linear-gradient(to left, rgba(82, 122, 161, 0.12), rgba(0, 0, 0, 0));
          }
        }
      }
    }


    .buttons-wrapper {
      display: inline-flex;
      gap: 4px;
    }

    .run-bot-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      background: $light-gray-color;
      cursor: pointer;
      border-radius: 8px;
      transition: .3s;

      &:hover {
        background: $light-gray-hover-color;
      }

      &-disabled {
        background: transparent;
        cursor: not-allowed;

        &:hover {
          background: #fff;
        }

        svg {
          path {
            fill: $another-grey-color;
          }
        }
      }
    }

    .restart-button {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      border-radius: 8px;
      transition: 0.3s;
      cursor: not-allowed;

      &-active {
        cursor: pointer;
        background: $light-gray-color;
        svg path {
          fill: $blue-color;
        }
      }
    }

    .status-button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 24px;
      width: 88px;
      color: #FFF;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: -0.12px;
      border-radius: 6px;
      cursor: pointer;
      transition: .3s all;

      @media (max-width: 1079px) {
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 64px;
        overflow: hidden;
        text-align: center;
        display: inline-block;
        padding: 4px 5px;
        box-sizing: border-box;
      }

      &-waiting {
        background: $status-empty;
      }
      &-confirmation {
        background: $status-pay;
      }
      &-payment {
        background: $status-exchange;
      }
      &-exchanging {
        background: $status-exchange;
      }
      &-exchanginginprogress {
        background: $status-exchange;
      }
      &-readytosend {
        background: $status-withdrawal;
      }
      &-sending {
        background: $status-withdrawal;
      }
      &-success {
        background: $status-completed;
      }
      &-overdue {
        background: $status-overdue;
      }
      &-refunded {
        background: $status-returned;
      }
      &-frozen {
        background: $status-hold;
      }
      &-suspended {
        background: $status-profit;
      }
    }
  }

.exchanges-list-item-tr:hover > td.table-status {
  background: #F5F9FF;
}
.dropdown {
  position: absolute;
  background: white;
  top: 45px;
  right: -6px;
  width: 180px;
  z-index: 999;
  border-radius: 8px;
  padding: 8px 0;
  box-shadow: 0 1px 4px 0 rgba(6, 59, 122, 0.12), 0 4px 24px 0 rgba(6, 59, 122, 0.08);

  @media (max-width: 1079px) {
    top: 41px;
    left: -104px;

    @media (max-width: 819px) {
      top: 10px;
      left: -183px;
    }
  }

  &.position-top {
    top: -293px;
    @media (max-width: 1079px) {
      top: -300px;
      @media (max-width: 819px) {
        top: -271px;
      }
    }
  }


  .dropdown-item {
    padding: 6px 12px;
    color: $black-color1;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &:hover {
      background: $hover-color;
    }

    .status-label {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-right: 5px;
      width: 18px;
      height: 18px;
      color: #FFF;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: -0.12px;
      border-radius: 4px;

      &.status-empty {
        background: $status-empty;
      }
      &.status-pay {
        background: $status-pay;
      }
      &.status-exchange {
        background: $status-exchange;
      }
      &.status-withdrawal {
        background: $status-withdrawal;
      }
      &.status-completed {
        background: $status-completed;
      }
      &.status-overdue {
        background: $status-overdue;
      }
      &.status-returned {
        background: $status-returned;
      }
      &.status-hold {
        background: $status-hold;
      }
      &.status-profit {
        background: $status-profit;
      }
    }
  }
}

</style>
